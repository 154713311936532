import React from "react";
import SubHeader from "../SubHeader";

const FoodChemical = () => {
  return (
    <div>
      <SubHeader curruntPath={"Food Chemical"} />

      <div style={{ padding: "90px" }}>
        <h2>Food Chemicals</h2>
        <p>
          At AARTI CHEM PHARMA & AARTI DYE CHEM, we specialize in offering a
          variety of high-quality food chemicals that are essential for the food
          and beverage industry. Our food chemicals meet the stringent quality
          standards required for ensuring food safety, preservation, and
          enhancement.
        </p>
        <p>
          These chemicals play a critical role in food processing, from
          improving shelf life to enhancing flavor, texture, and appearance. Our
          commitment to quality ensures that our food chemicals meet both
          domestic and international regulatory standards.
        </p>

        <h3>Why Choose Our Food Chemicals?</h3>
        <ul>
          <li>
            <strong>Quality Assurance</strong>: All of our food chemicals are
            thoroughly tested and comply with food-grade standards, ensuring
            safety and consistency.
          </li>
          <li>
            <strong>Wide Range of Applications</strong>: Our food chemicals are
            used in various sectors such as baking, confectionery, dairy, and
            beverages.
          </li>
          <li>
            <strong>Customization</strong>: We offer tailored solutions to meet
            the specific needs of our clients in the food industry.
          </li>
        </ul>

        <h3>Applications of Our Food Chemicals</h3>
        <p>
          Our food chemicals serve various functions in food manufacturing and
          are used for:
        </p>
        <ul>
          <li>Preservation</li>
          <li>Flavor enhancement</li>
          <li>Color retention</li>
          <li>Texture improvement</li>
          <li>Emulsification</li>
        </ul>

        <h3>Our Food Chemical Products</h3>
        <table>
          <thead>
            <tr>
              <th>Chemical</th>
              <th>Application</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Citric Acid</td>
              <td>Used as a preservative and flavoring agent in beverages</td>
            </tr>
            <tr>
              <td>Sodium Benzoate</td>
              <td>Preservative for acidic foods and beverages</td>
            </tr>
            <tr>
              <td>Agar Agar</td>
              <td>Thickening and gelling agent in desserts and jellies</td>
            </tr>
            <tr>
              <td>Ascorbic Acid (Vitamin C)</td>
              <td>
                Antioxidant used to preserve food and enhance nutritional value
              </td>
            </tr>
            <tr>
              <td>Sodium Alginate</td>
              <td>Stabilizer and thickening agent in ice cream and sauces</td>
            </tr>
            <tr>
              <td>Calcium Propionate</td>
              <td>Preservative used in bread and baked goods</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FoodChemical;
