import React from "react";
import SubHeader from "../SubHeader";
import { useParams } from "react-router-dom";

const Apis = () => {
  const apiData = [
    {
      category: "SULFA DRUGS / SULFONAMIDES",
      items: [
        {
          name: "SULFACETAMIDE (PLAIN)",
          cas: "144-80-9",
          specs: "USP/In-House",
        },
        {
          name: "SULFACETAMIDE SODIUM (DRC)",
          cas: "127-56-0",
          specs: "IP/BP/EP/USP",
        },
        {
          name: "SULFANILAMIDE",
          cas: "63-74-1",
          specs: "EP/BP (VET)/Technical",
        },
        { name: "SULFATHIAZOLE", cas: "72-14-0", specs: "BP/EP/USP" },
      ],
    },
    {
      category: "VITAMINS",
      items: [
        {
          name: "NICOTINIC ACID / NIACIN",
          cas: "59-67-6",
          specs: "IP/BP/EP/USP",
        },
        {
          name: "NICOTINAMIDE / NIACINAMIDE",
          cas: "98-92-0",
          specs: "IP/BP/EP/USP/Cosm",
        },
      ],
    },
    {
      category: "FLUORO QUINOLONES",
      items: [
        { name: "NORFLOXACIN", cas: "7045896-7", specs: "IP/BP/EP/USP" },
        { name: "ENROFLOXACIN BASE", cas: "93106-60-6", specs: "EP/BP (VET)" },
        { name: "NALIDIXIC ACID", cas: "389-08-2", specs: "BP/EP/USP" },
      ],
    },
    {
      category: "ANTIBIOTICS",
      items: [
        { name: "CHLORAMPHENICOL", cas: "56-75-7", specs: "IP/BP/EP/USP" },
        {
          name: "CHLORAMPHENICOL PALMITATE",
          cas: "530-43-8",
          specs: "IP/BP/EP/USP",
        },
      ],
    },
    {
      category: "ANTHELMINTICS",
      items: [
        { name: "PIPERAZINE HYDRATE", cas: "142-63-2", specs: "IP/BP/EP" },
        { name: "PIPERAZINE CITRATE", cas: "144-29-6", specs: "IP/BP/EP/USP" },
        { name: "PIPERAZINE ADIPATE", cas: "142-88-1", specs: "IP/BP/EP" },
        { name: "PIPERAZINE PHOSPHATE", cas: "1951-97-9", specs: "IP/BP/EP" },
      ],
    },
    {
      category: "IODIDES & CITRATES (SALTS)",
      items: [
        { name: "POTASSIUM IODIDE", cas: "7681-11-0", specs: "IP/BP/EP/USP" },
        { name: "SODIUM IODIDE", cas: "7681-82-5", specs: "IP/BP/EP/USP" },
        { name: "POTASSIUM CITRATE", cas: "866-83-1", specs: "IP/BP/EP/USP" },
        { name: "SODIUM CITRATE", cas: "6132-04-3", specs: "IP/BP/EP/USP" },
        { name: "SODIUM ACID CITRATE", cas: "144-33-2", specs: "BP/EP" },
        {
          name: "MONO SODIUM CITRATE",
          cas: "18996-35-5",
          specs: "In house specs",
        },
      ],
    },
    {
      category: "FOOD ADDITIVES / EXCIPIENTS",
      items: [
        {
          name: "SODIUM CHLORIDE (PYROGEN FREE)",
          cas: "7647-14-5",
          specs: "IP/BP/EP/USP",
        },
        {
          name: "POTASSIUM CHLORIDE (PYROGEN FREE)",
          cas: "7447-40-7",
          specs: "IP/BP/EP/USP",
        },
        { name: "CITRIC ACID ANHYDROUS", cas: "77-92-9", specs: "BP/EP/USP" },
        {
          name: "CITRIC ACID MONOHYDRATE",
          cas: "5949-29-1",
          specs: "BP/EP/USP",
        },
        { name: "MAIZE STARCH", cas: "9005-25-8", specs: "BP/EP" },
        {
          name: "SODIUM STARCH GLYCOLATE (maize starch based)",
          cas: "9063-38-1",
          specs: "IP/USP/NF",
        },
        {
          name: "PUMOJEL – SODIUM STARCH GLYCOLATE (potato starch based)",
          cas: "9063-38-1",
          specs: "IP/BP/EP/USP/NF",
        },
      ],
    },
    {
      category: "OTHERS",
      items: [
        { name: "METHLY NICOTINATE", cas: "93-60-7", specs: "BP/EP/Technical" },
        { name: "PYRAZINAMIDE", cas: "98-96-4", specs: "IP/BP/EP/USP" },
      ],
    },
  ];

  const a = window.location.pathname;

  console.log("a::", a);

  return (
    // <>
    //   <SubHeader curruntPath={"APIs"} />

    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       height: "100vh",
    //       padding: "20px",
    //       boxSizing: "border-box",
    //     }}
    //   >
    //     <h1 style={{ textAlign: "center" }}>ACTIVE PHARMA INGREDIENTS</h1>
    //     <div style={{ width: "100%", maxWidth: "800px", overflowX: "hidden" }}>
    //       <table
    //         style={{
    //           borderCollapse: "collapse",
    //           width: "100%",
    //           textAlign: "left",
    //           border: "1px solid #000",
    //           margin: "20px",
    //         }}
    //       >
    //         <thead>
    //           <tr>
    //             <th style={{ padding: "12px", border: "1px solid #000" }}>
    //               NAME
    //             </th>
    //             <th style={{ padding: "12px", border: "1px solid #000" }}>
    //               CAS NO
    //             </th>
    //             <th style={{ padding: "12px", border: "1px solid #000" }}>
    //               SPECS/GRADE
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {apiData.map((category, index) => (
    //             <React.Fragment key={index}>
    //               <tr>
    //                 <td
    //                   colSpan="3"
    //                   style={{
    //                     padding: "12px",
    //                     fontWeight: "bold",
    //                     textAlign: "center",
    //                   }}
    //                 >
    //                   {category.category}
    //                 </td>
    //               </tr>
    //               {category.items.map((item, subIndex) => (
    //                 <tr key={subIndex}>
    //                   <td style={{ padding: "12px", border: "1px solid #000" }}>
    //                     {item.name}
    //                   </td>
    //                   <td style={{ padding: "12px", border: "1px solid #000" }}>
    //                     {item.cas}
    //                   </td>
    //                   <td style={{ padding: "12px", border: "1px solid #000" }}>
    //                     {item.specs}
    //                   </td>
    //                 </tr>
    //               ))}
    //             </React.Fragment>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </>
    <>
      <SubHeader curruntPath={"APIs"} />

      <h2 style={{ textAlign: "center", margin: "20px" }}>
        ACTIVE PHARMA INGREDIENTS
      </h2>
      <div style={{ overflowX: "auto", margin: "20px" }}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            maxWidth: "800px",
            margin: "auto",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "12px",
                  textAlign: "center",
                  backgroundColor: "#f2f2f2",
                  fontWeight: "bold",
                }}
              >
                NAME
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "12px",
                  textAlign: "center",
                  backgroundColor: "#f2f2f2",
                  fontWeight: "bold",
                }}
              >
                CAS NO
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "12px",
                  textAlign: "center",
                  backgroundColor: "#f2f2f2",
                  fontWeight: "bold",
                }}
              >
                SPECS/GRADE
              </th>
            </tr>
          </thead>
          <tbody>
            {apiData.map((category, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      padding: "12px",
                      fontWeight: "bold",
                      textAlign: "center",
                      backgroundColor: "#e2e2e2",
                    }}
                  >
                    {category.category}
                  </td>
                </tr>
                {category.items.map((item, subIndex) => (
                  <tr key={subIndex}>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {item.name}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {item.cas}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {item.specs}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Apis;
