import React from 'react'

const PotassiumPhosphateDibasicAnhydrous = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p align="justify">
              With an aim to become leading entity in this domain, we have been
              engaged in providing fine grade
              <strong> Potassium Phosphate Dibasic Anhydrous</strong>. The offered
              product is ideal to be used in pharmaceutical and food industries
              owing to its high effectiveness. This product is processed using
              quality examined chemicals by our adroit professionals with the aid
              of sophisticated methodology. In addition, the provided{" "}
              <strong>Potassium Phosphate Dibasic Anhydrous</strong> can be
              purchased from us in bulk at pocket friendly rates.
            </p>
            <p align="justify">
              <strong>Key Points:</strong>
            </p>
            <ul>
              {" "}
              <li>Longer shelf life</li> <li>Safe to consume</li>{" "}
              <li>
                No side effects&nbsp; <br />
              </li>{" "}
              <li>
                Free from adulteration <br />
              </li>{" "}
            </ul>
            <div align="justify"> </div>
            <div align="justify"> </div>{" "}
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html: "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }"
              }}
            />{" "}
            <p style={{ pageBreakBefore: "always" }}>
              <strong>
                <span lang="en-US">Potassium Phosphate Dibasic Anhydrous</span>
              </strong>
            </p>{" "}
            <ul>
              <li>
                <strong>C.A.S. No</strong>. : 7758-11-4{" "}
              </li>
              <li>
                <strong>Molecular Formula</strong> : K2hpo4
              </li>
              <li>
                <strong>Molecular Weight </strong>: 174.18{" "}
              </li>
            </ul>{" "}
            <center>
              {" "}
              <table cellSpacing={0} cellPadding={0} width="100%">
                {" "}
                <colgroup>
                  <col width="64*" /> <col width="64*" /> <col width="64*" />{" "}
                  <col width="64*" />{" "}
                </colgroup>
                <tbody>
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={28}
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>SPECIFICATION</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>LR</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>AR</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{ border: "1px solid rgb(0, 0, 0)", padding: "0cm" }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>ACS</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Insoluble Matter</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.01%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.01%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>pH of a 5% solution at 250C</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>8.5 -9.3</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>9.2 - 9.4</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>8.5 - 9.6</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Nitrogen Compounds (N)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.001%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.001%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Chloride (Cl)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.001%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.003%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Bromide (Br)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Chlorate and Nitrate</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Sulphate (SO4)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.005%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Heavy Metals (as Pb)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.0005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.0005%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Iron (Fe)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.0005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.001%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Phosphate (PO4)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Calcium (Ca)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Barium (Ba)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>
                        <br />{" "}
                      </p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Magnesium (Mg)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.0005%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Sodium (Na)</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.05%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>0.05%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={29}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Assay</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>NLT 98.0%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>NLT 99.0%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>NLT 98.0%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                  <tr>
                    {" "}
                    <td
                      width="25%"
                      height={28}
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p>
                        <strong>Loss on Drying</strong>
                      </p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>1.0%</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>---</p>{" "}
                    </td>{" "}
                    <td
                      width="25%"
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor:
                          "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0cm"
                      }}
                    >
                      {" "}
                      <p style={{ fontWeight: "normal" }}>1.0%</p>{" "}
                    </td>{" "}
                  </tr>{" "}
                </tbody>
              </table>{" "}
            </center>{" "}
            <p>
              <strong>Features</strong>
            </p>{" "}
            <ul>
              <li>No side-effects </li>
              <li>Approved quality </li>
              <li>Effectiveness </li>
              <li>Easy to use </li>
            </ul>
            <div align="justify"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default PotassiumPhosphateDibasicAnhydrous