import React from "react";

const AmmoniumPhosphate = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="50%"
                    >
                      <p>AMMONIUM PHOSPHATE</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>7783-28-0</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        (NH4)<sub>2</sub>HPO<sub>4</sub>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>132.06 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="85*" />
                  <col width="85*" />
                  <col width="85*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Specification</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>BP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>USP</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Description</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        White or almost white crystals or granules, hygroscopic,
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        White or almost white crystals or granules, hygroscopic,
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Solubility</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        Very soluble in water, practically insoluble in ethanol
                        (96%).
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>pH</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>About 8.0</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>7.6-8.2</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Chloride</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 300 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Sulfate</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 1500 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Arsenic</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 3 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Heavy metals</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 10 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Residual solvents</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Meets the requirements</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Assay</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>96.0 %-102.0%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmmoniumPhosphate;
