import React from "react";
import SubHeader from "../SubHeader";

const Finechemicals = () => {
  const data = [
    { name: "SALICYLANILIDE", casNo: "87-17-2" },
    { name: "PARA BROMO TOLUENE 99.5%", casNo: "1073-70-7" },
    { name: "PYRIDINE HYDROBROMIDE", casNo: "18820-82-1" },
    { name: "POTASSIUM PHTHALIMIDE", casNo: "86604-78-6" },
    { name: "NICOTINIC ACID HYDRAZIDE", casNo: "553-53-7" },
    { name: "3 AMINO PYRIDINE", casNo: "462-08-08" },
    { name: "METHYL IODIDE", casNo: "74-88-4" },
    { name: "D Cycloserine", casNo: "68-41-7" },
    { name: "Thioacetamide", casNo: "62-55-5" },
    { name: "Cerium (III) Nitrate Hexahydrate", casNo: "10294-41-4" },
    { name: "Triclosan USP", casNo: "3380-34-5" },
    { name: "Ammonium Chloride LR", casNo: "12125-02-9" },
    { name: "CueLure", casNo: "180-84-7" },
    { name: "RNase A", casNo: "9001-99-4" },
    { name: "Z-11-Hexadecenyl Acetate", casNo: "34010-21-4" },
    { name: "Z-13-Octadecadienyl Acetate", casNo: "34010-21-4" },
    { name: "Z9-Tetradecadienyl Acetate", casNo: "34010-21-4" },
    { name: "Z E-9 12-Tetradecadienyl Acetate", casNo: "34010-21-4" },
    { name: "Sodium Hydrosulphite", casNo: "7775-14-6" },
    { name: "Timentin", casNo: "697-14-7" },
    { name: "Rhodamine B, Practical Grade", casNo: "81-88-9" },
    { name: "Potassium Iodide I.P.", casNo: "7681-11-0" },
    { name: "Lithium Bromide Anhydrous", casNo: "7550-35-8" },
    { name: "Aluminium Hydroxide IP (LR)", casNo: "21645-51-2" },
    { name: "Nicotinamide Adenine Dinucleotide", casNo: "53-84-9" },
    { name: "Malachite Green Oxalate, Practical Grade", casNo: "2437-29-8" },
  ];

  const tableStyle = {
    margin: "20px auto",
    borderCollapse: "collapse",
    width: "80%",
  };

  const thTdStyle = {
    border: "1px solid #ddd",
    padding: "12px",
    textAlign: "center",
    margin: "10px",
  };

  return (
    <div>
      <SubHeader curruntPath={"FINE CHEMICALS"} />
        <table style={tableStyle}>
        <thead>
          <tr>
            <th
              style={{
                ...thTdStyle,
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              Chemical Name
            </th>
            <th
              style={{
                ...thTdStyle,
                backgroundColor: "#f2f2f2",
                fontWeight: "bold",
              }}
            >
              CAS No
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={thTdStyle}>{item.name}</td>
              <td style={thTdStyle}>{item.casNo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Finechemicals;
