// export default Homepage;
import React, { useEffect, useLayoutEffect } from "react";
import slider_1 from "../images/banner1.png";
import slider_2 from "../images/slide-2.jpg";
import service from "../images/services.jpg";
import ser_img3 from "../images/ser-img3.jpg";
import ser_img5 from "../images/ser-img5.jpg";
import paoermeel from "../images/papermeel.jpg";
import silicon from "../images/silicon.png";
import portfolio9 from "../images/portfolio9.jpg";
import diamond from "../images/diamond.svg";
import vision from "../images/icons8-vision-48.png";
import mission from "../images/icons8-goal-50.png";
import Himedia_laboratories from "../images/Himedia_laboratories.png";
import warkem_biotech from "../images/warkem_biotech.png";
import Glide_Chem from "../images/Glide_Chem.png";
import Mil_Laboratories from "../images/Mil_Laboratories.png";
import CDH from "../images/cdh.png";
import SRL from "../images/SRL.png";
import CTX_Life_Sciences from "../images/CTX_Life_Sciences.png";
import hexon_laboratories from "../images/hexon_laboratories.png";
import { NavLink } from "react-router-dom";
import { windowScroll } from "../common/Footer";

const Homepage = () => {
  useEffect(() => {
    if (window.jQuery && window.jQuery.fn.flexslider) {
      const slider = window.jQuery(".flexslider");
      if (!slider.hasClass("flexslider-initialized")) {
        slider.flexslider({
          animation: "fade",
          controlsContainer: ".flex-container",
          slideshow: true,
          slideshowSpeed: 5000,
          animationSpeed: 1200,
          directionNav: true,
          controlNav: false,
          mousewheel: false,
          smoothHeight: false,
          prevText: " ",
          nextText: " ",
          start: function (slider) {
            window.jQuery(".total-slides").text(slider.count);
            window
              .jQuery(slider.slides[slider.currentSlide])
              .find(".flex-caption")
              .addClass("flex-caption-anim");
          },
          before: function (slider) {
            window
              .jQuery(slider.slides)
              .find(".flex-caption")
              .removeClass("flex-caption-anim");
          },
          after: function (slider) {
            window
              .jQuery(slider.slides[slider.animatingTo])
              .find(".flex-caption")
              .addClass("flex-caption-anim");
          },
        });
      }
    }

    if (window.jQuery && window.jQuery.fn.owlCarousel) {
      window.jQuery("#owl-20").owlCarousel({
        items: 4,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 3],
        itemsTablet: [768, 2],
        itemsMobile: [479, 1],
        navigation: false,
        pagination: false,
        autoPlay: true,
        autoplay: true,
        autoplayHoverPause: false,
        autoplayTimeout: 2,
      });
    }
  }, []);

  return (
    <>
      <section id="featured_slider" class="clearfix">
        <div class="slider_wrapper">
          <div class="flexslider">
            <ul class="slides">
              <li>
                <img alt="Evaluation of the Current Safety" src={slider_1}  />
                <div class="flex-caption fs-left flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>
                        We are the Largest Independent manufacturer Company
                      </h2>
                      <div class="flex-content">
                        We are one of the leading traders, importers and
                        suppliers of water Treatment chemical And Acids. <br />
                        These dyes and chemicals are sourced from the most
                        reliable vendors of the marke
                      </div>
                    </div>

                    <NavLink to={"/apis"} class="first_slider_button">
                      <button
                        onClick={windowScroll}
                        class="first_slider_button"
                      >
                        learn more
                      </button>
                    </NavLink>
                    <NavLink to={"/about-us"}>
                      <button onClick={windowScroll}>our sevices</button>
                    </NavLink>
                  </div>
                </div>
              </li>
              <li>
                <img
                  alt="Laboratory Priority Services Delivered"
                  src={slider_2}
                />
                <div class="flex-caption fs-right flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>Most reliable partner</h2>
                      <div class="flex-content">
                        <p>
                          A reliable company to work with then you're on to good
                          thing, carefull indentified the best strategic
                          partners.
                        </p>
                        <NavLink to={"/apis"} class="first_slider_button">
                          <button
                            class="first_slider_button"
                            onClick={windowScroll}
                          >
                            learn more
                          </button>
                        </NavLink>
                        <NavLink to={"/about-us"} onClick={windowScroll}>
                          <button>our sevices</button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <img alt="Evaluation of the Current Safety" src={slider_1} />
                <div class="flex-caption fs-left flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>MANUFACTURTE AND EXPORTER SUPERIOR QUALITY</h2>
                      <NavLink to={"/apis"} class="first_slider_button">
                        <button
                          class="first_slider_button"
                          onClick={windowScroll}
                        >
                          learn more
                        </button>
                      </NavLink>
                      <NavLink to={"/about-us"}>
                        <button onClick={windowScroll}>our sevices</button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="flex-direction-nav">
              <li>
                <NavLink class="flex-prev" to={""}>
                  {" "}
                </NavLink>
              </li>
              <li>
                <NavLink class="flex-next" to={""}>
                  {" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div id="main" class="content-area">
        <div class="section_row clearfix">
          <div class="section_inner">
            <div class="at-fancy-heading-v1 textcenter">
              <h2>
                Aarti Chem Pharma is an India-based API and pharmaceutical
                company with over{" "}
                <strong
                  style={{
                    color: "#990b6e",
                    whiteSpace: "nowrap",
                    wordBreak: "break-word",
                  }}
                >
                  10 years of experience
                </strong>{" "}
                We specialize in the manufacturing, exporting, sourcing, and
                supply of bioscience chemicals and pharmaceutical molecules,
                delivering excellence across global markets
              </h2>
              <span class="at-fancy-heading-v1-sep"></span>
            </div>

            <div class="demo_space" style={{ height: "80px" }}></div>

            <div class="one_half">
              <img src="assets/images/photo1.jpg" alt="" />
            </div>

            <div class="one_half end">
              <h1>WELCOME TO AARTI CHEM PHARMA & AARTI DYE CHEM</h1>
              <p>
                <strong>Having a new name is just the beginning.</strong>
              </p>
              <p>
                Welcome to AARTI CHEM PHARMA & AARTI DYE CHEM Pharmaceutical,
                where our new name signifies the start of an exciting journey.
                We are committed to pioneering advancements in healthcare
                through innovative solutions and exceptional quality. Our
                mission is to enhance lives with products that meet the highest
                standards and address diverse healthcare needs. Join us as we
                continue to build on our legacy of trust and excellence, shaping
                the future of the pharmaceutical industry.
              </p>
              <p onClick={windowScroll}>
                <strong>
                  <NavLink to={"/about-us"}>
                    MORE ABOUT US <i class="fa fa-long-arrow-right"></i>
                  </NavLink>
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="section_row clearfix" style={{ paddingTop: "0" }}>
          <div className="section_inner">
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-regular fa-hospital"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Research Center</h3>
                  In City
                </div>
              </div>
            </div>
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa fa-fw fa-2x fa-stethoscope"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Lab Technicians</h3>
                  Powerful &amp; Skilled
                </div>
              </div>
            </div>
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-solid fa-money-bill-1"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Low Cost</h3>
                  Low cost research
                </div>
              </div>
            </div>
            <div className="one_fourth last">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-regular fa-envelope"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Notifications</h3>
                  Instant Email Alerts
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="inner">
          <main className="content-area">
            <div className="entry-content-wrapper clearfix">
              <div className="cases-align">
                <div className="at-cases-main">
                  <NavLink to={"/finechemicals"} onClick={windowScroll}>
                    <div className="one_third at-cases-item">
                      <div className="at-cases-details">
                        <img src={service} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title"> FINE CHEMICALS</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={"/apis"} onClick={windowScroll}>
                    <div className="one_third at-cases-item">
                      <div className="at-cases-details">
                        <img src={paoermeel} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title">
                            API-ACTIVE PHARMACEUTICAL
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={"/WaterTreatment"} onClick={windowScroll}>
                    <div className="one_third last at-cases-item">
                      <div className="at-cases-details">
                        <img src={ser_img3} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title">
                            INDUSTRIAL CHEMICALS AND WATER TREATMENT CHEMICAL
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>

                <div className="at-cases-main">
                  <NavLink to={"/foodchemicals"} onClick={windowScroll}>
                    <div className="one_third at-cases-item">
                      <div className="at-cases-details">
                        <img src={portfolio9} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title">FOOD CHEMICALS</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink
                    to={"/dyesanddyeintermediates"}
                    onClick={windowScroll}
                  >
                    <div className="one_third at-cases-item">
                      <div className="at-cases-details">
                        <img src={ser_img5} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title">
                            DYES AND DYE INTERMEDIATES
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={"/drugintermediates"} onClick={windowScroll}>
                    <div className="one_third last at-cases-item">
                      <div className="at-cases-details">
                        <img src={silicon} alt="" height="150" width="200" />
                        <div className="at-cases-content">
                          <span className="title">
                            PHARMACEUTICAL INTERMEDIATES
                          </span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div
          className="section_row clearfix"
          style={{ backgroundColor: "#e4ebef", padding: "40px 0" }}
        >
          <div className="section_inner">
            <div className="owl-wrapper">
              <div id="owl-20" className="owl-Carousel">
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={Glide_Chem} />

                    <span className="cl-title">Glide Chem</span>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={warkem_biotech} />

                    <span className="cl-title">warkem biotech pvt ltd</span>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={Mil_Laboratories} />

                    <span className="cl-title">Mil Laboratories</span>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={hexon_laboratories} />

                    <span className="cl-title">
                      hexon laboratories private limited
                    </span>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={CDH} />

                    <span className="cl-title">CDH</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={SRL} />

                    <span className="cl-title">
                      sisco research laboratories
                    </span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={CTX_Life_Sciences} />

                    <span className="cl-title">CTX Life Sciences</span>
                  </div>
                </div>
                <div className="owl-item">
                  <div className="clientthumb">
                    <img alt="" src={Himedia_laboratories} />
                    <span className="cl-title"> Himedia laboratories</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <h1 style={{ textAlign: "center" }}>Experienced</h1>
          <p style={{ textAlign: "center" }}>
            Choose from our range of over 1,000 products, or let us make
            suggestions, drawing on our extensive experience in the Fine
            Chemicals and Pharma APIs manufacturing industry.
          </p>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              gap: "70px",
              marginTop: "2rem",
              justifyContent: "center",
            }}
          >
            <div class="serviceIcn_style1">
              <div class="services-icon">
                <img
                  src={diamond}
                  class="services_icon1 fa fa-fw fa-2x fa-diamond fa-4x"
                  style={{ color: "#990b6e", height: "50px" }}
                ></img>
              </div>
              <div class="services-content"></div>
              <p>VALUES</p>
            </div>
            <div>
              <img src={vision} alt="Vision" />
              <p>VISION</p>
            </div>
            <div>
              <img src={mission} alt="Mission" />
              <p>MISSION</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
