import React from "react";

const Career = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <h2
        style={{
          fontSize: "2rem",
          color: "#333",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        JOIN THE AARTI TEAM TODAY!
      </h2>
      <p
        style={{
          fontSize: "1.2rem",
          lineHeight: "1.6",
          color: "#555",
        }}
      >
        As a member of AARTI CHEM PHARMA, you’ll have the opportunity to enhance
        your skills, learn to be a leader, and build your career with a growing
        organization. <br /> AARTI CHEM PHARMA continuously strives to provide staff
        career development within the organization. <br /> These efforts include
        mentoring, professional development, and pathways for senior leadership
        opportunities. <br /> If you’d like to join us, please send an e-mail to spa
        <a
          href="mailto:info@aartichempharma.com"
          style={{
            color: "#990b6e",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          info@aartichempharma.com
        </a>
        .
      </p>
    </div>
  );
};

export default Career;
