import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import aartilogo from "../../src/images/aarti_logo_footer.png";
import { handleWhatsup } from "../components/ContactPage";

export const windowScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
const Footer = () => {
  const location = useLocation();

  const handleLinkdIn = () => {
    const webURL = "https://www.linkedin.com/company/aarti-chem/";
    window.open(webURL, "_blank");
  };

  return (
    <>
      <div id="footer">
        {location.pathname === "/contact-us" ? null : (
          <div className="footer-area-top">
            <div className="inner">
              <div className="at-callOutBox">
                <div className="at-callOut_inner">
                  <div className="at-callOut-action">
                    <div className="at-callOut_text">
                      <div className="at-callout-action-text">
                        <h1
                          style={{
                            fontSize: "24px",
                            color: "#ffffff",
                            fontWeight: "500",
                            letterSpacing: "-1px",
                          }}
                        >
                          If You have any reqiurement of Pharma APIs ... We Are
                          Available For Sourcing and supply
                        </h1>
                      </div>
                    </div>
                    <div className="at-callOut_btn" onClick={windowScroll}>
                      <div className="at-callout-action-button">
                        <NavLink
                          to={"contact-us"}
                          className="btn large"
                          style={{
                            color: "#ffffff",
                            borderColor: "#ffffff",
                          }}
                          data-btn-color="#f0f0f0"
                        >
                          <span className="btn-text">CONTACT US </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-area-middle">
          <div className="inner">
            <div className="inner-row">
              <aside id="text-4" className="whitetext widget widget_text col_3">
                <div className="textwidget">
                  <p>
                    <img
                      src={aartilogo}
                      alt="Labora"
                      height="100px"
                      width="170px"
                      style={{ marginTop: "-20px" }}
                    />
                  </p>
                  <h5>
                    Our dedication for quality and excellence has not only
                    allowed us to become leaders in the industry, it motivates
                    us to continuously innovate and improve on our products and
                    processes.
                  </h5>
                </div>
              </aside>
              <aside id="nav_menu-6" className="widget widget_nav_menu col_3">
                <h3 className="widget-title">Our Product</h3>
                <div className="menu-footer-one-container">
                  <ul className="menu">
                    <li onClick={windowScroll}>
                      <NavLink to={"/apis"}>- APIs</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/drugintermediates"}>
                        - Drug-intermediates
                      </NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/finechemicals"}>- Fine-chemicals</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/labgradechemical"}>
                        - Lab-Grade Chemicals
                      </NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/foodchemicals"}>- Food Chemicals</NavLink>
                    </li>{" "}
                    <li onClick={windowScroll}>
                      <NavLink to={"/Dyesanddyeintermediates"}>
                        - Dyes and Dye Intermediates
                      </NavLink>
                    </li>{" "}
                    <li onClick={windowScroll}>
                      <NavLink to={"/WaterTreatment"}>
                        - Industrial And Water Treatment Chemicals
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </aside>
              <aside
                id="nspainfoav_menu-5"
                className="widget widget_nav_menu col_3"
              >
                <h3 className="widget-title">Quick Links</h3>
                <div className="menu-footer-one-container">
                  <ul className="menu">
                    <li onClick={windowScroll}>
                      <NavLink to={"/about-us"}>- About Us</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/apis"}>- Product</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to={"/ContractManufacturing"}>
                        - ContractManufacturing
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </aside>
              <aside
                id="contactinfo_widgets-2"
                className="widget contactinfo-wg col_3"
              >
                <h3 className="widget-title">Get in Touch</h3>
                <div className="contactinfo-wrap">
                  <div style={{ display: "flex" }}>
                    <span className="icon fa fa-location-arrow fa-lg"></span>
                    <span className="details" style={{ marginLeft: "15px" }}>
                      <NavLink to={"/contact-us"}>
                        2005-international fashion market, sitangar chock,
                        Punagam, Surat, Gujarat 395011
                      </NavLink>
                    </span>
                  </div>

                  <p className="phone">
                    <span className="icon fa fa-phone fa-lg"></span>
                    <span className="details">(+91)96870 52936</span>
                  </p>
                  <p className="phone">
                    <span className="icon fa fa-regular fa-envelope"></span>
                    <span className="details">
                      <NavLink to={"/"}>info@aartichempharma.com</NavLink>
                    </span>
                  </p>
                  <p onClick={windowScroll}>
                    <span className="icon fa fa-map-marker fa-lg"></span>
                    <span className="details">
                      <NavLink to={"/contact-us"}>View Map</NavLink>
                    </span>
                  </p>
                  <p>
                    <span className="icon fa fa-brands fa-instagram fa-lg"></span>
                    <span className="details">
                      <NavLink
                        to={
                          "https://www.instagram.com/aartidyechem/?utm_source=qr"
                        }
                        target="_blank"
                      >
                        Instagram
                      </NavLink>
                    </span>
                  </p>
                  <p>
                    <span className="fa-brands fa-whatsapp fa-lg icon"></span>
                    <span className="details">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={handleWhatsup}
                      >
                        WhatsApp
                      </div>
                    </span>
                  </p>
                  <p>
                    <span className="fa-brands fa-linkedin fa-lg icon"></span>
                    <span className="details">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={handleLinkdIn}
                      >
                        Linkedin
                      </div>
                    </span>
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div className="footer-area-bottom">
          <div className="inner">
            <aside id="text-7" className="textcenter widget widget_text">
              <div className="textwidget">
                Copyright © aartichempharma 2016.. All rights reserved.{" "}
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
