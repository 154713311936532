import React from 'react'

const BenzoicAcid = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <strong>Product Details :</strong>
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="128*" />
                <col width="128*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Product Name</strong>
                    </p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="50%"
                  >
                    <p>: BENZOIC ACID</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>C.A.S. No.</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 65-85-0 </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Formula</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      : C<sub>7</sub>H<sub>6</sub>O<sub>2</sub>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Weight</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 122.12g/mol</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <br />
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>SPECIFICATION</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>IP</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>BP</strong>
                    </p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="25%"
                  >
                    <p>
                      <strong>USP</strong>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Description</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      Colorless, light crystals, scales or needles, odour, slight
                      characteristic.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      White or almost white, crystalline powder or colorless
                      crystals.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      Colorless, light crystals, scales or needles, odor, slight
                      characteristic.
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Solubility</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      Slightly soluble in water, soluble in boiling water and
                      freely soluble in ethanol (96 %) and in fatty oils.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>Appearance of solution </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      A 5.0 % w/v solution in ethanol (95%) is clear and
                      colorless.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>5 % solution in ethanol (96 %) is clear and colorless.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>Identification </p>
                    <ol type="A">
                      <li>
                        <p style={{ marginBottom: "0cm" }}>Melting point</p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>Reaction</p>
                      </li>
                      <li>
                        <p>Acidic</p>
                      </li>
                    </ol>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <ol type="A">
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          121<sup>o</sup>C to 123<sup>o</sup>C
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          buff colored precipitate is produced
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          Light absorption maximum at about 225 nm, about 0.8.
                        </p>
                      </li>
                      <li>
                        <p>1% w/v solution is acidic to methyl red solution.</p>
                      </li>
                    </ol>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      A : 121<sup>o</sup>C to 124<sup>o</sup>C
                    </p>
                    <p>B: It gives reaction of Benzoates.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <ol type="A">
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          Summoned colored PPT is formed.
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          A white PPT formed which is soluble in ether
                        </p>
                      </li>
                    </ol>
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Arsenic </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 2 ppm.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Heavy metals </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT10 ppm.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT10 ppm.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Readily oxidisable substance </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      NMT 0.5 ml of potassium permanganate is require to persist
                      pink color.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>After 5 min. solution is still colored pink.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      NMT 0.50 ml of 0.10 N potassium permanganate is Consumed.
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Readily carbonisable substance</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      The color of the solution is not more instance than that of
                      the reference solution YS5.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      The solution is not more intensely colored than reference
                      solution Y5
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>The solution has no more color than matching fluid Q.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Cinnamic acid </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>No odour of benzaldehyde is developed.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Chlorinated compounds</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>The filtrate compiles with the limit test of chloride.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Halogenated compounds &amp; Halides</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 300 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Residue on Ignition</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.1 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.1 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.05 %</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Water by KF </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.7 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.7 %</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Assay (on anhydrous basis)</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.5 % to 100.5 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % to 100.5 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.5 % to 100.5 %</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default BenzoicAcid