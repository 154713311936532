import React from "react";

const BoricAcid = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: BORIC ACID</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 10043-35-3</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          : H<sub>3</sub>BO<sub>3</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 61.83</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>IP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>BP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>USP</strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Description</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          White, Crystalline powder or colorless shiny plates,
                          unctuous to the touch or white crystals, odorless
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          White or almost white, crystalline powder, colorless,
                          shiny plates greasy to the touch, or white or almost
                          white crystals.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          White or almost white, crystalline powder, colorless,
                          shiny plates greasy to the touch, or white or almost
                          white crystals.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Solubility</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Soluble in water and in ethanol (96 %), freely soluble
                          in boiling water and in glycerol ( 85 %).
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          Appearance of solution
                        </p>
                        <p>(10% w/v solution)</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          3.5 % w/v solution in boiling water is clear and
                          colorless.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>3.3 % w/v solution is clear and color less.</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>Identification</p>
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}> By color</p>
                          </li>
                          <li>
                            <p>Reaction</p>
                          </li>
                        </ol>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          A: The flame has a green border.
                        </p>
                        <p>B: 3.3 %w/v the solution is faintly acid.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          A: The flame has green border.
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          B: 3.3 % w/v solution is acidic.
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>It responds to the tests for borate</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Ph ( 3.3 % w/v solution)</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>3.8-4.8</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>3.8 - 4.8</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          Solubility in ethanol
                        </p>
                        <p>(96% )</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The solution is not more opalescent than opalescence
                          standard OS2 and colorless
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The solution has not more opalescent than reference
                          suspension II and colorless.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Organic matter</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          It does not darken on progressive heating to dull
                          redness
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Sulphates</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 450 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 450 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Arsenic</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 10 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Heavy metal</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 20 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 20 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Loss on drying</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.5 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.5 %</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Residual solvents</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Meets the requirements</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Assay</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.5 % to 100.5 % ( on dry basis)</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.0 % - 100.5 %</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.5 % to 100.5% (on dry basis )</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoricAcid;
