import React from "react";

const FerrousSulfateHeptahydrate = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={2} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.02in 0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.02in",
                      }}
                      width="50%"
                    >
                      <p>: FERROUS SULFATE HEPTAHYDRATE</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>: 7782-63-0</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>
                        : FeSO<sub>4</sub>.7H<sub>2</sub>O
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="50%"
                    >
                      <p>: 278.02 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
              </p>
              <table cellPadding={2} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="69*" />
                  <col width="95*" />
                  <col width="92*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.02in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">SPECIFICATION</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.02in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <strong>
                          <span lang="en">IP</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <strong>
                          <span lang="en">BP</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Description</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">
                          Bluish green crystals or a light green, crystalline
                          powder, odourless. Efforescent in air. On exposure To
                          moist air, the crystals rapidly oxidize and become
                          brown.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">
                          Bluish green crystals or a light green, crystalline
                          powder, efforescent in air.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Solubility</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">
                          Freely soluble in water, very soluble in boiling
                          water, practically insoluble in ethanol (96 %) Ferrous
                          sulfate heptahydrate is oxidized in moist air,
                          becoming brown.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">pH</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">3.0-4.0 ( 5 % w/v Solution)</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">3.0-4.0</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Appearance of Solution</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">
                          The solution is not more opalescent than opalescent
                          Standard.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <strong>
                          <span lang="en">Identification</span>
                        </strong>
                      </p>
                      <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                      <ul>
                        <li>
                          <strong>
                            <span lang="en">Reaction</span>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            <span lang="en">Reaction</span>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            <span lang="en">Assay</span>
                          </strong>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">
                            It&nbsp;gives reaction of sulfates
                          </span>
                        </li>
                        <li>
                          <span lang="en">
                            It gives reaction of Ferrous Salt
                          </span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">It gives reaction of sulfates</span>
                        </li>
                        <li>
                          <span lang="en">It gives reaction of Irons</span>
                        </li>
                        <li>
                          <span lang="en">
                            It complies with limit of assay.
                          </span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Chloride</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 250 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 200 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Basic sulphate</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">
                          Producing solution that is not more than turbid.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Arsenic</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 2 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Lead</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Mercury</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Chromium</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Copper</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Ferric ions</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 0.5 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 0.3 %</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Manganese</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 0.1 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 0.1 %</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Nickel</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Zinc</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">NMT 500 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">NMT 50 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Organic volatile impurities</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Residual Solvents</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="27%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Assay</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.02in 0.02in",
                      }}
                      width="37%"
                    >
                      <p>
                        <span lang="en">98.0 % - 105.0 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.02in 0.02in",
                      }}
                      width="36%"
                    >
                      <p>
                        <span lang="en">98.0 % - 105.0 %</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FerrousSulfateHeptahydrate;
