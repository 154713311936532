import React from 'react'

const MagnesiumSulphateHeptahydrate = () => {
  return (
    <div>
      MagnesiumSulphateHeptahydrate
    </div>
  )
}

export default MagnesiumSulphateHeptahydrate
