import React from "react";
import tin1 from "../../images/tin1.jpg";
import tin2 from "../../images/tin2.jpg";
import tin3 from "../../images/tin3.jpg";

const Tin = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Tin(II) Chloride Dihydrate
      </h2>
      <p>
        Tin(II) chloride dihydrate is widely used as a reducing agent (in acid
        solution), and in electrolytic baths for tin-plating.
      </p>
      <h2 style={{ textTransform: "uppercase" }}>
        Tin(II) Chloride Dihydrate (AR - Grade)
      </h2>
      <img src={tin1} alt="" style={{ marginBottom: "25px" }} />
      <h2 style={{ textTransform: "uppercase" }}>
        Tin(II) Chloride Dihydrate (ACS - Grade)
      </h2>
      <img src={tin2} alt="" style={{ marginBottom: "25px" }} />
      <h2 style={{ textTransform: "uppercase" }}>Tin Sulphate (LR - Grade)</h2>
      <img src={tin3} alt="" style={{ marginBottom: "25px" }} />
    </div>
  );
};

export default Tin;
