import React from "react";
import SubHeader from "../SubHeader";

const DrugIntermediates = () => {
  return (
    <div>
      <SubHeader curruntPath={"DRUG INTERMEDIATES"} />
      <div style={{ padding: "90px" }}>
        <table>
          <thead>
            <tr>
              <th>Component</th>
              <th>CAS No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                4 - Sulfonamido Phenyl Hydrazine Hydrochloride (Celecoxib
                Intermediate)
              </td>
              <td>17852-52-7</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DrugIntermediates;
