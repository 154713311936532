import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo.png";

const Navbar = () => {
  // function resizemobile() {
  //   // show meun starting from iPad Portrait
  //   if (window.innerWidth < 959) {
  //     window.jQuery(".header #menuwrap").hide();
  //     window.jQuery(".header .iva-light-logo").hide();
  //     window.jQuery(".header .iva-dark-logo").show();
  //   } else {
  //     window.jQuery(".header #menuwrap").show();
  //     window.jQuery(".iva-mobile-menu").hide();
  //     window.jQuery(".header .iva-light-logo").show();
  //     window.jQuery(".header .iva-dark-logo").hide();
  //   }
  // }
  // useEffect(() => {
  //   window.jQuery(document).ready(function ($) {
  //     "use strict";
  //     $("#iva-mobile-nav-icon").click(function () {
  //       $(this).toggleClass("open");
  //       $(".iva-mobile-menu").slideToggle(500);
  //       return false;
  //     });
  //     // Child Menu Toggle
  //     window.jQuery(".iva-children-indenter").click(function () {
  //       $(this).parent().parent().toggleClass("iva-menu-open");
  //       $(this).parent().parent().find("> ul").slideToggle();
  //       return false;
  //     });
  //     resizemobile();
  //   });
  // }, []);
  // }

  return (
    <header>
      <div id="fixedheader" className="header">
        <div className="header-area">
          <div className="logo">
            <Link to="/" title="Labora - Medical and Laboratory">
              <img
                src={logo}
                alt="Labora - Medical and Laboratory"
                width="171"
                height="35"
              />
            </Link>
          </div>

          <div className="header-rightpart">
            <div className="primarymenu menuwrap">
              <ul className="sf-menu">
                <li>
                  <NavLink
                    to="/"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/about-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="menu-item menu-item-has-children">
                  <NavLink
                    to="#"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    PRODUCT
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/apis">APIs</NavLink>
                    </li>
                    <li>
                      <NavLink to="/drugintermediates">
                        Drug-intermediates
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/finechemicals">Fine-chemicals</NavLink>
                    </li>
                    <li>
                      <NavLink to="/labgradechemical">
                        Lab-gradechemical
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    to="/ContractManufacturing"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Contract Manufacturing
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                    to="/contact-us"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div id="iva-mobile-nav-icon" className="iva-mobile-dropdown">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="iva-mobile-menu" style={{ display: "none" }}>
          <ul className="iva_mmenu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="#">
                Headers
                <span className="iva-children-indenter">
                  <i className="fa fa-angle-down"></i>
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/header-style2">Header Style 2</Link>
                </li>
                <li>
                  <Link to="/header-style3">Header Style 3</Link>
                </li>
                <li>
                  <Link to="/header-style4">Header Style 4</Link>
                </li>
                <li>
                  <Link to="/header-fixed">Header Fixed</Link>
                </li>
                <li>
                  <Link to="/vertical-menu">Vertical Left Menu</Link>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/about-us"
                style={({ isActive }) => ({
                  color: isActive ? "#990b6e" : "transparent",
                })}
              >
                About Us
                <span className="iva-children-indenter">
                  <i className="fa fa-angle-down"></i>
                </span>
              </NavLink>
              <ul className="sub-menu">
                <li>
                  <Link to="/company-history">Company History</Link>
                </li>
                <li>
                  <Link to="/our-approach">Our Approach</Link>
                </li>
                <li>
                  <Link to="/our-partners">Our Partners</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/team-grid">Our Team Grid</Link>
                </li>
                <li>
                  <Link to="/our-team-list">Our Team List</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/case-grid">
                Case Grid
                <span className="iva-children-indenter">
                  <i className="fa fa-angle-down"></i>
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/case-with-filters">Case with Filters</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/our-services">
                Our Services
                <span className="iva-children-indenter">
                  <i className="fa fa-angle-down"></i>
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/service1">Services 1</Link>
                </li>
                <li>
                  <Link to="/service2">Services 2</Link>
                </li>
                <li>
                  <Link to="/service3">Services 3</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/press-releases">Press Releases</Link>
            </li>
            <li>
              <Link to="/shortcode">Shortcodes</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="iva-mobile-menu">
        <ul className="iva_mmenu">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="#">
              Headers{" "}
              <span className="iva-children-indenter">
                <i className="fa fa fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/header-style2">Header Style 2</NavLink>
              </li>
              <li>
                <NavLink to="/header-style3">Header Style 3</NavLink>
              </li>
              <li>
                <NavLink to="/header-style4">Header Style 4</NavLink>
              </li>
              <li>
                <NavLink to="/header-fixed">Header Fixed</NavLink>
              </li>
              <li>
                <NavLink to="/vertical-menu">Vertical Left Menu</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/about-us">
              About Us
              <span className="iva-children-indenter">
                <i className="fa fa fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/company-history">Company History</NavLink>
              </li>
              <li>
                <NavLink to="/our-approach">Our Approach</NavLink>
              </li>
              <li>
                <NavLink to="/our-partners">Our Partners</NavLink>
              </li>
              <li>
                <NavLink to="/careers">Careers</NavLink>
              </li>
              <li>
                <NavLink to="/team-grid">Our Team Grid</NavLink>
              </li>
              <li>
                <NavLink to="/our-team-list">Our Team List</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/case-grid">
              Case Grid{" "}
              <span className="iva-children-indenter">
                <i className="fa fa fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/case-with-filters">Case with Filters</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/our-services">
              Our Services{" "}
              <span className="iva-children-indenter">
                <i className="fa fa fa-angle-down" />
              </span>
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/service1">Services 1</NavLink>
              </li>
              <li>
                <NavLink to="/service2">Services 2</NavLink>
              </li>
              <li>
                <NavLink to="/service3">Services 3</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/press-releases">Press Releases</NavLink>
          </li>
          <li>
            <NavLink to="/shortcode">Shortcodes</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
