import React from 'react'

const SodiumNitrite = () => {
  return (
    <div>
        <div className="other-info-section-main">
  <div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <p style={{ marginBottom: "0cm"}}>
            <strong>Product Details :</strong>
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="128*" />
              <col width="128*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Product Name</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="50%"
                >
                  <p>: SODIUM NITRITE</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>C.A.S. No.</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 7632-00-0</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Formula</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    : NaNO<sub>2</sub>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Weight</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 69.00 g/mol</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginBottom: "0cm"}}>
            <br />
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>SPECIFICATION</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>IP</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>BP</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="25%"
                >
                  <p>
                    <strong>USP</strong>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Description</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    Colorless crystals or mass or yellowish rods ,hygroscopic
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    Colorless crystals or mass or yellowish rods ,hygroscopic
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    Colorless crystals or mass or yellowish rods ,hygroscopic
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Solubility</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>Freely soluble in water and soluble in ethanol (96 %).</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <strong>Identification</strong>
                  </p>
                  <ol type="A">
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Color</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Color</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Reaction</strong>
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />A : It gives reaction of Sodium
                  </p>
                  <p>B : It gives reaction of Nitrite</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />A : An intense red color developed
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B : An intense green color developed
                  </p>
                  <p>C : It gives reaction of Sodium</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />A : It gives reaction of Sodium
                  </p>
                  <p>B : It gives reaction of Nitrite</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Appearance of solution</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    Solution S is clear and not more intensely colored than
                    reference solution B6
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Acidity &amp; Alkalinity</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    The solution is red add 0.3 ml of 0.01M HCL , the solution
                    is yellow
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Chloride</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 50 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Sulphate</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p> NMT 200 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Heavy metals</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Residual solvents</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>Meets the requirement</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>LOD </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 0.25 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 1.0%</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>NMT 0.25 %</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Assay (on dry basis)</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>97.0 % - 101.0 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>98.5 % - 100.5%</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="25%"
                >
                  <p>97.0 % - 101.0 %</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default SodiumNitrite