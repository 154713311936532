import React from "react";

const SodiumPhosphateDibasicDihydrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: SODIUM PHOSPHATE DIBASIC DIHYDRATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 10028-24-7</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          : Na<sub>2</sub>HPO<sub>4</sub>.2H<sub>2</sub>O
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 177.99 g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>USP</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Description</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          Colorless, Transparent crystals; very efflorescent.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Appearance of Solution </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          Solution should be clear and
                        </p>
                        <p>colorless</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Identification</strong>
                        </p>
                        <ul>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              <strong>Reaction</strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Reaction</strong>
                            </p>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p
                          align="left"
                          lang="en-US"
                          style={{ marginBottom: "0cm" }}
                        >
                          <font face="Calibri, serif">
                            <br />
                            A: Gives reaction of phosphates.
                          </font>
                        </p>
                        <p>B : Gives reaction of sodium</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Insoluble Substances</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>NMT 0.4%</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Arsenic</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>NMT 16 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Chloride</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>NMT 600</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Sulphate</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>NMT 2000 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Residual solvents</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>Meets the requirement</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Heavy Metal </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>NMT 20 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>
                            Loss on drying(at 130<sup>o</sup>C)
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>18.5 %-21.5 %</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Assay (on Dried Basis)</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>98.0% - 100.5%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumPhosphateDibasicDihydrate;
