import React from "react";
import SubHeader from "../SubHeader";
import Footer from "../Footer";

const DrugIntermediates = () => {
  const data = [
    {
      name: "4 – Sulfonamido Phenyl Hydrazine Hydrochloride (Celecoxib Intermediate)",
      casNo: "17852-52-7",
    },
  ];

  const tableStyle = {
    margin: "20px auto",
    borderCollapse: "collapse",
    width: "80%", // Adjust width as needed
  };

  const thTdStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };

  return (
    <div>
      <SubHeader curruntPath={"DRUG INTERMEDIATES"} />

      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thTdStyle}>Component</th>
            <th style={thTdStyle}>CAS No</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td style={thTdStyle}>{item.name}</td>
              <td style={thTdStyle}>{item.casNo}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DrugIntermediates;
