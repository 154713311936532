import React from "react";

const MagnesiumChlorideHexahydrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0in" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="50%"
                      >
                        <p>: MAGNESIUM CHLORIDE HEXAHYADRATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 7791-18-6</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          : MgC<sub>l2</sub>.6H<sub>2</sub>O
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 203.3g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="85*" />
                    <col width="85*" />
                    <col width="85*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">SPECIFICATION</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">IP</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">BP</span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Appearance</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Colorless crystals, hygroscopic.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Colorless crystals, hygroscopic.
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Appearance of solution</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Solution is clear and colorless</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Solution is clear and colorless</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Solubility</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Very Soluble in water, freely soluble in ethanol
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <strong>
                            <span lang="en">Identification</span>
                          </strong>
                        </p>
                        <ul>
                          <li>
                            <strong>
                              <span lang="en">Water</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">
                              It gives reaction of Chlorides.
                            </span>
                          </li>
                          <li>
                            <span lang="en">
                              It gives reaction of magnesium salts
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">Complies with water test</span>
                          </li>
                          <li>
                            <span lang="en">
                              It gives reaction of Chlorides.
                            </span>
                          </li>
                          <li>
                            <span lang="en">
                              It gives reaction of magnesium
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">pH</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Insoluble matter</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Acidity or Alkalinity</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            NMT 0.3 ml of 0.01 M Hydrochloric acid or 0.01 M
                            Sodium hydroxide is required to change the color of
                            the indicator.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            NMT 0.3 ml of 0.01 M Hydrochloric acid or 0.01 M
                            Sodium hydroxide is required to change the color of
                            the indicator.
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Bromides</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 500 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sulphates</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Aluminium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Arsenic</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 2 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 2 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Calcium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.1 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.1 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Iron</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Potassium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 500 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Barium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Heavy Metals</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Organic Volatile impurities</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Residual Solvents</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Water</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">51.0 % - 55.0 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Assay</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">98.0 %-101.0%</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">98.0 %-101.0%</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagnesiumChlorideHexahydrate;
