import React from "react";
import cobalt from "../../images/cobalt carbonate.jpg";
const Cobalt = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Cobalt Carbonate
      </h2>
      <p>
        We offer Cobalt Carbonate, which is the inorganic compound that is
        identified with its chemical formula; CoCO3. For manufacturing this
        reddish paramagnetic solid substance, we use optimal quality
        ingredients. Basically, it works as an intermediate in the
        hydro-metallurgical cleansing of cobalt from its subunits. It is an
        inorganic coloring material as well as a precursor to activators. This
        is made by heating cobaltous sulfate with a mixture of sodium
        bicarbonate. Cobalt Carbonate is not soluble in water, but is promptly
        afflicted by mineral acids.
      </p>
      <h2 style={{ textTransform: "uppercase" }}>Cobalt Carbonate (LR)</h2>
      <img src={cobalt} alt="" />
    </div>
  );
};

export default Cobalt;
