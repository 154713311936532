import React from "react";

const Product = () => {
  return (
    <div>

    </div>
  );
};

export default Product;
