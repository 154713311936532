import React from "react";

const SodiumCitrateTribasicDihydrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: SODIUM CITRATE TRIBASIC DIHYDARTE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 6132-04-3</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          : C<sub>6</sub>H<sub>5</sub>Na<sub>3</sub>O
                          <sub>7</sub>.2H
                          <sub>20</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>: 294.1 g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>IP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>BP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>USP</strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Description</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          {" "}
                          White Granular crystals or a white, crystalline powder
                          odorless; slightly deliquescent in moist air.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          A white or almost white, crystalline powder or white
                          or almost white, granular crystals, slightly
                          deliquescent in moist air
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          A white or almost white, crystalline powder or
                          colorless crystals
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Solubility</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Freely soluble in water, practically insoluble in
                          ethanol (96 % )
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Identification</strong>
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>(A)Reaction</strong>
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>(B)Reaction</strong>
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          A : It gives reaction of Citrates{" "}
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          B: It gives reaction of Sodium salts.
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          A : It gives reaction of Citrates
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          B : It gives reaction of Sodium
                        </p>
                        <p style={{ marginBottom: "0cm" }}>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          A : It gives reaction of Citrate &amp; Sodium
                        </p>
                        <p>
                          B : Upon ignition it yields an alkaline residue which
                          effervesces when treated with 3 N Hcl
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Appearance of Solution</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Clear &amp; Colorless</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Clear &amp; Colorless</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Acidity or Alkalinity</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          NMT 0.5 ml of 0.05 M sulphuric acid or 0.1 M sodium
                          hydroxide is required.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          NMT 0.2 ml of 0.1 M Hydrochloric acid or 0.1 M sodium
                          hydroxide is required to change the color of
                          indicator.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          No pink color is produced by addition of
                          phenolphthalein TS
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Chloride</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 100 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 50 ppm.</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 350 ppm.</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Sulphates</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 150 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 150 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 50 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Oxalates</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 300 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 300 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Tartrates</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>No crystalline precipitate is formed</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>No crystalline precipitate is formed</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Heavy Metals</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 10 ppm.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 10 ppm.</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Readily carbonisable substances</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The solution is not more intensely colored than
                          reference solution YS2 or GYS2
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The solution is not more intensely colored than
                          reference solution Y2 or GY2
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Water</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>11.0 %-13.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>11.0 %-13.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>10.0 %-13.0 %</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Pyrogens</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>It should comply with the test for Pyrogens.</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Residual Solvents</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>Meets the requirement</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Arsenic</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 2 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>Assay </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.0% - 101.0%</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.0% - 101.0%</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="25%"
                      >
                        <p>99.0 % - 101.0 %</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumCitrateTribasicDihydrate;
