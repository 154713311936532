import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <div id="footer">
        {location.pathname === "/contact-us" ? null : (
          <div className="footer-area-top">
            <div className="inner">
              <div className="at-callOutBox">
                <div className="at-callOut_inner">
                  <div className="at-callOut-action">
                    <div className="at-callOut_text">
                      <div className="at-callout-action-text">
                        <h1
                          style={{
                            fontSize: "24px",
                            color: "#ffffff",
                            fontWeight: "500",
                            letterSpacing: "-1px",
                          }}
                        >
                          If You have any reqiurement of Chemicals ... We Are
                          Available For You
                        </h1>
                      </div>
                    </div>
                    <div className="at-callOut_btn">
                      <div className="at-callout-action-button">
                        <NavLink
                          to={"contact-us"}
                          className="btn large"
                          style={{
                            color: "#ffffff",
                            borderColor: "#ffffff",
                          }}
                          data-btn-color="#f0f0f0"
                        >
                          <span className="btn-text">CONTACT US </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="footer-area-middle">
          <div className="inner">
            <div className="inner-row">
              <aside id="text-4" className="whitetext widget widget_text col_3">
                <div className="textwidget">
                  <p>
                    <img src="assets/images/logo-white.png" alt="Labora" />
                  </p>
                  <h5>
                    Our dedication for quality and excellence has not only
                    allowed us to become leaders in the industry, it motivates
                    us to continuously innovate and improve on our products and
                    processes.
                  </h5>
                </div>
              </aside>
              <aside id="nav_menu-6" className="widget widget_nav_menu col_3">
                <h3 className="widget-title">Our Product</h3>
                <div className="menu-footer-one-container">
                  <ul className="menu">
                    <li>
                      <NavLink to={""}>- APIs</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>- Drug-intermediates</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>- Fine-chemicals</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>- chemical researchn</NavLink>
                    </li>
                    <li>
                      <NavLink to={""}>- LabGrade-Chemicals</NavLink>
                    </li>
                  </ul>
                </div>
              </aside>
              <aside id="nav_menu-5" className="widget widget_nav_menu col_3">
                <h3 className="widget-title">Quick Links</h3>
                <div className="menu-footer-one-container">
                  <ul className="menu">
                    <li>
                      <NavLink to={"/about-us"}>- About Us</NavLink>
                    </li>
                    <li>
                      <NavLink>- Career</NavLink>
                    </li>
                    <li>
                      <NavLink>- Get Quote</NavLink>
                    </li>
                  </ul>
                </div>
              </aside>
              <aside
                id="contactinfo_widgets-2"
                className="widget contactinfo-wg col_3"
              >
                <h3 className="widget-title">Get in Touch</h3>
                <div className="contactinfo-wrap">
                  <div style={{ display: "flex" }}>
                    <span className="icon fa fa-location-arrow fa-lg"></span>
                    <span className="details" style={{ marginLeft: "15px" }}>
                      <NavLink to={"/contact-us"}>
                        Reg.off.B-56 Kiran Park Society,Nalanda school-2 Punagam
                        Surat-395010 , India
                      </NavLink>
                    </span>
                  </div>
                  <p className="phone">
                    <span className="icon fa fa-phone fa-lg"></span>
                    <span className="details">(+91)96870 52936</span>
                  </p>
                  <p className="phone">
                    <span className="icon fa fa-regular fa-envelope"></span>
                    <span className="details">info@aartichempharma.com</span>
                  </p>
                  <p>
                    <span className="icon fa fa-map-marker fa-lg"></span>
                    <span className="details">
                      <NavLink className="contact-us" href="/contact-us">
                        View Map
                      </NavLink>
                    </span>
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>

        <div className="footer-area-bottom">
          <div className="inner">
            <aside id="text-7" className="textcenter widget widget_text">
              <div className="textwidget">
                Copyright © aartichempharma 2016.. All rights reserved.{" "}
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
