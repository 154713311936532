import React from "react";

const PotassiumSulphate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p align="justify">
                  With an objective to serve clients in the best possible
                  manner, we manufacture and supply superior grade{" "}
                  <strong>Potassium Sulphate</strong>. This product is processed
                  using precise composition of chemical compounds and
                  contemporary methodology by our dexterous professionals. The
                  provided product is preferred by farmers for high value
                  horticultural crop production. Extensively demanded in
                  agriculture sector, this product is an excellent source of
                  vitamin K. Furthermore, the offered{" "}
                  <strong>Potassium Sulphate</strong> can be bought from us at
                  market leading prices.
                </p>
                <p align="justify">
                  <strong>Key points:</strong>
                </p>
                <ul>
                  {" "}
                  <li>
                    Highly pure
                    <br />
                  </li>{" "}
                  <li>Standard shelf life</li> <li>No side effects</li>{" "}
                  <li>
                    Hygienically processed
                    <br />{" "}
                  </li>{" "}
                </ul>{" "}
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                  }}
                />{" "}
                <p lang="en-US" style={{ pageBreakBefore: "always" }}>
                  <strong>Potassium Sulphate </strong>{" "}
                </p>{" "}
                <ul>
                  <li>
                    <strong>C.A.S. No. </strong>: 7778-80-5{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula</strong> : K2so4{" "}
                  </li>
                  <li>
                    <span lang="en-US">
                      <strong>Molecular Weight :</strong>
                    </span>
                    <span lang="en-US"> 174.26 </span>{" "}
                  </li>
                </ul>{" "}
                <center>
                  {" "}
                  <table cellSpacing={0} cellPadding={0} width="100%">
                    {" "}
                    <colgroup>
                      <col width="64*" /> <col width="64*" />{" "}
                      <col width="64*" /> <col width="64*" />{" "}
                    </colgroup>
                    <tbody>
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>SPECIFICATION</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">LR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">AR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            border: "1px solid rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">ACS</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Insoluble Matter</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>pH of a 5% solution at 250C</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">5.5 - 7.5</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">6.0 - 8.0</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">5.5 - 8.5</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Nitrogen Compounds (N)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Chloride (Cl)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.03%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Heavy Metals (as Pb)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Iron (Fe)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Calcium (Ca)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0025%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Copper (Cu)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">----</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Magnesium (Mg)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Sodium (Na)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.2%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.02%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.02%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Assay</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.0%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.0%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                </center>{" "}
                <p>
                  <br />{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotassiumSulphate;
