// export default Homepage;
import React, { useEffect, useLayoutEffect } from "react";
import slider_1 from "../images/slide-1.jpg";
import slider_2 from "../images/slide-2.jpg";
import service from "../images/services.jpg";
import ser_img3 from "../images/ser-img3.jpg";
import ser_img5 from "../images/ser-img5.jpg";
import paoermeel from "../images/papermeel.jpg";
import silicon from "../images/silicon.png";
import portfolio9 from "../images/portfolio9.jpg";
import diamond from "../images/diamond.svg";
import vision from "../images/icons8-vision-48.png";
import mission from "../images/icons8-goal-50.png";
import { NavLink } from "react-router-dom";

const Homepage = () => {
  // useEffect(() => {
  //   if (window.jQuery && window.jQuery.fn.flexslider) {
  //     window.jQuery(".flexslider").flexslider({
  //       animation: "fade",
  //       controlsContainer: ".flex-container",
  //       slideshow: true,
  //       slideshowSpeed: 3000,
  //       animationSpeed: 1200,
  //       directionNav: true,
  //       controlNav: false,
  //       mousewheel: false,
  //       smoothHeight: false,
  //       prevText: " ", //String: Set the text for the "previous" directionNav item
  //       nextText: " ", //String: Set the text for the "next" directionNav item
  //       start: function (slider) {
  //         window.jQuery(".total-slides").text(slider.count);
  //         window
  //           .jQuery(slider.slides[slider.currentSlide])
  //           .find(".flex-caption")
  //           .addClass("flex-caption-anim");
  //       },
  //       before: function (slider) {
  //         window
  //           .jQuery(slider.slides)
  //           .find(".flex-caption")
  //           .removeClass("flex-caption-anim");
  //       },
  //       after: function (slider) {
  //         window
  //           .jQuery(slider.slides[slider.animatingTo])
  //           .find(".flex-caption")
  //           .addClass("flex-caption-anim");
  //       },
  //     });
  //   }
  // }, []);

  // useLayoutEffect(() => {
  //   if (window.jQuery && window.jQuery.fn.flexslider) {
  //     window.jQuery(".flexslider").flexslider({
  //       animation: "fade",
  //       controlsContainer: ".flex-container",
  //       slideshow: true,
  //       slideshowSpeed: 3000,
  //       animationSpeed: 1200,
  //       directionNav: true,
  //       controlNav: false,
  //       mousewheel: false,
  //       smoothHeight: false,
  //       prevText: " ",
  //       nextText: " ",
  //       start: function (slider) {
  //         window.jQuery(".total-slides").text(slider.count);
  //         window
  //           .jQuery(slider.slides[slider.currentSlide])
  //           .find(".flex-caption")
  //           .addClass("flex-caption-anim");
  //       },
  //       before: function (slider) {
  //         window
  //           .jQuery(slider.slides)
  //           .find(".flex-caption")
  //           .removeClass("flex-caption-anim");
  //       },
  //       after: function (slider) {
  //         window
  //           .jQuery(slider.slides[slider.animatingTo])
  //           .find(".flex-caption")
  //           .addClass("flex-caption-anim");
  //       },
  //     });
  //   }
  // });

  useEffect(() => {
    if (window.jQuery && window.jQuery.fn.flexslider) {
      const slider = window.jQuery(".flexslider");
      if (!slider.hasClass("flexslider-initialized")) {
        slider.flexslider({
          animation: "fade",
          controlsContainer: ".flex-container",
          slideshow: true,
          slideshowSpeed: 3000,
          animationSpeed: 1200,
          directionNav: true,
          controlNav: false,
          mousewheel: false,
          smoothHeight: false,
          prevText: " ",
          nextText: " ",
          start: function (slider) {
            window.jQuery(".total-slides").text(slider.count);
            window
              .jQuery(slider.slides[slider.currentSlide])
              .find(".flex-caption")
              .addClass("flex-caption-anim");
          },
          before: function (slider) {
            window
              .jQuery(slider.slides)
              .find(".flex-caption")
              .removeClass("flex-caption-anim");
          },
          after: function (slider) {
            window
              .jQuery(slider.slides[slider.animatingTo])
              .find(".flex-caption")
              .addClass("flex-caption-anim");
          },
        });
      }
    }

    if (window.jQuery && window.jQuery.fn.owlCarousel) {
      window.jQuery("#owl-20").owlCarousel({
        items: 4,
        itemsDesktop: [1199, 3],
        itemsDesktopSmall: [979, 3],
        itemsTablet: [768, 2],
        itemsMobile: [479, 1],
        navigation: false,
        pagination: false,
        autoPlay: true,
        autoplay: true,
        autoplayHoverPause: false,
        autoplayTimeout: 2,
      });
    }
  }, []);

  return (
    <>
      <section id="featured_slider" class="clearfix">
        <div class="slider_wrapper">
          <div class="flexslider">
            <ul class="slides">
              <li>
                <img alt="Evaluation of the Current Safety" src={slider_1} />
                <div class="flex-caption fs-left flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>
                        We are the Largest Independent manufacturer Company
                      </h2>
                      <div class="flex-content">
                        We are one of the leading traders, importers and
                        suppliers of Industrial Chemicals And Acids. <br />
                        These dyes and chemicals are sourced from the most
                        reliable vendors of the marke
                      </div>
                    </div>
                    <button class="first_slider_button">learn more</button>
                    <button>our sevices</button>
                  </div>
                </div>
              </li>
              <li>
                <img
                  alt="Laboratory Priority Services Delivered"
                  src={slider_2}
                />
                <div class="flex-caption fs-right flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>Most reliable partner</h2>
                      <div class="flex-content">
                        <p>
                          A reliable company to work with then you're on to good
                          thing, carefull indentified the best strategic
                          partners.
                        </p>
                        <button class="first_slider_button">learn more</button>
                        <button>our sevices</button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <img alt="Evaluation of the Current Safety" src={slider_1} />
                <div class="flex-caption fs-left flex-caption-anim">
                  <div class="flex-caption-inner">
                    <div class="flex-title">
                      <h2>MANUFACTURTE AND EXPORTER SUPERIOR QUALITY</h2>
                      <button class="first_slider_button">learn more</button>
                      <button>our sevices</button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="flex-direction-nav">
              <li>
                <NavLink class="flex-prev" to={""}>
                  {" "}
                </NavLink>
              </li>
              <li>
                <NavLink class="flex-next" to={""}>
                  {" "}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div id="main" class="content-area">
        <div class="section_row clearfix">
          <div class="section_inner">
            <div class="at-fancy-heading-v1 textcenter">
              <h2>
                Arti Pharma is the Indian based pharmaceutical company with over{" "}
                <strong style={{ color: "#990b6e" }}>
                  10 years of experience
                </strong>{" "}
                focusing on leading traders, importers and suppliers of
                Industrial Chemicals And Acids.
              </h2>
              <span class="at-fancy-heading-v1-sep"></span>
            </div>

            <div class="demo_space" style={{ height: "80px" }}></div>

            <div class="one_half">
              <img src="assets/images/photo1.jpg" alt="" />
            </div>

            <div class="one_half end">
              <h1>WELCOME TO ARTI PHARMACEUTICAL</h1>
              <p>
                <strong>Having a new name is just the beginning.</strong>
              </p>
              <p>
                Welcome to Arti Pharmaceutical, where our new name signifies the
                start of an exciting journey. We are committed to pioneering
                advancements in healthcare through innovative solutions and
                exceptional quality. Our mission is to enhance lives with
                products that meet the highest standards and address diverse
                healthcare needs. Join us as we continue to build on our legacy
                of trust and excellence, shaping the future of the
                pharmaceutical industry.
              </p>
              <p>
                <strong>
                  <NavLink href="/about-us">
                    MORE ABOUT US <i class="fa fa-long-arrow-right"></i>
                  </NavLink>
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="section_row clearfix" style={{ paddingTop: "0" }}>
          <div className="section_inner">
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-regular fa-hospital"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Research Center</h3>
                  In City
                </div>
              </div>
            </div>
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa fa-fw fa-2x fa-stethoscope"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Lab Technicians</h3>
                  Powerful &amp; Skilled
                </div>
              </div>
            </div>
            <div className="one_fourth">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-solid fa-money-bill-1"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Low Cost</h3>
                  Low cost research
                </div>
              </div>
            </div>
            <div className="one_fourth last">
              <div className="serviceIcn_style1">
                <div className="services-icon">
                  <i
                    className="services_icon1 fa-regular fa-envelope"
                    style={{ fontSize: "24px" }}
                  ></i>
                </div>
                <div className="services-content">
                  <h3>Notifications</h3>
                  Instant Email Alerts
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="partial_section_wrap clearfix"
          style={{ backgroundColor: "#e4ebef", padding: "0" }}
        >
          <div className="partial_section_image">
            <div>
              <img src="assets/images/photo2.jpg" alt="" />
            </div>
          </div>

          <div className="partial_section_content">
            <div className="ps_content clearfix">
              <div
                className="at-fancy-heading-v1 textleft"
                style={{ marginTop: "80px" }}
              >
                <h2 style={{ fontWeight: 800 }}>
                  We Are Trusted By World's Leading Companies
                </h2>

                <span className="at-fancy-heading-v1-sep"></span>
              </div>
              <div className="one_half">
                <h4>Innovation By Design Awards</h4>
                <p>
                  Arti Pharmaceuticals: Ayurvedic leader in India. Herbal
                  cosmetics & PCD pharma franchise. Empowering wellness,
                  naturally. Transforming lives, traditionally.
                  <br />
                </p>
              </div>
              <div className="one_half last">
                <p></p>
                <h4>Innovation by Design Awards</h4>
                <p>
                  Antacid (APCID)
                  <br />
                  Herbal cough syrup (Cuf-AP)
                  <br />
                  Anti-piles (APILE)
                  <br />
                  Pachak churna
                  <br />
                  TEJ Malam for skin
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section_row clearfix">
          <div className="section_inner">
            <div className="three_fourth">
              <div className="at-fancy-heading-v1">
                <h3>
                  <strong>NEWS &amp; EVENTS</strong>
                </h3>
              </div>

              <article id="post-2580" className="post one_third ">
                <div className="at-blog-sc-v1">
                  <div className="at-post-content">
                    <h2 className="at-entry-title">
                      <NavLink href="blog-single.html" rel="bookmark">
                        Exploring Anatomy & Physiology in the Pharmaceutical
                      </NavLink>
                    </h2>
                    <div className="at-entry-content">
                      Discover how anatomy and physiology shape pharmaceutical
                      development. Understand the impact of the human body on
                      drug design and effectiveness. Join us to explore the
                      vital connections in healthcare innovation.
                    </div>
                  </div>
                </div>
              </article>

              <article id="post-2577" className="post one_third">
                <div className="at-blog-sc-v1">
                  <div className="at-post-content">
                    <h2 className="at-entry-title">
                      <NavLink href="blog-single.html" rel="bookmark">
                        Medicine Diagnosis of Disease in Clinical Pharmaceutical
                      </NavLink>
                    </h2>
                    <div className="at-entry-content">
                      Explore the role of clinical pharmaceuticals in diagnosing
                      diseases. Understand how targeted therapies improve
                      patient outcomes. Join us to learn about the integration
                      of diagnostics and treatment in modern medicine.
                    </div>
                  </div>
                </div>
              </article>

              <article id="post-2320" className="post one_third last">
                <div className="at-blog-sc-v1">
                  <div className="at-post-content">
                    <h2 className="at-entry-title">
                      <NavLink href="blog-single.html" rel="bookmark">
                        Pharmaceutical and Diagnostic Tests with Nursing
                        Implications
                      </NavLink>
                    </h2>
                    <div className="at-entry-content">
                      Discover the critical role of pharmaceuticals and
                      diagnostic tests in patient care. Learn about the
                      implications for nursing practice and how they enhance
                      patient outcomes
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div className="one_fourth last">
              <div
                className="fancybox"
                style={{ backgroundColor: "#990b6e", padding: "50px 30px" }}
              >
                <h3 className="whitetext">
                  <strong>Best Pharmaceutical Award Winner</strong>
                </h3>
                <p style={{ opacity: 0.7, color: "#fff" }}>
                  Trustworthy and Loyalty.
                  <br />
                  New technologies.
                  <br />
                  Positive Results.
                </p>
                <p>
                  <NavLink
                    className="more-link"
                    style={{ color: "#fff" }}
                    to={"/about-us"}
                  >
                    LEARN MORE
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="inner">
          <main className="content-area">
            <div className="entry-content-wrapper clearfix">
              <div className="cases-align">
                <div className="at-cases-main">
                  <div className="one_third at-cases-item">
                    <div className="at-cases-details">
                      <img src={service} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title"> FINE CHEMICALS</span>
                      </div>
                    </div>
                  </div>

                  <div className="one_third at-cases-item">
                    <div className="at-cases-details">
                      <img src={paoermeel} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title">API-ACTIVE PHARMACEUTICAL</span>
                      </div>
                    </div>
                  </div>

                  <div className="one_third last at-cases-item">
                    <div className="at-cases-details">
                      <img src={ser_img3} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title"> INDUSTRIAL CHEMICALS</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="at-cases-main">
                  <div className="one_third at-cases-item">
                    <div className="at-cases-details">
                      <img src={portfolio9} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title">FOOD CHEMICALS</span>
                      </div>
                    </div>
                  </div>

                  <div className="one_third at-cases-item">
                    <div className="at-cases-details">
                      <img src={ser_img5} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title">
                          DYES AND DYE INTERMEDIATES
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="one_third last at-cases-item">
                    <div className="at-cases-details">
                      <img src={silicon} alt="" height="150" width="200" />
                      <div className="at-cases-content">
                        <span className="title">
                          PHARMACEUTICAL INTERMEDIATES
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div
          className="section_row clearfix"
          style={{ backgroundColor: "#e4ebef", padding: "40px 0" }}
        >
          <div className="section_inner">
            <div className="owl-wrapper">
              <div id="owl-20" className="owl-Carousel">
                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/crescento-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Crescento</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/finserv-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Finserv</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/storeup-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Storeup</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/keorra-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Keorra</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/crescento-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Crescento</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/finserv-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Finserv</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/storeup-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Storeup</span>
                  </div>
                </div>

                <div className="owl-item">
                  <div className="clientthumb">
                    <NavLink to={""} target="_blank">
                      <figure>
                        <img alt="" src="assets/images/keorra-logo.png" />
                      </figure>
                    </NavLink>
                    <span className="cl-title">Keorra</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <h1 style={{ textAlign: "center" }}>Experienced</h1>
          <p style={{ textAlign: "center" }}>
            Choose from our range of over 1,000 products, or let us make
            suggestions, drawing on our extensive experience in the Fine
            Chemicals and Pharma APIs manufacturing industry.
          </p>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              gap: "70px",
              marginTop: "2rem",
              justifyContent: "center", // Added this line to center horizontally
            }}
          >
            <div class="serviceIcn_style1">
              <div class="services-icon">
                <img
                  src={diamond}
                  class="services_icon1 fa fa-fw fa-2x fa-diamond fa-4x"
                  style={{ color: "#990b6e", height: "50px" }}
                ></img>
              </div>
              <div class="services-content"></div>
              <p>VALUES</p>
            </div>
            <div>
              <img src={vision} alt="Vision" />
              <p>VISION</p>
            </div>
            <div>
              <img src={mission} alt="Mission" />
              <p>MISSION</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
