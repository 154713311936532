import React from "react";
import SubHeader from "../SubHeader";

export const WaterTreatment = () => {
  return (
    <>
      <SubHeader curruntPath={"Industrial And Water Treatment Chemical"} />

      <div style={{ padding: "20px", margin: "20px" }}>
        <table border="1" cellPadding="10">
          <thead>
            <tr>
              <th>Product</th>
              <th>Concentration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Chlorinated Paraffin Wax</td>
              <td>52%</td>
            </tr>
            <tr>
              <td>Hydrochloric Acid</td>
              <td>33%</td>
            </tr>
            <tr>
              <td>Sulphuric Acid</td>
              <td>98%</td>
            </tr>
            <tr>
              <td>Nitric Acid</td>
              <td>68%</td>
            </tr>
            <tr>
              <td>Ferric Chloride</td>
              <td>40%</td>
            </tr>
            <tr>
              <td>Ferric Chloride Anhydrous</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Ferric Chloride Hexahydrate</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Caustic Soda Flakes & Lye</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Calcium Chloride Prills</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Hydrated Lime</td>
              <td>90-92%</td>
            </tr>
            <tr>
              <td>Industrial Salt</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Ammonia Solutions</td>
              <td>23-25%</td>
            </tr>
            <tr>
              <td>Sodium Sulphate Anhydrous</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Sodium Silicate Liquid/Lumps</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Sodium Lauryl Ether Sulphate (SLES)</td>
              <td>70%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
