import React from 'react'

const SodiumBenzoate = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <strong>Product Details :</strong>
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="128*" />
                <col width="128*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Product Name</strong>
                    </p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="50%"
                  >
                    <p>: SODIUM BENZOATE</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>C.A.S. No.</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 532-32-1</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Formula</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      : C<sub>7</sub>H<sub>5</sub>NaO<sub>2</sub>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Weight</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 144.10 g/mol</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <br />
            </p>
            <table cellPadding={2} cellSpacing={0} width="100%">
              <colgroup>
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.04cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>SPECIFICATION</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.04cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>IP</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.04cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>BP</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>USP</strong>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Description</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      A white or almost white, crystalline or granular powder or
                      flakes , odorless or with a faint odour ;hygroscopic
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      A white or almost white, crystalline or granular powder or
                      flakes , slightly hygroscopic
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      A white or almost white, crystalline or granular powder or
                      flakes , slightly hygroscopic
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Solubility</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      Freely soluble in water, sparingly soluble in ethanol (90 %
                      )
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>Identification</strong>
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>(A)Reaction</strong>
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>(B)Reaction</strong>
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>(C)Reaction</strong>
                    </p>
                    <p align="left" lang="en-US">
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />A : It gives reaction of Benzoate
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      B: It gives reaction of Sodium salts.
                    </p>
                    <p>C: A white Crystalline precipitate is produced.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      A : It gives reaction of Benzoate
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      B : It gives reaction of Sodium
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />A : It gives reaction of Benzoate
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      B : It gives reaction of Sodium
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Appearance of Solution</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      10 % w/v solution is not more intensely Colored than
                      reference solution YS6.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      10 % w/v solution is not more intensely Colored than
                      reference solution Y6.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>Acidity &amp; Alkalinity</strong>
                    </p>
                    <p>
                      <strong>( USP)</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      NMT. 0.2 ml of 0.1M sodium hydroxide or 0.1M HCL is required
                      to change the colour of the solution
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      NMT. 0.2 ml of 0.1M sodium hydroxide or 0.1M HCL is required
                      to change the color of indicator
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>The pink color is produced</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Arsenic</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 2 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Chlorinated Compounds</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>The filtrate complies with limit test for chlorides</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Water</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>NMT. 1.5%</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Halogenated compounds</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      NMT. 200 ppm for Ionized chlorine and NMT. 300 ppm for total
                      chlorine
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Heavy Metals</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p align="left" lang="en-US">
                      <font face="Calibri, serif">NMT 20 ppm</font>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>NMT.10 ppm.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Organic Volatile Impurities</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p align="left" lang="en-US">
                      <font face="Calibri, serif">-</font>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>Meets the requirement</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Residual Solvents</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p align="left" lang="en-US">
                      <font face="Calibri, serif">-</font>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>Meets the requirement</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        Loss on drying ( 105<sup>o</sup>C)
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 2.0%</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 2.0%</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Assay (on dry basis )</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % - 100.5 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>99.0% - 100.5%</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.04cm 0.04cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % - 100.5 %</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default SodiumBenzoate