import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/artilogo.png";
import $ from "jquery";
import { windowScroll } from "./Footer";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 959;
      if (isMobile) {
        $(".header #menuwrap").hide();
        $(".header .iva-light-logo").hide();
        $(".header .iva-dark-logo").show();
      } else {
        $(".header #menuwrap").show();
        $(".iva-mobile-menu").hide();
        $(".header .iva-light-logo").show();
        $(".header .iva-dark-logo").hide();
      }
    };

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen((prev) => !prev);
      $(".iva-mobile-menu").slideToggle(500);
    };

    const closeMobileMenu = () => {
      setIsMobileMenuOpen(false);
      $(".iva-mobile-menu").slideUp(500);
    };

    const initMobileMenu = () => {
      $("#iva-mobile-nav-icon").on("click", toggleMobileMenu);

      // Close mobile menu on clicking a link
      $(".iva_mmenu a").on("click", closeMobileMenu);

      $(".iva-children-indenter").on("click", function () {
        $(this).parent().parent().toggleClass("iva-menu-open");
        $(this).parent().parent().find("> ul").slideToggle();
        return false;
      });
    };

    handleResize();
    initMobileMenu();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      $("#iva-mobile-nav-icon").off("click", toggleMobileMenu);
      $(".iva_mmenu a").off("click", closeMobileMenu);
      $(".iva-children-indenter").off("click");
    };
  }, []);

  const childIsActive = () => {
    const path = window.location.pathname;
    return (
      path.includes("/apis") ||
      path.includes("/drugintermediates") ||
      path.includes("/finechemicals") ||
      path.includes("/labgradechemical")
    );
  };

  return (
    <header>
      <div id="fixedheader" className="header">
        <div className="header-area">
          <div className="logo">
            <Link to="/" title="Labora - Medical and Laboratory">
              <img
                src={logo}
                alt="Labora - Medical and Laboratory"
                // width="171"
                height="80"
              />
            </Link>
          </div>

          <div className="header-rightpart">
            <div className="primarymenu menuwrap">
              <ul className="sf-menu">
                <li onClick={windowScroll}>
                  <NavLink
                    to="/"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Home
                  </NavLink>
                </li>
                <li onClick={windowScroll}>
                  <NavLink
                    to="/about-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="menu-item menu-item-has-children">
                  <NavLink
                    to="#"
                    style={() => ({
                      color: childIsActive() ? "#990b6e" : "black",
                    })}
                  >
                    PRODUCT
                  </NavLink>
                  <ul>
                    <li onClick={windowScroll}>
                      <NavLink to="/apis">APIs</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to="/drugintermediates">
                        Drug Intermediates
                      </NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to="/finechemicals">Fine Chemicals</NavLink>
                    </li>
                    <li onClick={windowScroll}>
                      <NavLink to="/labgradechemical">
                        Lab-grade Chemicals
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li onClick={windowScroll}>
                  <NavLink
                    to="/ContractManufacturing"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Contract Manufacturing
                  </NavLink>
                </li>
                <li onClick={windowScroll}>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                    to="/contact-us"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div id="iva-mobile-nav-icon" className="iva-mobile-dropdown">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          className={`iva-mobile-menu ${isMobileMenuOpen ? "open" : ""}`}
          style={{ display: isMobileMenuOpen ? "block" : "none" }}
        >
          <ul className="iva_mmenu">
            <li onClick={windowScroll}>
              <NavLink to="/">Home</NavLink>
            </li>
            <li onClick={windowScroll}>
              <NavLink to="/about-us">About Us</NavLink>
            </li>
            <li onClick={windowScroll}>
              <NavLink to="#">
                PRODUCT
                <span className="iva-children-indenter">
                  <i className="fa fa-angle-down"></i>
                </span>
              </NavLink>
              <ul className="sub-menu">
                <li onClick={windowScroll}>
                  <NavLink to="/apis">APIs</NavLink>
                </li>
                <li onClick={windowScroll}>
                  <NavLink to="/drugintermediates">Drug Intermediates</NavLink>
                </li>
                <li onClick={windowScroll}>
                  <NavLink to="/finechemicals">Fine Chemicals</NavLink>
                </li>
                <li onClick={windowScroll}>
                  <NavLink to="/labgradechemical">Lab-grade Chemicals</NavLink>
                </li>
              </ul>
            </li>
            <li onClick={windowScroll}>
              <NavLink to="/ContractManufacturing">
                Contract Manufacturing
              </NavLink>
            </li>
            <li onClick={windowScroll}>
              <NavLink to="/contact-us">Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
