import React from "react";

const AmmoniumPhosphateDibasic = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p lang="en-US">
                  <strong>Ammonium Phosphate Dibasic</strong>
                </p>
                <ul>
                  <li>
                    <strong>C.A.S. No</strong>. : 7783-28-0{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula </strong>: (NH4)2HPO4{" "}
                  </li>
                  <li>
                    <span lang="en-US">
                      <strong>Molecular Weight</strong> : 132.06{" "}
                    </span>
                  </li>
                </ul>
                <center>
                  <table cellPadding={0} cellSpacing={0} width="100%">
                    <colgroup>
                      <col width="64*" />
                      <col width="64*" />
                      <col width="64*" />
                      <col width="64*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td
                          height={28}
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>SPECIFICATION</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">LR</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">AR</p>
                        </td>
                        <td
                          style={{
                            border: "1px solid rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">ACS</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Insoluble Matter</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.003%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.005%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>pH of a 5% solution at 250C</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">7.5 - 8.5</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">7.8 - 8.5</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">7.7 - 8.1</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Chloride (Cl)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.0005%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Sulphate (SO4)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.02%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.01%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.01%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Heavy Metals (as Pb)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.002%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.0005%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Iron (Fe)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Arsenic (As)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.0001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Calcium (Ca)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Copper (Cu)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.0005%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Magnesium (Mg)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.0005%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Potassium (K)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.005%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Sodium (Na)</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">---</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.001%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">0.005%</p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          height={28}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">
                            <strong>Assay</strong>
                          </p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">98.5%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">NLT 99.0%</p>
                        </td>
                        <td
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            padding: "0cm",
                          }}
                          width="25%"
                        >
                          <p lang="en-US">NLT 98.0%</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </center>
                <p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmmoniumPhosphateDibasic;
