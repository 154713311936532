import React from "react";
import nickel1 from "../../images/nickle1.jpg";
import nickel2 from "../../images/nickel2.jpg";
import nickel3 from "../../images/nickel3.jpg";

const Nickle = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Nickel Sulphate Hexahydrate
      </h2>
      <p>
        We deal in Nickel Sulphate Hexahydrate, which appears as a green
        crystalline powder. This manufactured top-class chemical product is
        usually referred to the inorganic compound and has a chemical formula;
        NiSO4(H2O)6. This highly soluble chemical compound is the common root of
        the Ni2+ ion that is applicative for electroplating. Primarily and
        fittingly suitable for electroplating of nickel, this has been proven as
        a topmost allergen in many patch tests. Used for laboratory usages,
        Nickel Sulphate Hexahydrate comes with good solubility and optimal
        consistency a chemical must have..
      </p>{" "}
      <h2 style={{ textTransform: "uppercase" }}>
        Nickel Sulphate Hexahydrate (Pure)
      </h2>
      <img src={nickel1} alt="" style={{ marginBottom: "15px" }} />
      <h2 style={{ textTransform: "uppercase" }}>
        Nickel Sulphate Hexahydrate (LR)
      </h2>
      <img src={nickel2} alt="" style={{ marginBottom: "15px" }} />
      <h2 style={{ textTransform: "uppercase" }}>
        Nickel Sulphate Hexahydrate (AR)
      </h2>
      <img src={nickel3} alt="" style={{ marginBottom: "15px" }} />
    </div>
  );
};

export default Nickle;
