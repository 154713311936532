import React from "react";

const OurPartner = () => {
  return (
    <>
      <div id="main">
        <div id="primary" className="pagemid">
          <div className="inner">
            <main className="content-area">
              <div className="entry-content-wrapper clearfix">
                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Himedia laboratories</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Himedia Laboratories is a leading provider of
                        microbiological and laboratory chemicals, offering a
                        wide range of high-quality products for research and
                        industry.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Warkem biotech pvt ltd</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Warkem Biotech Pvt Ltd focuses on providing
                        biotechnology solutions and chemicals, serving
                        industries in food, agriculture, and healthcare.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Glide Chem</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Glide Chem is a trusted manufacturer of pharmaceutical
                        intermediates and specialty chemicals, known for their
                        commitment to quality and innovation.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Mil Laboratories</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Mil Laboratories specializes in the production of APIs
                        and intermediates, with a strong focus on research and
                        development in the pharmaceutical industry.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">CDH</a>
                    </h4>

                    <div className="desc">
                      <p>
                        CDH is known for producing laboratory chemicals,
                        reagents, and biochemicals, catering to a wide range of
                        industries including pharmaceuticals and research.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Sisco research laboratories</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Sisco Research Laboratories provides a vast range of
                        high-quality laboratory reagents and chemicals,
                        supporting research and industrial applications
                        globally.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">CTX Life Sciences</a>
                    </h4>

                    <div className="desc">
                      <p>
                        CTX Life Sciences is engaged in the manufacturing of
                        active pharmaceutical ingredients (APIs), contributing
                        to the global pharmaceutical supply chain.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image"></div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Hexon laboratories private limited</a>
                    </h4>

                    <div className="desc">
                      <p>
                        Hexon Laboratories Private Limited focuses on producing
                        pharmaceutical formulations and specialty chemicals,
                        committed to maintaining the highest standards of
                        quality.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPartner;
