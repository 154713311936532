import React from "react";
import visionImage from "../../images/ser-img3.jpg";
import visionImage2 from "../../images/services.jpg";

const OurVision = () => {
  return (
    <div className="vision-container">
      <div className="vision-text">
        <h2>OUR VISION</h2>
        <p>
          We wish you a warm welcome to AartiDyeChem. It's a Vision, a Dream, a
          Challenge. With great self-confidence, AartiDyeChem has been promoted
          in 2012 by two highly experienced & dedicated technocrats. We
          continuously strive at developing new & first-time in the world
          Products. We look forward to giving our Next Generation a ‘Clean & 
          Green’ world through innovative Chemistry.
        </p>
        <p>
          Our commitment to sustainability drives us to not only innovate but
          also to ensure that our processes are environmentally friendly. We
          believe that progress should not come at the expense of the planet. By
          integrating sustainable practices into our operations, we aim to set a
          benchmark in the chemical industry.
        </p>
        <p>
          We shall become the first choice vendor of bulk drugs and achieve a
          leadership position by:
        </p>
        <ul>
          <li>Assuring consistent quality and timely delivery at competitive prices.</li>
          <li>Providing customized solutions and services to meet changing requirements of customers.</li>
          <li>Choosing the best and most flexible manufacturing practices and methods.</li>
          <li>Adopting processes supported by proven technologies, which are cost-effective and safe.</li>
          <li>Aiming at customer orientation through continuous technology upgrades, high business ethics, and new product development.</li>
        </ul>

      </div>
      <div className="vision-images">
        <img src={visionImage} alt="Our Vision" className="vision-image" />
        <img src={visionImage2} alt="Our Vision" className="vision-image" />
      </div>
    </div>
  );
};

export default OurVision;
