import React from 'react'

const Cobalt = () => {
  return (
    <div>
      Cobalt
    </div>
  )
}

export default Cobalt
