import React from "react";

const DriedFerrousSulfate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0in"}}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="50%"
                      >
                        <p>: DRIED FERROUS SULFATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 7720-78-7</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          : FeSO<sub>4</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 151.91g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p lang="en" style={{ marginBottom: "0in"}}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="85*" />
                    <col width="85*" />
                    <col width="85*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">SPECIFICATION</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">IP</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">BP</span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Description</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Grayish white to buff colored powder.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Grayish white powder.</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Solubility</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Slowly but freely soluble in water. Very soluble in
                            boiling water, practically insoluble in ethanol
                            (96%)
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">pH</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">3.0-4.0</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <strong>
                            <span lang="en">Identification</span>
                          </strong>
                        </p>
                        <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                        <ul>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Assay</span>
                            </strong>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">Reaction of sulfates</span>
                          </li>
                          <li>
                            <span lang="en">Reaction of Ferrous Salt</span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">Reaction of sulfates</span>
                          </li>
                          <li>
                            <span lang="en">Reaction of Irons</span>
                          </li>
                          <li>
                            <span lang="en">
                              It complies with limit of assay.
                            </span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Chloride</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 300 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Insoluble Substances</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Basic sulphate</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Producing solution that is not more than faintly
                            turbid.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Arsenic</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 3 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Lead</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 50 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Mercury</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Chromium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Copper</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 50 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 50 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Ferric ions</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.5 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Manganese</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.1 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.1 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Nickel</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Zinc</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 500 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Organic volatile impurities</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Residual Solvents</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Assay</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">86.0 % - 90.0 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">86.0 % - 90.0 %</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriedFerrousSulfate;
