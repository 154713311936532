import React from "react";

const FerrousSulphateHeptahydrate = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Ferrous Sulphate Heptahydrate
      </h2>
      <div>
        Through our quality-oriented approach, we are manufacturing and
        supplying a foremost range of Ferrous Sulphate Heptahydrate. These are
        used for preparing medicines to restore iron levels in the body. Our
        Ferrous Sulphate Heptahydrate is formulated by a team of professionals
        as per the set industry norms using supreme grade components. Be it in
        domestic market or international, our offer chemicals are appreciated
        and cherished over the global platform.
      </div>{" "}
      <br />
      <div>Excellent purity </div> <div>Highly effective</div>{" "}
      <div>Precise composition</div> <br />
      <div>
        <h2 style={{ textTransform: "uppercase" }}>
          Ferrous Sulphate Heptahydrate – LR Grade
        </h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0 to 4.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Alkalis (Na)</td>
                      <td>0.1% Max</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>FERROUS SULPHATE HEPTAHYDRATE – EP Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay (By KMnO4)</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0 to 4.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Mercury (Hg)</td>
                      <td>0.0003% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.1% Max.</td>
                    </tr>
                    <tr>
                      <td>Ferric Ion (Fe3+)</td>
                      <td>0.2% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>FERROUS SULPHATE HEPTAHYDRATE – AR/GR Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% aq solution</td>
                      <td>3.0-4.0</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Magnesium</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium</td>
                      <td>0.02% Max.</td>
                    </tr>
                    <tr>
                      <td>Ferric Ion (Fe3+)</td>
                      <td>0.1% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>FERROUS SULPHATE HEPTAHYDRATE - ACS Grade</h2>
      <table>
        <tbody>
          <tr>
            <th>Property</th>
            <th>Details</th>
          </tr>
          <tr>
            <td>Synonyms</td>
            <td>
              Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
              Copperas Green Vitriol, Fesofor
            </td>
          </tr>
          <tr>
            <td>Molecular Formula</td>
            <td>FeSO4.7H2O</td>
          </tr>
          <tr>
            <td>Molecular Weight</td>
            <td>278.01</td>
          </tr>
          <tr>
            <td>CAS Number</td>
            <td>7782-63-0</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>
              Green Crystal or Crystalline Powder, Odorless, Efflorescent,
              Oxidized in moist Air.
            </td>
          </tr>
          <tr>
            <td>Clarity</td>
            <td>
              10% Solution in Dilute Sulphuric Acid is Clear blush Green
              Solution
            </td>
          </tr>
          <tr>
            <th>Specification</th>
            <td>
              <table>
                <tbody>
                  <tr>
                    <th>Specification</th>
                    <th>Value</th>
                  </tr>
                  <tr>
                    <td>Assay</td>
                    <td>99.0% Minimum</td>
                  </tr>
                  <tr>
                    <td>pH of 5% Solution</td>
                    <td>3.0 to 4.0</td>
                  </tr>
                  <tr>
                    <td>Insoluble Matter</td>
                    <td>0.01% Max.</td>
                  </tr>
                  <tr>
                    <td>Chloride (Cl)</td>
                    <td>0.001% Max.</td>
                  </tr>
                  <tr>
                    <td>Phosphate (PO4)</td>
                    <td>0.001% Max.</td>
                  </tr>
                  <tr>
                    <td>Copper (Cu)</td>
                    <td>0.005% Max.</td>
                  </tr>
                  <tr>
                    <td>Manganese (Mn)</td>
                    <td>0.05% Max.</td>
                  </tr>
                  <tr>
                    <td>Zinc (Zn)</td>
                    <td>0.005% Max.</td>
                  </tr>
                  <tr>
                    <td>Ferric Ion (Fe3+)</td>
                    <td>0.002% Max.</td>
                  </tr>
                  <tr>
                    <td>Substance Not Precipitated by Ammonium Hydroxide</td>
                    <td>0.05% Max.</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <h2>FERROUS SULPHATE HEPTAHYDRATE – IP Grade (Confirming) </h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0 to 105.0%</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0 to 4.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.025% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.1% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>FERROUS SULPHATE HEPTAHYDRATE – BP (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0 to 105.0%</td>
                    </tr>
                    <tr>
                      <td>pH of 5% aq solution</td>
                      <td>3.0-4.0</td>
                    </tr>
                    <tr>
                      <td>Chloride</td>
                      <td>0.03% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Ferric Ion (Fe3+)</td>
                      <td>0.5% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>FERROUS SULPHATE HEPTAHYDRATE – USP (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.5 to 104.5%</td>
                    </tr>
                    <tr>
                      <td>Lead</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Mercury</td>
                      <td>0.0003% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>FERROUS SULPHATE HEPTAHYDRATE – FCC (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Iron(II) Sulphate Heptahydrate, Green Vitriol, Iron Vitriol,
                Copperas Green Vitriol, Fesofor
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>FeSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>278.01</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7782-63-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Green Crystal or Crystalline Powder, Odorless, Efflorescent,
                Oxidized in moist Air.
              </td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.5 to 104.5%</td>
                    </tr>
                    <tr>
                      <td>Lead</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Mercury</td>
                      <td>0.0003% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <h2>
        We are able to provide you the Ferrous Sulphate Heptahydrate As per your
        Customized Specification
      </h2>
      Laboratory Reagent <br />
      Analytical Reagent <br />
      Pharmaceuticals
      <br />
      Ink
      <br />
      Pigments
      <br />
      Leather
      <br />
      Photography
      <br />
      Preservation Wood <br />
      Lithopone <br />
      Agriculture
      <br />
      Electroplating
      <br />
      Water Treatment
      <br />
      Cattle & Poultry Feed Supplements <br />
      Manufacturing of Other Organic & Inorganic basedFerrous Salts <br />
    </div>
  );
};

export default FerrousSulphateHeptahydrate;
