import React from "react";

const OurPrincipal = () => {
  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Company Overview
        </h2>
        <p>
          <strong style={{ color: "#990b6e" }}>Mission Statement:</strong> To
          improve health and well-being through innovative pharmaceuticals.
        </p>
        <p>
          <strong style={{ color: "#990b6e" }}>Vision and Values:</strong>{" "}
          Integrity, Quality, Innovation, and Collaboration.
        </p>
        <p>
          <strong style={{ color: "#990b6e" }}>Brief History:</strong>{" "}
          Established in 2015, we have achieved numerous milestones in the
          healthcare sector.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Products, Sourcing and Supply
        </h2>
        <p>
           At Aarti Chem Pharma, we pride ourselves on our ability to stay
          ahead of market trends through our agile and strategic product
          sourcing and supply capabilities. Our expertise lies in identifying
          shifts in market demand and proactively sourcing the highest quality
          raw materials, ensuring timely and cost-effective production for our
          clients. Our global supply chain network, coupled with strong
          relationships with trusted suppliers, allows us to adapt quickly to
          changes in the pharmaceutical landscape. Whether it's emerging
          therapeutic needs, regulatory shifts, or technological advancements,
          we ensure that our sourcing and supply practices remain flexible,
          efficient, and reliable. By closely monitoring market conditions and
          leveraging our in-depth industry insights, we help our clients
          navigate challenges like fluctuating material availability, cost
          changes, and compliance requirements. Our commitment to supply chain
          excellence ensures that our partners receive the right products at the
          right time, giving them a competitive edge in an
          ever-evolving marketplace.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Research and Development
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          At Aarti Chem Pharma, we are a leading research-driven manufacturing
          facility focused on the development and production of Active
          Pharmaceutical Ingredients (APIs) and drug intermediates for both
          regulated and semi-regulated markets. Our dedicated R&D team is at the
          forefront of process innovation and optimization, leveraging advanced
          analytical tools and modeling techniques to accelerate product
          development and scale-up. We utilize a data-driven approach to
          identify potential risks early in the process, ensuring our products
          not only meet but surpass regulatory and industry standards. At Aarti
          Chem Pharma, we are committed to continuous innovation and
          improvement. Our R&D efforts are advancing pharmaceutical science,
          helping us develop cutting-edge products that cater to the evolving
          needs of our clients across the globe.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Regulatory Compliance
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We adhere to strict safety and quality standards, holding numerous
          certifications. Our compliance with regulatory agencies ensures that
          our products meet all required guidelines and specifications. We
          regularly conduct audits and assessments to maintain the highest
          levels of quality. Our commitment to transparency and ethical
          practices reinforces our dedication to providing safe and effective
          pharmaceuticals. We strive to foster trust with our customers by
          consistently meeting and exceeding industry regulations.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Contact Information
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          For inquiries, please reach out through our{" "}
          <a href="contact-us">(+91) 96870 52936 | info@aartichempharma.com</a>.
          Our dedicated customer service team is available to assist you with
          any questions or concerns you may have. We encourage open
          communication and are committed to providing timely responses. Whether
          you have product inquiries, require support, or wish to discuss
          potential partnerships, we are here to help you.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Corporate Social Responsibility (CSR)
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We are dedicated to community initiatives and sustainability efforts.
          Our CSR programs focus on improving healthcare access, supporting
          education, and promoting environmental sustainability. We actively
          engage with local communities to understand their needs and
          collaborate on initiatives that make a meaningful impact. Through
          employee volunteering and partnerships with non-profit organizations,
          we strive to foster positive change and enhance the quality of life
          for those we serve. Our commitment to CSR reflects our core values and
          responsibility as a corporate citizen.
        </p>
      </section>
    </div>
  );
};

export default OurPrincipal;
