import React from 'react'

const CitricAcidMonohydrate = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p style={{ marginBottom: "0in"}}>
              <strong>Product Details :</strong>
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="128*" />
                <col width="128*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0.04in 0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Product Name</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0.04in"
                    }}
                    width="50%"
                  >
                    <p>:CITRIC ACID MONOHYDRATE</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>C.A.S. No.</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>:5949-29-1</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Formula</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>
                      : C<sub>6</sub>H<sub>8</sub>O<sub>7</sub>H<sub>2</sub>O
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Weight</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="50%"
                  >
                    <p>: 210.14</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0in"}}>
              <br />
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0.04in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">SPECIFICATION</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0.04in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">IP</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0.04in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">BP</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">USP</span>
                      </strong>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Description</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Colorless crystals or a white crystalline powder, slightly
                        efflorescent warm, dry air.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        White or almost white, crystalline powder, colorless
                        crystals or granules efflorescent.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">White Powder</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Solubility</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Very soluble in water, freely soluble in ethanol (96 %).
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Clarity of Solution</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        The Test solution shows the same clarity as that of water.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0in" }}>
                      <strong>
                        <span lang="en">Appearance of solution (color)</span>
                      </strong>
                    </p>
                    <p lang="en">
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        20 % w/v solution is clear and not more intensely colored
                        than reference solution YS7,BYS7 OR GYS7.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        20 % w/v solution is clear and color less or not more
                        intensely colored than reference solution Y7, BY7 and GY7.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        The test solution is not more intensely colored than
                        standard solution A,B, or C, or Water.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0in" }}>
                      <strong>
                        <span lang="en">Identification</span>
                      </strong>
                    </p>
                    <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                    <ul>
                      <li>
                        <strong>
                          <span lang="en">(A) Reaction</span>
                        </strong>
                      </li>
                      <li>
                        <strong>
                          <span lang="en">(B)) By IR Absorption</span>
                        </strong>
                      </li>
                      <li>
                        <strong>
                          <span lang="en">(C) Reaction</span>
                        </strong>
                      </li>
                      <li>
                        <strong>
                          <span lang="en">(D) Reaction</span>
                        </strong>
                      </li>
                      <li>
                        <strong>
                          <span lang="en">(E) Test of water</span>
                        </strong>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0in" }}>
                      <span lang="en">
                        <br />
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span lang="en">
                          A 10 % w/v solution is strongly acidic.
                        </span>
                      </li>
                      <li>
                        <span lang="en">Reactions of citrates</span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                    <p lang="en" style={{ marginBottom: "0in" }}>
                      <br />
                    </p>
                    <p lang="en" style={{ marginBottom: "0in" }}>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0in" }}>
                      <span lang="en">
                        <br />
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span lang="en">
                          10 % w/v solution is strongly acidic
                        </span>
                      </li>
                      <li>
                        <span lang="en">
                          Must comply with the Citric acid anhydrous CRS.
                        </span>
                      </li>
                      <li>
                        <span lang="en">A red color develops</span>
                      </li>
                      <li>
                        <span lang="en">White precipitate is produced</span>
                      </li>
                      <li>
                        <span lang="en">Complies with the test of water.</span>
                      </li>
                    </ul>
                    <p>&nbsp;</p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p lang="en" style={{ marginBottom: "0in" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0in" }}>
                      <span lang="en">By IR Spectrum</span>
                    </p>
                    <p lang="en" style={{ marginBottom: "0in" }}>
                      <br />
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Arsenic</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 1ppm.</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Bacterial end toxins</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        The level of bacterial endotoxins is such that the
                        requirement in the relevant dosage form monograph(S) in
                        which citric acid monohydrate is used can be met.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Barium</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Any opalescence in the solution is not more intense than
                        that in a mixture of 5 ml of solution and 5 ml of
                        distilled water.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Sterility</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">Meets the requirements for sterility.</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Calcium</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 200 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Heavy metals</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 10 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p lang="en">
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 10 ppm</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Iron</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 50 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Readily carbonisable substance</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Any color produced is not more than intense than that of
                        the a mixture of 0.6 ml of CCS and 5.4 ml of FCS.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        The solution is not more intensely colored than a mixture
                        of 1 ml of red primary solution and 9 ml of yellow primary
                        solution.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Any turbidity produced in test solution after 5 minutes
                        standing is not greater than that produced in the standard
                        solution (NMT 150 PPM)
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Sulphates</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 150 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 150 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 150 ppm</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Oxalic acid</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        Any pink color is produced is not more instance than that
                        produced by carrying out the test using 0.2 ml of oxalic
                        acid dissolve in 4 ml of water.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 360 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 360 ppm</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Limit of Aluminum</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 0.2 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">
                        The fluorescencence of the test solution does not exceed
                        that of the standard solution (0.2 g/g)
                      </span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Residual Solvents</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">Meets the requirements</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Chlorides</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 50 ppm</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">-</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Water by KF</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">7.5% - 9.0%</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">7.5 % - 9.0 %</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">7.5% - 9.0 %</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Sulphated ash</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 0.1 %</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 0.1 %</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">NMT 0.1 %</span>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>
                        <span lang="en">Assay</span>
                      </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">99.0 % to 101.0 %</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium medium 1px 1px",
                      borderStyle: "none none solid solid",
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">99.5 % - 100.5 %</span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderWidth: "medium 1px 1px",
                      borderStyle: "none solid solid",
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      padding: "0in 0.04in 0.04in"
                    }}
                    width="25%"
                  >
                    <p>
                      <span lang="en">99.5 % - 100.5 %</span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0in"}}>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default CitricAcidMonohydrate