import React from "react";
import imagevalue from "../../images/value2.png";
import value1 from "../../images/z12.jpg";

const OurValue = () => {
  return (
    <div className="vision-container">
      <div className="vision-text">
        <h2> OUR VALUES</h2>
        <p>Quality: keep improving the product quality;</p>
        <p>
          Integrity: Compliance with the highest ethical standards, and comply
          with our commitments seriously;
        </p>
        <p>
          Leadership: Improve our team guide and management skills in all
          aspects;
        </p>

        <p>
          Customers'satisfaction: Listening the customers’ requirements, and do
          our job to meet their satisfaction.
        </p>
      </div>
      <div className="value-image">
        <img src={imagevalue} alt="Our Vision" className="value-image" />
        <img
          src={value1}
          alt="Our Vision"
          className="value-image"
          style={{ height: "137px", width: "306px" }}
        />
      </div>
    </div>
  );
};

export default OurValue;
