import React from "react";

const SodiumNitrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p align="justify">
                  Since our inception in the year 2006, we have been affianced
                  in providing<strong> Sodium Nitrate </strong>to our clients.
                  By utilizing quality assured chemical compounds and innovative
                  methodology, this product is processed under the surveillance
                  of ingenious professionals. In order to avoid spoilage and
                  maintain freshness, the offered product is ideal to be used in
                  food industry as preservative in processed meat. Available in
                  moisture proof packaging options, the provided{" "}
                  <strong>Sodium Nitrate</strong> can be purchased from us at
                  rock bottom prices.&nbsp;
                </p>
                <p align="justify">
                  <strong>Key points:</strong>
                </p>
                <ul>
                  {" "}
                  <li>Accurate chemical composition</li>{" "}
                  <li>Standard shelf life</li> <li>Free from impurities</li>{" "}
                  <li>
                    Odorless <br />{" "}
                  </li>{" "}
                </ul>
                <p>
                  {" "}
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                    }}
                  />{" "}
                </p>
                <p lang="en-US" style={{ pageBreakBefore: "always" }}>
                  <strong>Sodium Nitrate </strong>{" "}
                </p>{" "}
                <ul>
                  <li>
                    <strong>C.A.S. No.</strong> : 7631-99-4{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula </strong>: Nano3{" "}
                  </li>
                  <li>
                    <span lang="en-US">
                      <strong>Molecular Weight </strong>
                    </span>
                    <span lang="en-US">: 84.99 </span>{" "}
                  </li>
                </ul>{" "}
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                  }}
                />{" "}
                <center>
                  {" "}
                  <table cellSpacing={0} cellPadding={0} width="100%">
                    {" "}
                    <colgroup>
                      <col width="64*" /> <col width="64*" />{" "}
                      <col width="64*" /> <col width="64*" />{" "}
                    </colgroup>
                    <tbody>
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>SPECIFICATION</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">LR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">AR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            border: "1px solid rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">ACS</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Insoluble Matter</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>pH of a 5% solution at 250C</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">5.0 - 7.5</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">5.0 - 7.5</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">5.5- 8.3</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Chloride (Cl)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Iodate (IO3)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Nitrite (NO2)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Sulphate (SO4)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Heavy Metals (as Pb)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Iron (Fe)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0003%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Phosphate (PO4)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Calcium (Ca)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Arsenic (As)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.00004%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Magnesium (Mg)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.002%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Copper (Cu)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Potassium (K)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Assay</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 98.0%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.0%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                </center>{" "}
                <p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumNitrate;
