import React from "react";

const CopperSulphatePentahydrate = () => {
  return (
    <div>
      <p style={{ marginTop: "50px" }}>
        Leading manufacturer and exporter of Zinc Sulphate Heptahydrate. We are
        producing Zinc Sulphate Heptahydrate (EP/LR Grade), Zinc Sulphate
        Heptahydrate (AR/GR Grade), Zinc Sulphate Heptahydrate (Tech)..
      </p>
      <h2>
        Copper Sulphate (Penta Hydrate) Synonyms : Copper (II) Sulphate, Cupric
        Sulphate
      </h2>
      <h2>Specifications</h2>
      <table>
        <thead>
          <tr>
            <th>Property</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CAS No.</td>
            <td>7758-98-7</td>
          </tr>
          <tr>
            <td>Molecular Formula</td>
            <td>CuSO4</td>
          </tr>
          <tr>
            <td>Molecular Weight</td>
            <td>159.61 g/mol</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>Greenish Gray Powder to Gray White Powder</td>
          </tr>
          <tr>
            <td>Grade</td>
            <td>Technical*</td>
          </tr>
          <tr>
            <td>Assay as Cu</td>
            <td>98% MIN</td>
          </tr>
        </tbody>
      </table>
      <div>
        <h2 style={{ textTransform: "uppercase" }}>
          Copper Sulphate Pentahydrate – LR Grade
        </h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Alkalis Sulphate (SO4)</td>
                      <td>0.3% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – AR Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.8 Minimum</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Total Nitrogen (N)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Magnesium (Mg)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Nickel (Ni)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.03% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – ACS Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0 – 102.0%</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Total Nitrogen (N)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Nickel (Ni)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.02% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – IP Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Identification</td>
              <td>Copper as Cu and Sulphate as SO4 = +VE.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 to 101.0%</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0008% Max.</td>
                    </tr>
                    <tr>
                      <td>Alkaline Earth</td>
                      <td>0.3% Max.</td>
                    </tr>
                    <tr>
                      <td>LOD @ 250°C</td>
                      <td>35.0 – 36.5</td>
                    </tr>
                    <tr>
                      <td>Substance Not Precipitated by Hydrogen Sulphide</td>
                      <td>0.3% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – BP Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 to 101.0%</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>LOD @ 250°C</td>
                      <td>33.0 – 36.5</td>
                    </tr>
                    <tr>
                      <td>Substance Not Precipitated by Hydrogen Sulphide</td>
                      <td>0.3% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – USP (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.5 to 100.5%</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.02% Max.</td>
                    </tr>
                    <tr>
                      <td>Nickel (Ni)</td>
                      <td>0.005% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – Iron Free</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0</td>
                    </tr>
                    <tr>
                      <td>Iron as (Fe)</td>
                      <td>500 PPM to 5 PPM (as per your requirement)</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Copper Sulphate Pentahydrate – Electronic Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Copper (II) Sulphate Pentahydrate; Blue Vitriol; Roman Vitriol;
                Salzburg Vitriol
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4.5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.68</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Blue crystal or crystalline powder, odorless, slowly
                efflorescent.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of aqua solution is clear.</td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>As per your required specification</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>
        We are able to provide you the COPPER SULPHATE PENTAHYDRATE As per your
        Customized Specification
      </h2>
      <h2>Uses</h2>
      <div>
        Laboratory Reagent <br />
        Analytical Reagent <br />
        Pharmaceuticals
        <br />
        Agriculture
        <br />
        Adhesive
        <br />
        Cattle & Poultry Feed Supplements
        <br />
        Electroplating
        <br />
        Electro Refining of Copper
        <br />
        Pigments
        <br />
        Preserving Wood
        <br />
        Pyrotechnic
        <br />
        Petroleum Refining
        <br />
        Mordant in textile dying &Azo Dyes,
        <br />
        Manufacturing of Other Organic & Inorganic based Copper Salts <br />
      </div>
    </div>
  );
};

export default CopperSulphatePentahydrate;
