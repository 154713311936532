import React from "react";

const ZincSulphateHeptahydrate = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: ZINC SULPHATE HEPTAHYDRATE</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 7446-20-0 </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        : ZnSO<sub>4</sub>.7H<sub>2</sub>O
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 287.5</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="57*" />
                  <col width="71*" />
                  <col width="64*" />
                  <col width="64*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>SPECIFICATION</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        <strong>IP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>BP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>USP</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Description</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        Colorless , transparent crystals or white powder
                        crystalline powder ,odorless ,efflorescent.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        White or almost white , crystalline powder or colorless,
                        transparent crystals, efflorescent.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        White or almost white, crystalline powder or colorless,
                        transparent crystals, efflorescent.
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Solubility</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        Very soluble in water and practically insoluble in
                        ethanol (96 % )
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Appearance of solution</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>Clear and colorless</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>5 % solution is clear and colorless.</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Clear and colorless</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Identification</strong>
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>(A) By Color</strong>
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>(B) Reaction</strong>
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>(C) Reaction</strong>
                      </p>
                      <p>
                        <br />
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <br />
                        A: It gives reaction of Sulphates.
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B: It gives reaction of Zinc.
                      </p>
                      <p>
                        <br />
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <br />
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        A: It gives reaction of Sulphates.
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B: It gives reaction of Zinc.
                      </p>
                      <p>C: It complies with the limit test of the Assay.</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <br />
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        A : It gives reaction of Sulphates.
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B : It gives reaction of Zinc.
                      </p>
                      <p>
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>pH (5 % solution )</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>4.4 - 5.6</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>4.4 - 5.6</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Acidity</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Solution is not colored pink by methyl orange TS</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Chloride</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>Not more than 250 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Not more than 300 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Iron</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>Not more than 100 ppm.</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Not more than 100 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Arsenic</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        Not more than 10 ppm.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 14 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Lead</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 20 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Alkalies &amp; alkaline earths</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 0.9 %</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Residual solvents</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Meets the requirement</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Assay</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>99 .0 % to 104.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>99.0 % - 104.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>99.0 % - 108.7 %</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZincSulphateHeptahydrate;
