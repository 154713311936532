import React from "react";

const PotassiumNitrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0in" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="50%"
                      >
                        <p>: POTASSIUM NITRATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 7757-79-1</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          : KNO<sub>3</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 101.1g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p lang="en" style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="85*" />
                    <col width="85*" />
                    <col width="85*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">SPECIFICATION</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">BP</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">USP</span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Description</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white, crystalline powder or
                            colorless crystals.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white, crystalline powder or
                            colorless crystals.
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Solubility</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Freely soluble in water, very soluble in boiling
                            water, practically insoluble in ethanol (96 %)
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <strong>
                            <span lang="en">Identification</span>
                          </strong>
                        </p>
                        <ul>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">
                              It gives reaction of nitrates.
                            </span>
                          </li>
                          <li>
                            <span lang="en">
                              It gives reaction of Potassium
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">
                              It gives reaction of nitrates.
                            </span>
                          </li>
                          <li>
                            <span lang="en">
                              It gives reaction of Potassium
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Acidity or Alkalinity</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            NMT 0.5 ml of 0.01 M HCl or 0.01 M NaOH is required
                            to change the color of the indicator.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Reducible Substances</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            The solution does not become blue within 2 min.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Chlorides</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 300 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sulfates</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 150 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1000 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Limit of Nitrite</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 5 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Heavy metals</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Arsenic</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 3 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Lead</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Ammonium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Calcium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 100 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Iron</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Residual Solvents</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Meets the requirements.</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sodium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1000 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1000 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Loss on Drying</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.5 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Assay</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">99.0 %-101.1 % (on dried basis)</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">99.0 %-100.5 %</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotassiumNitrate;
