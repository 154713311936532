import React, { useEffect } from "react";
import { windowScroll } from "./Footer";

const BackToTop = () => {
  return (
    <div id="back-top">
      <span
        onClick={windowScroll}
        className="fa fa-angle-up fa-fw fa-2x"
        style={{color:"white"}}
      ></span>
    </div>
  );
};

export default BackToTop;
