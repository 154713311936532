import { BrowserRouter } from "react-router-dom";
import MainRouter from "./MainRouter";

function App() {
  return (
      <div id="wrapper">
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </div>
  );
}

export default App;
