import React from "react";
import SubHeader from "../SubHeader";

const DrugIntermediates = () => {
  return (
    <div>
      <SubHeader curruntPath={"DRUG INTERMEDIATES"} />
      <div style={{ padding: "90px" }}>
        <h2>Pharmaceutical Intermediates</h2>
        <p>
          AARTI CHEM PHARMA & AARTI DYE CHEM provides a comprehensive range of
          pharmaceutical intermediates that are integral to the synthesis of
          active pharmaceutical ingredients (APIs). Our intermediates play a
          vital role in the pharmaceutical manufacturing process, ensuring the
          production of high-quality medications.
        </p>
        <p>
          With our expertise in chemical synthesis and process optimization, we
          deliver intermediates that meet stringent quality standards, ensuring
          seamless integration into API production. Our commitment to quality,
          reliability, and innovation makes us a preferred partner for the
          global pharmaceutical industry.
        </p>

        <h3>Our Commitment to Quality and Innovation</h3>
        <ul>
          <li>
            <strong>Advanced Manufacturing</strong>: Utilizing cutting-edge
            technology, we produce pharmaceutical intermediates with precision
            and consistency.
          </li>
          <li>
            <strong>Regulatory Compliance</strong>: Our intermediates comply
            with international regulations and are subject to rigorous quality
            checks at every stage of production.
          </li>
          <li>
            <strong>Custom Synthesis</strong>: We offer custom synthesis
            services, catering to specific client requirements and specialized
            needs in pharmaceutical manufacturing.
          </li>
        </ul>

        <h3>Applications of Our Pharmaceutical Intermediates</h3>
        <p>
          Our intermediates are used in the development of APIs for a wide range
          of therapeutic areas, including:
        </p>
        <ul>
          <li>Anti-inflammatory drugs</li>
          <li>Antibiotics and antifungals</li>
          <li>Cardiovascular treatments</li>
          <li>Cancer therapies</li>
          <li>Central nervous system agents</li>
        </ul>

        <h3>Comprehensive Product List</h3>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Molecular Formula</th>
              <th>CAS No</th>
              <th>Specification</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>β-Nicotinamide mononucleotide (NMN)</td>
              <td>C11H18N2NaO8P</td>
              <td>108347-85-9</td>
              <td>Purity≥99.8%</td>
            </tr>
            <tr>
              <td>β-Nicotinamide mononucleotide reduced (NMNH)</td>
              <td>C11H15ClN2O5</td>
              <td>23111-00-4</td>
              <td>Purity≥99.8%</td>
            </tr>
            <tr>
              <td>Nicotinamide riboside chloride (NR)</td>
              <td>C21H26N7NaO17P3</td>
              <td>1184-16-3</td>
              <td>Purity≥98.0%</td>
            </tr>
            <tr>
              <td>β-Nicotinamide adenine dinucleotide (NAD)</td>
              <td>C21H27N7Na2O14P2</td>
              <td>606-68-8</td>
              <td>Purity≥99.8%</td>
            </tr>
            <tr>
              <td>
                β-Nicotinamide adenine dinucleotide reduced disodium salt (NMDH)
              </td>
              <td>C21H27N7O14P2</td>
              <td>53-84-9</td>
              <td>Purity≥98.0%</td>
            </tr>
            <tr>
              <td>
                β-Nicotinamide adenine dinucleotide phosphate monosodium
                salt(NADP - NA)
              </td>
              <td>C11H15N2O8P</td>
              <td>1094-61-7</td>
              <td>Purity≥99.8%</td>
            </tr>
            <tr>
              <td>
                β-Nicotinamide adenine dinucleotide phosphate disodium salt
                (NADP - NA2)
              </td>
              <td>C21H26N7Na2O17P3</td>
              <td>24292-60-2</td>
              <td>Purity≥98.0%</td>
            </tr>
            <tr>
              <td>β-NADPH, Coenzyme II reduced tetrasodium salt(β-NADPH)</td>
              <td>C21H26N7Na4O17P3</td>
              <td>2646-71-1</td>
              <td>Purity≥99.8%</td>
            </tr>
          </tbody>
        </table>

        <h3>Supplement Ingredients</h3>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Molecular Formula</th>
              <th>CAS No</th>
              <th>Specification</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ubidecarenone (Co Q10)</td>
              <td>C59H90O4</td>
              <td>303-98-0</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td>L-Glutathione Reduced (GSH)</td>

              <td>C20H32N6O12S2</td>
              <td>70-18-8</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td> Pyrroloquinoline quinone (PQQ)</td>

              <td>C14H4N2Na2O8</td>
              <td>122628-50-6</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td>Fisetin</td>

              <td>C15H10O6</td>
              <td>528-48-3</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td>Forskolin</td>

              <td>C22H34O7</td>
              <td>66575-29-9</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td>UrolithinA</td>

              <td>C13H8O4</td>
              <td>1143-70-0</td>
              <td>Purity≥98%</td>
            </tr>
            <tr>
              <td>Spermidine trihydrochloride (Spermidine)</td>

              <td>C7H22Cl3N3</td>
              <td>334-50-9</td>
              <td>Purity≥98%</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>Intermediate</th>
              <th>CAS No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                4-Sulfonamido Phenyl Hydrazine Hydrochloride (Celecoxib
                Intermediate)
              </td>
              <td>17852-52-7</td>
            </tr>
            <tr>
              <td>2-amino-5-methyl thiazole (Use In Meloxicam)</td>
              <td>7305-71-7</td>
            </tr>
            <tr>
              <td>Dibenzothiazepinone (Quetiapine Intermediate)</td>
              <td>3159-07-07</td>
            </tr>
            <tr>
              <td>
                1-[2-(2-Hydroxyethoxy)ethyl]piperazine (Quetiapine Intermediate)
              </td>
              <td>13349-82-1</td>
            </tr>
            <tr>
              <td>trans-4-Aminocyclohexanol (Ambroxol HCL)</td>
              <td>27489-62-9</td>
            </tr>
            <tr>
              <td>Sodium Tetraphenylborate</td>
              <td></td>
            </tr>
            <tr>
              <td>2-Chloromethyl-1,3-dioxolane</td>
              <td>2568-30-1</td>
            </tr>
            <tr>
              <td>Chloroacetaldehyde Dimethylacetal (CADMA)</td>
              <td>97-97-2</td>
            </tr>
            <tr>
              <td>2-chloro-1,4-naphthoquinone (Use In Atovaquone)</td>
              <td>1010-60-2</td>
            </tr>
            <tr>
              <td>Isobutyl Benzene (Ibuprofen Intermediate)</td>
              <td>538-93-2</td>
            </tr>
            <tr>
              <td>4-Chlorobutyryl Chloride (Ketoprofen Intermediate)</td>
              <td>4635-59-0</td>
            </tr>
            <tr>
              <td>3-Bromoanisole (Bromfenac Intermediate)</td>
              <td>2398-37-0</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DrugIntermediates;
