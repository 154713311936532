import React from 'react'

const PotassiumHydrogenPhthalate = () => {
  return (
    <div>
        <div className="other-info-section-main">
  <div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <div align="justify">
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              {" "}
            </font>
          </div>
          <p align="justify" className="MsoNormal">
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              We are engaged as manufacturer and supplier of{" "}
              <strong>Potassium Hydrogen Phthalate</strong>. It is often called
              simply KHP, is an acidic salt compound. Offered{" "}
              <strong>Potassium Hydrogen Phthalate</strong> is processed from
              premium quality of chemical substances and innovative technology
              under the punctilious observation of our highly experienced
              quality controller. Moreover, we offer this product in diverse
              packaging options as per our precious clients requirements.
              Furthermore, we offer this product at pocket friendly prices.
              <strong>
                <span style={{ fontSize: "14pt", lineHeight: "115%" }}>
                  {" "}
                  <br />
                </span>
              </strong>
            </font>
          </p>
          <div align="justify">
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              {" "}
            </font>
          </div>
          <p
            align="justify"
            style={{ marginBottom: "0.0001pt", lineHeight: "normal" }}
            className="MsoNormal"
          >
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              <strong>
                <span style={{ fontSize: "14pt", lineHeight: "115%" }}>
                  Features
                </span>
              </strong>
              <span style={{ fontSize: "10pt" }}>
                <br />
              </span>
            </font>
          </p>
          <div align="justify">
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              {" "}
            </font>
          </div>
          <ul>
            {" "}
            <li>
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                Correct formulation
              </font>
            </li>{" "}
            <li>
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                Fine quality
              </font>
            </li>{" "}
            <li>
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                Longer shelf life
              </font>
            </li>{" "}
            <li>
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                Precise pH value
                <span style={{ fontSize: "10pt", lineHeight: "115%" }} />
              </font>
            </li>{" "}
          </ul>
          <div align="justify">
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              {" "}
            </font>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default PotassiumHydrogenPhthalate