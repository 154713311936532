import React from "react";

const PotassiumPhosphateMonobasic = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.04in",
                      }}
                      width="50%"
                    >
                      <p>: POTASSIUM PHOSPHATE MONOBASIC</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>: 7778-77-0</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        : KH<sub>2</sub>PO<sub>4</sub>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>: 136.09 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="85*" />
                  <col width="85*" />
                  <col width="85*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">SPECIFICATION</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">BP</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">USP</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Appearance</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          White crystalline powder or colorless crystals.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">Colorless &amp; odorless crystals</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Solubility</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          Freely soluble in water, practically insoluble in
                          ethanol.(96 %)
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          Freely soluble in water, practically insoluble in
                          alcohol.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <strong>
                          <span lang="en">Identification</span>
                        </strong>
                      </p>
                      <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                      <ul>
                        <li>
                          <strong>
                            <span lang="en">A) Reaction</span>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            <span lang="en">B) Reaction</span>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            <span lang="en">C)Reaction</span>
                          </strong>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">It gives reaction of Potassium</span>
                        </li>
                        <li>
                          <span lang="en">
                            It gives reaction for Phosphates
                          </span>
                        </li>
                        <li>
                          <span lang="en">Solution s is faintly acid.</span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">It gives reaction of Potassium</span>
                        </li>
                        <li>
                          <span lang="en">
                            It gives reaction for Phosphates
                          </span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                      <p lang="en">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Insoluble Substances</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 0.2 %</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Chloride</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 200 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">pH</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">4.2 - 4.5</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Arsenic</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 2 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 3 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Lead</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 5 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Limit of Fluoride</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 10 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Sulfates</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 300 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Reducing substances</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          The color of the permanganate is not completely
                          discharge.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Iron</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 10 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Sodium</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 0.1 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Heavy Metals</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 20 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Loss on Drying (Temp 125</span>
                          <sup>
                            <span lang="en">o</span>
                          </sup>
                          <span lang="en">C-130</span>
                          <sup>
                            <span lang="en">o</span>
                          </sup>
                          <span lang="en">C)</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 2.0 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 1.0 %</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Assay</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">98.0 % to 100.5 %(on dried basis)</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">98.0 % to 100.5 %</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotassiumPhosphateMonobasic;
