import React from 'react'

const DibasicPotassiumPhoshphate = () => {
  return (
    <div>
        <div className="other-info-section-main">
  <div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <p style={{ marginBottom: "0in" }}>
            <strong>Product Details :</strong>
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="128*" />
              <col width="128*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04in 0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>PRODUCT NAME</p>
                </td>
                <td
                  style={{
                    border: "1px solid rgb(0, 0, 0)",
                    padding: "0.04in"
                  }}
                  width="50%"
                >
                  <p>: DIBASIC POTASSIUM PHOSPHATE</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>C.A.S. No.</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>: 7758-11-4</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>MOLECULAR FORMULA</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>
                    : K<sub>2</sub>HPO<sub>4</sub>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>MOLECULAR WEIGHT</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="50%"
                >
                  <p>: 174.18</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p lang="en" style={{ marginBottom: "0in" }}>
            <br />
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="72*" />
              <col width="99*" />
              <col width="85*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">SPECIFICATION</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <strong>
                      <span lang="en">BP</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    border: "1px solid rgb(0, 0, 0)",
                    padding: "0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <strong>
                      <span lang="en">USP</span>
                    </strong>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Description</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">
                      White or almost white powder or colorless crystals, very
                      hygroscopic.
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">
                      White or almost white powder or colorless crystals, very
                      hygroscopic.
                    </span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Solubility</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">
                      Very soluble in water, very slightly soluble in ethanol
                      (96 per cent).
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Appearance of solution</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">Solution is clear &amp; Colorless</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p style={{ marginBottom: "0in" }}>
                    <strong>
                      <span lang="en">Identification</span>
                    </strong>
                  </p>
                  <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                  <ul>
                    <li>
                      <strong>
                        <span lang="en">(A) ) Reaction</span>
                      </strong>
                    </li>
                    <li>
                      <strong>
                        <span lang="en">(B) Reaction</span>
                      </strong>
                    </li>
                    <li>
                      <strong>
                        <span lang="en">(C) Reaction</span>
                      </strong>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p style={{ marginBottom: "0in" }}>
                    <span lang="en">
                      <br />
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span lang="en">
                        Solution S (see Tests) is slightly alkaline
                      </span>
                    </li>
                    <li>
                      <span lang="en">
                        Solution S gives reaction of phosphate
                      </span>
                    </li>
                    <li>
                      <span lang="en">
                        Solution S gives reaction of potassium
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p style={{ marginBottom: "0in" }}>
                    <span lang="en">
                      <br />
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span lang="en">
                        Solution S gives reaction of phosphate
                      </span>
                    </li>
                    <li>
                      <span lang="en">
                        Solution S gives reaction of potassium
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Reducing substances</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">The solution remains faintly pink.</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Heavy Metals</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">NMT 0.001 %</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Limit of Fluoride</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">NMT 0.001 %</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">pH</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">8.5-9.6</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Monopotassium phosphate</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">This ratio is not greater than 0.025.</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Chlorides</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT200 ppm</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Sulphates</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT 0.1 %</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Arsenic</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT 2 ppm</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Sodium</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT 0.1 %</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">
                      A solution tested on a platinum wire imparts no pronounced
                      yellow color to a nonluminous flame.
                    </span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Limit of Monobasic OR tribasic salt</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">NMT 0.001 %</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Iron</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT10 ppm</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">NMT 30 ppm</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Residual solvents</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">-</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">Meets the requirement</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Loss on Drying(at 125-130ËšC)</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">NMT 2.0 %</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">NMT 1.0 %</span>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="28%"
                >
                  <p>
                    <strong>
                      <span lang="en">Assay (dried substance).</span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0in 0in 0.04in 0.04in"
                  }}
                  width="39%"
                >
                  <p>
                    <span lang="en">98.0 % to 101.0 %</span>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0in 0.04in 0.04in"
                  }}
                  width="33%"
                >
                  <p>
                    <span lang="en">98.0 % to 100.5 %</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginBottom: "0in" }}>
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default DibasicPotassiumPhoshphate