import React from 'react'

const SodiumSulphateAnhydrous = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <strong>Product Details :</strong>
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="128*" />
                <col width="128*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>Product Name</p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="50%"
                  >
                    <p>: SODIUM SULPHATE ANHYDROUS</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>C.A.S. No.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 7757-82-6</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>Molecular Formula</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      : Na<sub>2</sub>SO<sub>4</sub>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>Molecular Weight</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 142.04 g/mol</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <br />
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="85*" />
                <col width="85*" />
                <col width="85*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>SPECIFICATION</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>BP</p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="33%"
                  >
                    <p>USP</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Description</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>White or almost white powder hygroscopic.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>White almost white powder hygroscopic.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Solubility</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>Freely soluble in water.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Appearance of solution</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>2.2 % w/v solution is clear and colorless</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>Identification</strong>
                    </p>
                    <ul>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Reaction</strong>
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Reaction</strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>LOD</strong>
                        </p>
                      </li>
                    </ul>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      A: It gives reaction of sulphates.
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      B: It gives reaction of Sodium.
                    </p>
                    <p>C: It complies with Loss on drying.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <br />
                    </p>
                    <p style={{ marginBottom: "0cm" }}>
                      A: It gives reaction of sulphates.
                    </p>
                    <p>B: It gives reaction of Sodium.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Acidity or alkalinity</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      NMT 0.5 ml of 0.01 M hydrochloric acid or 0.01 M sodium
                      hydroxide is required to change the color of the indicator.
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      NMT 0.5 ml of 0.01 M hydrochloric acid or 0.01 M sodium
                      hydroxide is required to change the color of the Solution.
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Chloride</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 450 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 200 ppm</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Calcium</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 450 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Heavy Metal</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 10 ppm</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Iron</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 90 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Magnesium</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 200 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Loss on drying </strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 0.5 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NMT 0.5 %</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>
                      <strong>Assay ( on dry basis )</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>98.5 % - 101.0 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="33%"
                  >
                    <p>NLT 99.00 %</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default SodiumSulphateAnhydrous