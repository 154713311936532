import React from 'react'

const FerrousSulphateHeptahydrate = () => {
  return (
    <div>
      FerrousSulphateHeptahydrate
    </div>
  )
}

export default FerrousSulphateHeptahydrate
