import React from 'react'

const SodiumAcetateAnhydrous = () => {
  return (
    <div>
        <div className="other-info-section-main">
  <div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <strong>Product Details :</strong>
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="128*" />
              <col width="128*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Product Name</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="50%"
                >
                  <p>: SODIUM ACETATE ANHYDROUS</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>C.A.S. No.</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 127-09-3</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Formula</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    : C<sub>2</sub>H<sub>3</sub>NaO<sub>2</sub>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Weight</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 82.03 g/mol</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <br />
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="77*" />
              <col width="87*" />
              <col width="92*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>SPECIFICATION</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    <strong>BP</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="36%"
                >
                  <p>
                    <strong>USP</strong>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Description</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>Colorless crystals or granules,</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>
                    A white or almost white, crystalline powder or colorless
                    crystals
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Solubility</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    Very soluble in water, sparingly soluble in ethanol (96%).
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <strong>Identification</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>(A) Reaction</strong>
                    </li>
                    <li>
                      <strong>(B) Reaction</strong>
                    </li>
                  </ul>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <ul>
                    <li>A: It gives reaction of Acetates. </li>
                    <li>B : It gives reaction of Sodium</li>
                  </ul>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>pH </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p align="left" lang="en-US">
                    <font face="Calibri, serif">7.5 - 9.2</font>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p align="left" lang="en-US">
                    <font face="Calibri, serif">
                      <strong>Insoluble Matter</strong>
                    </font>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>0.05%</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p align="left" lang="en-US">
                    <font face="Calibri, serif">
                      <strong>Chloride</strong>
                    </font>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>NMT. 350 ppm.</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Sulphates</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>NMT. 50 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Potassium</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>No precipitate is formed</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Aluminum</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p> NMT 0.2 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Heavy Metals</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>NMT.10 ppm.</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Organic Volatile Impurities</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>Meets the requirement</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Residual Solvents</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>Meets the requirement</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Calcium-Magnesium</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p align="left" lang="en-US">
                    <font face="Calibri, serif">
                      No Turbidity is produced within 5 mins.
                    </font>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Loss on drying </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <font face="Calibri, serif">NMT 2.0 %</font>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>NMT 1.0 %</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="30%"
                >
                  <p>
                    <strong>Assay (on dry basis )</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="34%"
                >
                  <p align="left" lang="en-US">
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="36%"
                >
                  <p>99.0 % - 101.0 %</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default SodiumAcetateAnhydrous