import React from "react";

const SodiumOxalate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                  }}
                />{" "}
                <p lang="en-US" style={{ pageBreakBefore: "always" }}>
                  <strong>Sodium Oxalate </strong>{" "}
                </p>{" "}
                <ul>
                  <li>
                    <strong>C.A.S. No</strong>. : 62-76-0{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula :</strong> (COONa)2{" "}
                  </li>
                  <li>
                    <span lang="en-US">
                      <strong>Molecular Weight </strong>
                    </span>
                    <span lang="en-US">: 134.00 </span>{" "}
                  </li>
                </ul>{" "}
                <table cellSpacing={0} cellPadding={2} width={932} height={422}>
                  {" "}
                  <colgroup>
                    <col width={242} /> <col width={130} /> <col width={130} />{" "}
                    <col width={130} />{" "}
                  </colgroup>
                  <tbody>
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={24}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>SPECIFICATION</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">LR</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">AR</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">ACS</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Insoluble Matter</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>pH of a 3% solution at 250C</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">7.0 – 8.7</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">7.5 – 8.5</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Chloride (Cl)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Nitrogen Compounds (N)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.001%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Neutrality</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">Passes test</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Ammonium (NH4)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Sulphate (SO4)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.03%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Heavy Metals (as Pb)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.0005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.002%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Iron (Fe)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.0005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.001%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>
                            Substances darkened by hot sulphuric acid
                          </strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">Passes test</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Copper (Cu)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.0005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Potassium (K)</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.02%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.005%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={25}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Assay</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">NLT 99.5%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">NLT 99.9%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">NLT 99.5%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                    <tr>
                      {" "}
                      <td
                        width={242}
                        height={24}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">
                          <strong>Loss on Drying</strong>
                        </p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">---</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.05cm 0cm 0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.01%</p>{" "}
                      </td>{" "}
                      <td
                        width={130}
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.05cm 0.19cm",
                        }}
                      >
                        {" "}
                        <p lang="en-US">0.01%</p>{" "}
                      </td>{" "}
                    </tr>{" "}
                  </tbody>
                </table>{" "}
                <p>
                  <strong>Features</strong>
                </p>{" "}
                <ul>
                  <li>Long shelf life </li>
                  <li>Quality approved </li>
                  <li>Effectiveness </li>
                  <li>Preciously processed </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumOxalate;
