import React from "react";

const AmmoniumAcetate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <div align="justify">
                  Since our inception in the year 2006, we have successfully
                  been engaged in providing excellent quality{" "}
                  <strong>Ammonium Acetate</strong>. At our well-advanced labs,
                  the offered acetate is processed under suitable conditions
                  with the use of best grade chemicals by our dexterous team of
                  professionals. The provided chemical is effective to be used
                  as an important food additive. Also, it is used with acetic
                  acid for the preparation of buffer solution. Additionally, our
                  clients can purchase this <strong>Ammonium Acetate</strong> at
                  reasonable prices from us.
                  <br /> <br />
                  <strong>Features:</strong>
                  <br />{" "}
                  <ul>
                    {" "}
                    <li>Easy to store</li> <li>Accurate composition</li>{" "}
                    <li>Longer shelf life</li>
                  </ul>
                  <p>
                    <span lang="en-US">
                      <strong>Ammonium Acetate </strong>
                      <br />
                    </span>{" "}
                  </p>{" "}
                  <ul>
                    <li>C.A.S. No. : 631-61-8</li>
                    <li>MOLECULAR FORMULA : CH3COONH4 </li>
                    <li>
                      <span lang="en-US">MOLECULAR WEIGHT : 77.08 </span>{" "}
                    </li>
                  </ul>{" "}
                  <center>
                    {" "}
                    <table cellSpacing={0} cellPadding={0} width="100%">
                      {" "}
                      <colgroup>
                        <col width="64*" /> <col width="64*" />{" "}
                        <col width="64*" /> <col width="64*" />{" "}
                      </colgroup>
                      <tbody>
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={28}
                            style={{
                              borderWidth: "1px medium 1px 1px",
                              borderStyle: "solid none solid solid",
                              borderColor:
                                "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>SPECIFICATION</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "1px medium 1px 1px",
                              borderStyle: "solid none solid solid",
                              borderColor:
                                "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">LR</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "1px medium 1px 1px",
                              borderStyle: "solid none solid solid",
                              borderColor:
                                "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">AR</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              border: "1px solid rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">ACS</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Insoluble Matter</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.005%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>pH of a 5% solution at 250C</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">6.0 -7.5 </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">6.5 7.5</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">6.7 7.3</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Chloride (Cl)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.002%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Residue After Ignition</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.01%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Sulphate (SO4)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.01%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.002%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.001%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Heavy Metals (as Pb)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Iron (Fe)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Phosphate (PO4)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Calcium (Ca)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0005%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Copper (Cu)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Magnesium (Mg)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.0001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Potassium (K)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.002%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={29}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Sodium (Na)</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">0.001%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">---</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          {" "}
                          <td
                            width="25%"
                            height={28}
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">
                              <strong>Assay</strong>
                            </p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">NLT 96.0%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium medium 1px 1px",
                              borderStyle: "none none solid solid",
                              borderColor:
                                "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">NLT 98.0%</p>{" "}
                          </td>{" "}
                          <td
                            width="25%"
                            style={{
                              borderWidth: "medium 1px 1px",
                              borderStyle: "none solid solid",
                              borderColor:
                                "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                              padding: "0cm",
                            }}
                          >
                            {" "}
                            <p lang="en-US">NLT 97.0%</p>{" "}
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </center>{" "}
                  <ul> </ul>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmmoniumAcetate;
