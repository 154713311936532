import React from "react";

const CitricAcidAnhydrous = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>Product Name</p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>CITRIC ACID ANHYDROUS</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>C.A.S. No.</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>77-92-9 </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>Molecular Formula</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          &nbsp;C<sub>6</sub>H<sub>8</sub>O<sub>7</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>Molecular Weight</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>&nbsp;192.13</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <br />
                </p>
                <table cellPadding={2} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                    <col width="64*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>IP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>BP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <strong>USP</strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Description</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Colorless crystals or white powder, slightly
                          hygroscopic in moist dry air.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          White or almost white, crystalline powder, colorless
                          crystals or granules
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>White Powder</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Solubility</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Very soluble in water, freely soluble in ethanol (96
                          %).
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Very soluble in water, freely soluble in ethanol (96
                          %).
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Clarity of Solution</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The Test solution shows the same clarity as that of
                          water.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          Appearance of solution (color){" "}
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          20 % w/v. solution is clear and not more intensely
                          colored than reference solution YS7,BYS7 OR GYS7.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          20 % w/v solution is clear and color less or not more
                          intensely colored than reference solution Y7, BY7 and
                          GY7.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The test solution is not more intensely colored than
                          standard solution A,B, or C, or Water.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>Identification </p>
                        <p style={{ marginBottom: "0cm" }}>(A) Reaction</p>
                        <p style={{ marginBottom: "0cm" }}>
                          (B)) By IR Absorption
                        </p>
                        <p style={{ marginBottom: "0cm" }}>(C) Reaction </p>
                        <p style={{ marginBottom: "0cm" }}>(D) Reaction</p>
                        <p>(E) Test of water</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              A 10 % w/v solution is strongly acidic.
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reactions of citrates
                            </p>
                          </li>
                        </ol>
                        <p style={{ marginBottom: "0cm" }}>
                          <br />
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              10 % w/v solution is strongly acidic
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Must comply with the Citric acid anhydrous CRS.
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              A red color develops
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              White precipitate is produced
                            </p>
                          </li>
                          <li>
                            <p>Complies with the test of water.</p>
                          </li>
                        </ol>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          <br />
                        </p>
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              By IR Spectrum
                            </p>
                          </li>
                        </ol>
                        <p>
                          <br />
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Arsenic</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 1ppm.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Bacterial end toxins</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The level of bacterial endotoxins is such that the
                          requirement in the relevant dosage form monograph(S)in
                          which citric acid monohydrate is used can be met.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Sterility</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Meets the requirements for sterility.</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Barium</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Any opalescence in the solution is not more intense
                          than that in a mixture of 5 ml of solution and 5 ml of
                          distilled water.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Calcium </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 200 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Heavy metals </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 10 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 10 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Iron </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 50 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Readily carbonisable substance</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Any color produced is not more than intense than that
                          of the a mixture of 1.0 ml of CCS and 9.0 ml of FCS.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The solution is not more intensely colored than a
                          mixture of 1 ml of red primary solution and 9 ml of
                          yellow primary solution.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The color of acid is not darker than that of a similar
                          volume of matching fluid K
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Sulphates </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 150 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 150 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 150 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Oxalic acid </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          Any pink color is produced is not more instance than
                          that produced by carrying out the test using 0.2 mg of
                          oxalic acid dissolve in 4 ml of water. .
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 360 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 360 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Limit of Aluminium</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.2 PPM</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          The fluorescencence of the test solution does not
                          exceed that of the standard solution (0.2 g/g)
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Residual Solvents </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Meets the requirements</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Organic Volatile impurities</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Meets the requirements</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Chlorides</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 50 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>
                          <br />
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Water by KF</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 1.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 1.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 1.0 %</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Sulphated ash </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.1 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.1 %</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>NMT 0.1 % (residue on ignition)</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>Assay </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>99.0 % to 101.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>99.5 % - 100.5 %</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.04cm 0.04cm",
                        }}
                        width="25%"
                      >
                        <p>99.5 %- 100.5 %</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitricAcidAnhydrous;
