import React from "react";

const PotassiumAcetate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0in" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="50%"
                      >
                        <p>: POTASSIUM ACETATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 127-08-2</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          : C<sub>2</sub>H<sub>3</sub>KO<sub>2</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 98.14 g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p lang="en" style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="85*" />
                    <col width="85*" />
                    <col width="85*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">SPECIFICATION</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">BP</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">USP</span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Description</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white, crystalline powder or
                            colorless cryastals, deliquescent.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white, crystalline powder or
                            colorless cryastals, deliquescent.
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Solubility</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Very soluble in water, freely soluble in ethanol (96
                            %)
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <strong>
                            <span lang="en">Identification</span>
                          </strong>
                        </p>
                        <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                        <ul>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p lang="en" style={{ marginBottom: "0in" }}>
                          <br />
                        </p>
                        <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                        <ul>
                          <li>
                            <span lang="en">Reaction of acetates.</span>
                          </li>
                          <li>
                            <span lang="en">Reaction of potassium</span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p lang="en" style={{ marginBottom: "0in" }}>
                          <br />
                        </p>
                        <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                        <ul>
                          <li>
                            <span lang="en">Reaction of acetates.</span>
                          </li>
                          <li>
                            <span lang="en">Reaction of potassium</span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Appearance of solution</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Clear &amp; Colorless</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">pH</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">7.5-9.0</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">7.5-8.5</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Heavy metals</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Limit of Sodium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.03 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Reducing substances</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">The solution remains pink.</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Chloride</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 200 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sulfates</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 200 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Aluminum</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Iron</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sodium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 0.5 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Loss on drying</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 3.0 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1.0 %</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Residual Solvents</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Meets the requirements</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Assay</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium medium 1px 1px",
                          borderStyle: "none none solid solid",
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">99.0 %-101.0 %</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "medium 1px 1px",
                          borderStyle: "none solid solid",
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">99.0%-100.5 %</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotassiumAcetate;
