import React, { useState } from "react";
import SubHeader from "../SubHeader";
import Cobalt from "../Labgradechemical/Cobalt";
import Copper from "../Labgradechemical/Copper";
import CopperSulphatePentahydrate from "../Labgradechemical/CopperSulphatePentahydrate";
import AmmoniumFerrousSulphateHexahydrate from "../Labgradechemical/AmmoniumFerrousSulphateHexahydrate";
import FerrousSulphateHeptahydrate from "../Labgradechemical/FerrousSulphateHeptahydrate";
import MagnesiumSulphateHeptahydrate from "../Labgradechemical/MagnesiumSulphateHeptahydrate";
import Nickle from "../Labgradechemical/Nickle";
import Tin from "../Labgradechemical/Tin";
import ZincSulphateHepthydrate from "../Labgradechemical/ZincSulphateHepthydrate";
import Sidebar from "../Sidebar";

const Labgradechemical = () => {
  const [curruntPath, setCurruntPath] = useState("Zinc Sulphate Hepthydrate");

  const menuItems = [
    { name: "Zinc Sulphate Hepthydrate" },
    { name: "Copper Sulphate Pentahydrate" },
    { name: "Ferrous Sulphate Heptahydrate" },
    { name: "Magnesium Sulphate Heptahydrate" },
    { name: "Ammonium Ferrous Sulphate Hexahydrate" },
    { name: "Copper Sulphate Pentahydrate LR Grade" },
    { name: "Tin(II) Chloride Dihydrate" },
    { name: "Nickel Sulphate Hexahydrate" },
    { name: "Cobalt Carbonate" },
  ];

  console.log("curruntPath", curruntPath);

  return (
    <div>
      {/* <SubHeader curruntPath={"Lab grade chemical"} /> */}
      <>
        <div className="rightsidebar">
          <div className="labora_page_loader" style={{ display: "none" }} />
          <div id="wrapper">
            <SubHeader curruntPath={curruntPath} />

            <div id="main">
              <div id="primary" className="pagemid">
                <div className="inner">
                  <main className="content-area">
                    {curruntPath ===
                      "Ammonium Ferrous Sulphate Hexahydrate" && (
                      <AmmoniumFerrousSulphateHexahydrate />
                    )}
                    {curruntPath === "Cobalt" && <Cobalt />}
                    {curruntPath === "Copperr" && <Copper />}

                    {curruntPath === "Copper Sulphate Pentahydrate" && (
                      <CopperSulphatePentahydrate />
                    )}
                    {curruntPath === "Ferrous Sulphate Heptahydrate" && (
                      <FerrousSulphateHeptahydrate />
                    )}
                    {curruntPath === "Magnesium Sulphate Heptahydrate" && (
                      <MagnesiumSulphateHeptahydrate />
                    )}
                    {curruntPath === "Nickel Sulphate Hexahydrate" && (
                      <Nickle />
                    )}
                    {curruntPath === "Tin(II) Chloride Dihydrate" && <Tin />}
                    {curruntPath === "Zinc Sulphate Hepthydrate" && (
                      <ZincSulphateHepthydrate />
                    )}
                    {curruntPath ===
                      "Copper Sulphate Pentahydrate LR Grade" && <Copper />}

                    {curruntPath === "Cobalt Carbonate" && <Cobalt />}
                  </main>
                  <Sidebar
                    setCurruntPath={setCurruntPath}
                    menuItems={menuItems}
                    initName={"Zinc Sulphate Hepthydrate"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Labgradechemical;
