import React from "react";

const OurPrincipal = () => {
  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Company Overview
        </h2>
        <p>
          <strong style={{ color: "#990b6e" }}>Mission Statement:</strong> To
          improve health and well-being through innovative pharmaceuticals.
        </p>
        <p>
          <strong style={{ color: "#990b6e" }}>Vision and Values:</strong>{" "}
          Integrity, Quality, Innovation, and Collaboration.
        </p>
        <p>
          <strong style={{ color: "#990b6e" }}>Brief History:</strong>{" "}
          Established in 2015, we have achieved numerous milestones in the
          healthcare sector.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Products and Services
        </h2>
        <ul
          style={{
            listStyleType: "square",
            paddingLeft: "20px",
            color: "#34495e",
          }}
        >
          <li>Prescription Drugs</li>
          <li>Over-the-Counter Products</li>
        </ul>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We focus on high-quality pharmaceuticals with detailed descriptions
          available on our website.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Research and Development
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We are engaged in multiple ongoing projects and collaborations to
          advance healthcare.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Regulatory Compliance
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We adhere to strict safety and quality standards, holding numerous
          certifications.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Patient Resources
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We provide comprehensive information and support programs for
          patients.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Healthcare Professional Resources
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          Guidelines and resources for prescribers and healthcare providers are
          available.
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Contact Information
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          For inquiries, please reach out through our{" "}
          <a href="contact-us">(+91)96870 52936</a>
        </p>
      </section>

      <section
        style={{
          marginBottom: "30px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "6px",
          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2
          style={{
            color: "#990b6e",
            borderBottom: "2px solid #990b6e",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          Corporate Social Responsibility (CSR)
        </h2>
        <p style={{ color: "#34495e", lineHeight: "1.6" }}>
          We are dedicated to community initiatives and sustainability efforts.
        </p>
      </section>
    </div>
  );
};

export default OurPrincipal;
