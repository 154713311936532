import React from 'react'

const AmmoniumFerrousSulphateHexahydrate = () => {
  return (
    <div>
      AmmoniumFerrousSulphateHexahydrate
    </div>
  )
}

export default AmmoniumFerrousSulphateHexahydrate
