import React from "react";

const OurPartner = () => {
  return (
    <>
      <div id="main">
        <div id="primary" className="pagemid">
          <div className="inner">
            <main className="content-area">
              <div className="entry-content-wrapper clearfix">
                <div className="at-partner style1">
                  <div className="at-partner-image">
                    <img
                      src="assets/images/crescento-logo.png"
                      width={350}
                      height={200}
                      alt="crescento-logo"
                      title="crescento-logo"
                    />
                  </div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Crescento Labs</a>
                    </h4>
                    <h6 className="sub-title">
                      Science Laboratory
                      <br />
                      Birmingham, AL
                    </h6>
                    <div className="desc">
                      <p>
                        Crescento Labs is committed to scientific excellence,
                        providing top-notch research and development services.
                        Their state-of-the-art facility in Birmingham ensures
                        quality and innovation in every project.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image">
                    <img
                      src="assets/images/finserv-logo.png"
                      width={350}
                      height={200}
                      alt="finserv-logo"
                      title="finserv-logo"
                    />
                  </div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Finserv Research</a>
                    </h4>
                    <h6 className="sub-title">
                      Technology Incubator
                      <br />
                      Garden City, NY
                    </h6>
                    <div className="desc">
                      <p>
                        Finserv Research specializes in nurturing innovative
                        technologies. Based in Garden City, they support
                        startups and established firms in achieving their
                        business goals.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image">
                    <img
                      src="assets/images/storeup-logo.png"
                      width={350}
                      height={200}
                      alt="storeup-logo"
                      title="storeup-logo"
                    />
                  </div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Storeup Labs</a>
                    </h4>
                    <h6 className="sub-title">
                      Technology Company
                      <br />
                      Denver, CO
                    </h6>
                    <div className="desc">
                      <p>
                        Storeup Labs is at the forefront of technology
                        innovation, providing solutions that enhance user
                        experience and streamline operations for businesses.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>

                <div className="at-partner style1">
                  <div className="at-partner-image">
                    <img
                      src="assets/images/keorra-logo.png"
                      width={350}
                      height={200}
                      alt="keorra-logo"
                      title="keorra-logo"
                    />
                  </div>
                  <div className="at-partner-content">
                    <h4>
                      <a href="#">Keorra Labs</a>
                    </h4>
                    <h6 className="sub-title">
                      Psychologist Laboratory
                      <br />
                      San Diego, CA
                    </h6>
                    <div className="desc">
                      <p>
                        Keorra Labs focuses on mental health research and
                        psychological services, providing valuable insights and
                        support for individuals and communities.
                      </p>
                    </div>
                    <a className="more-link" target="_self" href="#"></a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurPartner;
