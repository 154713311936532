const ZincSulphateHeptahydrate = () => {
  return (
    <div className="container">
      <header>
        <p>Leading manufacturer and exporter of Zinc Sulphate Heptahydrate.</p>
      </header>
      <section className="product-overview">
        <h2>Product Overview</h2>
        <p>
          We are producing Zinc Sulphate Heptahydrate (EP/LR Grade), Zinc
          Sulphate Heptahydrate (AR/GR Grade), Zinc Sulphate Heptahydrate
          (Tech).
        </p>
      </section>

      <section className="specifications">
        <h2>Specifications</h2>
        <table>
          <thead>
            <tr>
              <td>Synonyms</td>
              <td>White Vitriol,Zinc Sulfate Heptahydrate Crystals</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Appearance</td>
              <td>White Crystalline Powder</td>
            </tr>
            <tr>
              <td>Formula</td>
              <td>ZnSO4.7H2O</td>
            </tr>

            <tr>
              <td>CAS No.</td>
              <td>7446-20-0</td>
            </tr>
            <tr>
              <td>Content</td>
              <td>21% Zn (Agri Grade) & 21.5% Zn (Iron Free Grade)</td>
            </tr>
            <tr>
              <td>Uses</td>
              <td>Agriculture, Fertilizers, Dyes, etc.</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="grades">
        <h2>Grades</h2>
        <ul>
          <h3>Zinc Sulphate Heptahydrate (LR - Grade)</h3>
          <thead>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Synonym</td>
              <td>White Vitriol; Zinc Vitriol</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>ZnSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>287.56</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7446-02-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent, Astringent,
                Insoluble in Alcohol.
              </td>
            </tr>
            <tr>
              <td>Assay</td>
              <td>99.0% Minimum</td>
            </tr>
            <tr>
              <td>pH of 5% Solution</td>
              <td>4.0 to 6.0</td>
            </tr>
            <tr>
              <td>Chloride (Cl)</td>
              <td>0.01% Max</td>
            </tr>
            <tr>
              <td>Iron (Fe)</td>
              <td>0.005% Max</td>
            </tr>
            <tr>
              <td>Lead (Pb)</td>
              <td>0.002% Max</td>
            </tr>
            <tr>
              <td>Potassium (K)</td>
              <td>0.01% Max</td>
            </tr>
            <tr>
              <td>Sodium (Na)</td>
              <td>0.05% Max</td>
            </tr>
          </tbody>

          <li>
            <h3>Zinc Sulphate Heptahydrate (AR - Grade)</h3>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Synonym</td>
                <td>White Vitriol; Zinc Vitriol</td>
              </tr>
              <tr>
                <td>Molecular Formula</td>
                <td>ZnSO4.7H2O</td>
              </tr>
              <tr>
                <td>Molecular Weight</td>
                <td>287.56</td>
              </tr>
              <tr>
                <td>CAS Number</td>
                <td>7446-02-0</td>
              </tr>
            </tbody>
            <p>
              Description: White Crystalline Powder, Odorless, Efflorescent,
              Astringent, Insoluble in Alcohol.
            </p>
            <thead>
              <tr>
                <th>Property</th>
                <th>Specification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assay</td>
                <td>99.5% min.</td>
              </tr>
              <tr>
                <td>Insoluble matter</td>
                <td>0.01% Max.</td>
              </tr>
              <tr>
                <td>pH of 5% solution</td>
                <td>4.4-6.0</td>
              </tr>
              <tr>
                <td>Chloride (Cl)</td>
                <td>0.0005% Max.</td>
              </tr>
              <tr>
                <td>Iron (Fe)</td>
                <td>0.0005% Max.</td>
              </tr>
              <tr>
                <td>Cadmium (Cd)</td>
                <td>0.0005% Max.</td>
              </tr>
              <tr>
                <td>Copper (Cu)</td>
                <td>0.0005% Max.</td>
              </tr>
              <tr>
                <td>Manganese (Mn)</td>
                <td>0.0003% Max.</td>
              </tr>
              <tr>
                <td>Arsenic (As)</td>
                <td>0.0001% Max.</td>
              </tr>
              <tr>
                <td>Magnesium (Mg)</td>
                <td>0.005% Max.</td>
              </tr>
              <tr>
                <td>Nitrate (NO3)</td>
                <td>0.002% Max.</td>
              </tr>
              <tr>
                <td>Lead (Pb)</td>
                <td>0.001% Max.</td>
              </tr>
              <tr>
                <td>Calcium (Ca)</td>
                <td>0.001% Max.</td>
              </tr>
              <tr>
                <td>Total Nitrogen (N)</td>
                <td>0.001% Max.</td>
              </tr>
              <tr>
                <td>Ammonia (NH4)</td>
                <td>0.001% Max.</td>
              </tr>
              <tr>
                <td>Sodium (Na)</td>
                <td>0.05% Max.</td>
              </tr>
              <tr>
                <td>Potassium (K)</td>
                <td>0.01% Max.</td>
              </tr>
            </tbody>
          </li>
          <li>
            <h3>Product : ZINC SULPHATE HEPTAHYDRATE – IRON FREE</h3>

            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Molecular Formula</td>
                <td>ZnSO4.7H2O</td>
              </tr>
              <tr>
                <td>Molecular Weight</td>
                <td>287.56</td>
              </tr>
              <tr>
                <td>CAS Number</td>
                <td>7446-02-0</td>
              </tr>
            </tbody>
            <p>
              Description: Colorless transparent Crystal or White Crystalline
              Powder, Odorless, Efflorescent.
            </p>
            <thead>
              <tr>
                <th>Property</th>
                <th>Specification</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assay</td>
                <td>99.0% Minimum</td>
              </tr>
              <tr>
                <td>Acidity</td>
                <td>4.0 to 6.0</td>
              </tr>
              <tr>
                <td>Iron as (Fe)</td>
                <td>
                  100 PPM / 20 PPM / 10 PPM / 5 PPM (IRON as per your
                  requirement)
                </td>
              </tr>
            </tbody>
          </li>
        </ul>
      </section>
      <section className="uses">
        <h2>Uses</h2>
        <ul>
          <li>Laboratory Reagent</li>
          <li>Analytical Reagent</li>
          <li>Pharmaceuticals</li>
          <li>Rayon & Paper</li>
          <li>Pigments</li>
          <li>Electro Refining of Zinc</li>
          <li>Lithopone</li>
          <li>Agriculture</li>
          <li>Preserving Wood & Silk</li>
          <li>Electroplating</li>
          <li>Water Treatment</li>
          <li>Clarifying Glue</li>
          <li>Mordant in Calico Printing</li>
          <li>Cattle & Poultry Feed Supplements</li>
          <li>Manufacturing of Other Organic & Inorganic based Zinc Salts</li>
        </ul>
      </section>
    </div>
  );
};

export default ZincSulphateHeptahydrate;
