import React, { useEffect } from "react";

const BackToTop = () => {
    
  useEffect(() => {
    window.jQuery("#back-top").hide();

    // fade in #back-top
    window.jQuery(function () {
      window.jQuery(window).scroll(function () {
        if (window.jQuery(this).scrollTop() > 100) {
          window.jQuery("#back-top").fadeIn();
        } else {
          window.jQuery("#back-top").fadeOut();
        }
      });

      // scroll body to 0px on click
      window.jQuery("#back-top a").click(function () {
        window.jQuery("body,html").animate(
          {
            scrollTop: 0,
          },
          800
        );

        return false;
      });
    });
  }, []);

  return (
    <div id="back-top">
      <a href="#header" title="Scroll Top">
        <span className="fa fa-angle-up fa-fw fa-2x"></span>
      </a>
    </div>
  );
};

export default BackToTop;
