import React from 'react'

const SodiumBicarbonateAnhydrous = () => {
  return (
    <div>
        <div className="other-info-section-main">
  <div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <strong>Product Details :</strong>
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="128*" />
              <col width="128*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Product Name</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="50%"
                >
                  <p>: SODIUM BICARBONATE ANHYDROUS</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>C.A.S. No.</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 144-55-8</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Formula</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    : NaHCO<sub>3</sub>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Weight</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 84.01 g/mol</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <br />
          </p>
          <table cellPadding={2} cellSpacing={0} width="100%">
            <colgroup>
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>SPECIFICATION</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>IP</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.04cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>BP</strong>
                  </p>
                </td>
                <td
                  style={{
                    border: "1px solid rgb(0, 0, 0)",
                    padding: "0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>USP</strong>
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Description</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    A White crystalline powder. Or small, Opaque monoclinic
                    crystals. It gradually forms sodium carbonate on heating in
                    the dry state or in solution.
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>White or almost white crystalline powder.</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>White or almost white crystalline powder.</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Solubility</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    Soluble in water, practically insoluble in ethanol (96 %).
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Appearance of solution</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>Clear and colorless.</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>Clear and colorless.</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <strong>Identification</strong>
                  </p>
                  <ol type="A">
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>By color</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Reaction </strong>
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Reaction</strong>
                      </p>
                    </li>
                  </ol>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    A: The solution becomes red.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B : The test gives the reaction of bicarbonates
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    C: Test A gives reaction of Sodium salt.
                  </p>
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    A: The solution becomes red.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B: The test gives the reaction of Carbonates &amp;
                    bicarbonates.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    C : Test A gives reaction of Sodium
                  </p>
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                    A: The test gives the reaction of bicarbonates.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B: Test A gives reaction of Sodium
                  </p>
                  <p>
                    <br />
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Loss on Drying</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 0.25 %</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Copper</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>NMT 1 ppm</p>
                  <p>(for hemodialysis)</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Insoluble substances</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>Solution is complete &amp; Clear</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Carbonates(pH)</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 8.6</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 8.6</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>NMT 0.23 %</p>
                  <p>(for hemodialysis)</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Limit of sulphur compounds</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 150 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Aluminium</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>NMT 2 ppm</p>
                  <p>(for hemodialysis)</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Arsenic</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 2 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 2 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 2 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Normal carbonate</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    The solution does not assume more than a faint pink color
                    immediately.
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Heavy metals</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 5 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <ol type="A">
                    <li>
                      <br />
                    </li>
                  </ol>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 5 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <strong>Calcium &amp; Magnesium </strong>
                  </p>
                  <p>
                    <strong>(USP)</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>2.0 % w/v solution when boiled for 5 min.is clear.</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT100 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>Ca:NMT 100 ppm</p>
                  <p style={{ marginBottom: "0cm" }}>Mg:NMT 40 ppm</p>
                  <p>(for hemodialysis)</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Iron</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>NMT 5 ppm</p>
                  <p>(for hemodialysis)</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Chlorides</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 200 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT150 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT150 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Ammonium</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>No blue color develop</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Sulphates</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT150 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>NMT150 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Organic Volatile Impurities</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>Meets the requirements</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Limit of organic</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    NMT 100 ppm (for hemodialysis)
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Assay</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>99.0 % - 101.0 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>99.0 % - 101.0 %</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.04cm 0.04cm"
                  }}
                  width="25%"
                >
                  <p>99.0 % - 100.5 %</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default SodiumBicarbonateAnhydrous