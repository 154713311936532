import React from 'react'

const SodiumPhosphateDibasicAnhydrous = () => {
  return (
    <div><div className="container">
    <div className="other-info-section" id="productInfo">
      <div className="product-description-section-main" id="productDescription">
        <div className="product-description-section">
          <h2>Product Description</h2>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <strong>Product Details :</strong>
          </p>
          <table cellPadding={4} cellSpacing={0} width="100%">
            <colgroup>
              <col width="128*" />
              <col width="128*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.1cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Product Name</strong>
                  </p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                  width="50%"
                >
                  <p>: SODIUM PHOSPHATE MONOBASIC MONOHYDRATE</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>C.A.S. No.</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 10049-21-5 </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Formula</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    : NaH<sub>2</sub>PO<sub>4</sub>.H<sub>2</sub>O
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>
                    <strong>Molecular Weight</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.1cm 0.1cm"
                  }}
                  width="50%"
                >
                  <p>: 138.g/mol</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
            <br />
          </p>
          <table cellPadding={2} cellSpacing={0} width="100%">
            <colgroup>
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
              <col width="64*" />
            </colgroup>
            <tbody>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.05cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>SPECIFICATION</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.05cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>IP</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "solid none solid solid",
                    borderWidth: "1px medium 1px 1px",
                    padding: "0.05cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>BP</p>
                </td>
                <td
                  style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.05cm" }}
                  width="25%"
                >
                  <p>USP</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Description</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>A white powder or colorless crystals.</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>A white powder or colorless crystals.</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>A white powder or colorless crystals.</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Solubility</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    Very soluble in water and slightly soluble in ethanol (96 %).
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <strong>Identification</strong>
                  </p>
                  <ul>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Reaction</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Reaction</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Reaction</strong>
                      </p>
                    </li>
                    <li>
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>LOD </strong>
                      </p>
                    </li>
                  </ul>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    A: It gives reaction of phosphates.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B: It gives reaction of sodium salts.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br /> A : 10 % solution is slightly acidic
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B: It gives reaction of Phosphates.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    C: It gives reaction of Sodium Salts.
                  </p>
                  <p>D : Complies with the test for loss on drying</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    A: It gives reaction of phosphates.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    B: It gives reaction of sodium salts.
                  </p>
                  <p style={{ marginBottom: "0cm" }}>
                    <br />
                  </p>
                  <p>
                    <br />
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Clarity &amp; Color of solution</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>Clear &amp; Colorless</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>pH</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>4.1 - 4.5</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>4.2 - 4.5</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>4.1 - 4.5</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Insoluble Substances</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 0.2 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 0.2 %</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Chloride</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 140 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 200 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 140 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Sulphate</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 1500 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 300 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 1500 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Al , Ca &amp; related -element</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    Not became turbid when rendered slightly alkaline to litmus
                    paper with 6 M ammonium hydroxide.
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    Not became turbid when rendered slightly alkaline to litmus
                    paper
                  </p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Arsenic</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 8 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 2 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 8 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Heavy metals</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT 20 ppm</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Water by KF</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>10.0%-15.0 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>10.0%-15.0 %</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Organic volatile impurities</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>Meets the requirement</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Residual Solvents</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <br />
                  </p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>Meets the requirement</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Iron</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>NMT.10 ppm</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Reducing substance</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>The solution retains a slight red color.</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>
                      LOD ( at 130<sup>o</sup>C )
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>11.5%-14.5%</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>-</p>
                </td>
              </tr>
              <tr valign="top">
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>
                    <strong>Assay (on dry basis)</strong>
                  </p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>98.0 % - 103.0 %</p>
                </td>
                <td
                  style={{
                    borderColor:
                      "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none none solid solid",
                    borderWidth: "medium medium 1px 1px",
                    padding: "0cm 0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>98.0 % - 100.5%</p>
                </td>
                <td
                  style={{
                    borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                    borderStyle: "none solid solid",
                    borderWidth: "medium 1px 1px",
                    padding: "0cm 0.05cm 0.05cm"
                  }}
                  width="25%"
                >
                  <p>98.0%-103.0%</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default SodiumPhosphateDibasicAnhydrous