import React, { useState } from "react";
import SubHeader from "../SubHeader";
import Cobalt from "../Labgradechemical/Cobalt";
import Copper from "../Labgradechemical/Copper";
import CopperSulphatePentahydrate from "../Labgradechemical/CopperSulphatePentahydrate";
import AmmoniumFerrousSulphateHexahydrate from "../Labgradechemical/AmmoniumFerrousSulphateHexahydrate";
import FerrousSulphateHeptahydrate from "../Labgradechemical/FerrousSulphateHeptahydrate";
import MagnesiumSulphateHeptahydrate from "../Labgradechemical/MagnesiumSulphateHeptahydrate";
import Nickle from "../Labgradechemical/Nickle";
import Tin from "../Labgradechemical/Tin";
import ZincSulphateHepthydrate from "../Labgradechemical/ZincSulphateHepthydrate";
import Sidebar from "../Sidebar";
import SodiumPhosphateMonobasicAnhydrous from "../Labgradechemical/SodiumPhosphateMonobasicAnhydrous";
import CitricAcidAnhydrous from "../Labgradechemical/CitricAcidAnhydrous";
import SodiumAcetateTrihydrate from "../Labgradechemical/SodiumPhosphateMonobasicAnhydrous";
import { Saccharin } from "../Labgradechemical/Saccharin";
import FerrousSulfateHeptahydrate from "../Labgradechemical/FerrousSulfateHeptahydrate";
import SodiumCarbonateAnhydrous from "../Labgradechemical/SodiumCarbonateAnhydrous";
import CalciumSaccharate from "../Labgradechemical/CalciumSaccharate";
import DisodiumEdetate from "../Labgradechemical/DisodiumEdetate";
import AmmoniumPhosphate from "../Labgradechemical/AmmoniumPhosphate";
import PotassiumPhosphateMonobasic from "../Labgradechemical/PotassiumPhosphateMonobasic";
import SodiumChloride from "../Labgradechemical/SodiumChloride";
import SodiumPhosphateMonobasicMonohydrate from "../Labgradechemical/SodiumPhosphateMonobasicMonohydrate";
import ZincSulphateHeptahydrate from "../Labgradechemical/ZincSulphateHeptahydrate";
import MagnesiumSulphateHeptahydrate2 from "../Labgradechemical/MagnesiumSulphateHeptahydrate2";
import SodiumPhosphateDibasicAnhydrous from "../Labgradechemical/SodiumPhosphateDibasicAnhydrous";
import SodiumSaccharin from "../Labgradechemical/SodiumSaccharin";
import SodiumCitrateTribasicDihydrate from "../Labgradechemical/SodiumCitrateTribasicDihydrate";
import MagnesiumChlorideHexahydrate from "../Labgradechemical/MagnesiumChlorideHexahydrate";
import SodiumAcetateAnhydrous from "../Labgradechemical/SodiumAcetateAnhydrous";
import SodiumPhosphateDibasicDodecahydrate from "../Labgradechemical/SodiumPhosphateDibasicDodecahydrate";
import BenzoicAcid from "../Labgradechemical/BenzoicAcid";
import SodiumBenzoate from "../Labgradechemical/SodiumBenzoate";
import CitricAcidMonohydrate from "../Labgradechemical/CitricAcidMonohydrate";
import SorbicAcid from "../Labgradechemical/SorbicAcid";
import SodiumSulphateAnhydrous from "../Labgradechemical/SodiumSulphateAnhydrous";
import SodiumPhosphateMonobasicDihydrate2 from "../Labgradechemical/SodiumPhosphateMonobasicDihydrate2";
import SodiumPhosphateDibasicDihydrate from "../Labgradechemical/SodiumPhosphateDibasicDihydrate";
import SodiumNitrite from "../Labgradechemical/SodiumNitrite";
import SodiumBicarbonateAnhydrous from "../Labgradechemical/SodiumBicarbonateAnhydrous";
import PotassiumCarbonateAnhydrous from "../Labgradechemical/PotassiumCarbonateAnhydrous";
import PotassiumAcetate from "../Labgradechemical/PotassiumAcetate";
import MonoPotassiumPhoshphate from "../Labgradechemical/MonoPotassiumPhoshphate";
import DriedFerrousSulfate from "../Labgradechemical/DriedFerrousSulfate";
import DibasicPotassiumPhoshphate from "../Labgradechemical/DibasicPotassiumPhoshphate";
import CalciumCitrate from "../Labgradechemical/CalciumCitrate";
import Borax from "../Labgradechemical/Borax";
import AmmoniumAcetate from "../Labgradechemical/AmmoniumAcetate";
import DiPotassiumPhosphate from "../Labgradechemical/DiPotassiumPhosphate";
import SodiumPhosphateTribasicDodecahydrate from "../Labgradechemical/SodiumPhosphateTribasicDodecahydrate";
// import SodiumPhosphateDibasicHeptahydrate from "../Labgradechemical/SodiumPhosphateDibasicHeptahydrate";
import SodiumPhosphateDibasicHetahydrate from "../Labgradechemical/SodiumPhosphateDibasicHetahydrate";
import PotassiumChloride from "../Labgradechemical/PotassiumChloride";
import PotassiumSulphate from "../Labgradechemical/PotassiumSulphate";
import AmmoniumPhosphateMonobasic from "../Labgradechemical/AmmoniumPhosphateMonobasic";
import AmmoniumPhosphateDibasic from "../Labgradechemical/AmmoniumPhosphateDibasic";
import PotassiumHydrogenPhthalate from "../Labgradechemical/PotassiumHydrogenPhthalate";
import Urea from "../Labgradechemical/Urea";
import PotassiumPhosphateDibasicAnhydrous from "../Labgradechemical/PotassiumPhosphateDibasicAnhydrous";
import SodiumNitrate from "../Labgradechemical/SodiumNitrate";
import SodiumPhosphate from "../Labgradechemical/SodiumPhosphate";
import SodiumOxalate from "../Labgradechemical/SodiumOxalate";
import BoricAcid from "../Labgradechemical/BoricAcid";
import CalciumChlorideDihydrate from "../Labgradechemical/CalciumChlorideDihydrate";
import PotassiumNitrate from "../Labgradechemical/PotassiumNitrate";

const Labgradechemical = () => {
  const [curruntPath, setCurruntPath] = useState("Zinc Sulphate Hepthydrate");

  const menuItems = [
    { name: "Zinc Sulphate Hepthydrate" },
    { name: "Copper Sulphate Pentahydrate" },

    { name: "Ferrous Sulphate Heptahydrate" },
    { name: "Magnesium Sulphate Heptahydrate" },
    { name: "Ammonium Ferrous Sulphate Hexahydrate" },
    { name: "Copper Sulphate Pentahydrate LR Grade" },
    { name: "Tin(II) Chloride Dihydrate" },
    { name: "Nickel Sulphate Hexahydrate" },
    { name: "Cobalt Carbonate" },
    { name: "Sodium Phosphate Monobasic Anhydrous" },
    { name: "Citric Acid Anhydrous" },
    { name: "Sodium Acetate Trihydrate" },
    { name: "Saccharin" },
    { name: "Sodium Nitrate" },
    { name: "Ferrous Sulfate Heptahydrate" },
    { name: "Potassium Phosphate Monobasic" },
    { name: "Ammonium Phosphate" },
    { name: "Disodium Edetate" },
    { name: "Calcium Saccharate" },
    { name: "Sodium Carbonate Anhydrous" },
    { name: "Sodium Chloride" },
    { name: "Sodium Phosphate Monobasic Monohydrate" },
    { name: "Zinc Sulphate Heptahydrate" },
    { name: "Magnesium Sulphate Heptahydrate 2" },
    { name: "Sodium Phosphate Dibasic Anhydrous" },
    { name: "Sodium Saccharin" },
    { name: "Sodium Citrate Tribasic Dihydrate" },
    { name: "Magnesium Chloride Hexahydrate" },
    { name: "Sodium Acetate Anhydrous" },
    { name: "Sodium Phosphate Monobasic Dihydrate 2" },
    { name: "Sodium Phosphate Dibasic Dodecahydrate" },
    { name: "Benzoic Acid" },
    { name: "Sodium Benzoate" },
    { name: "Citric Acid Monohydrate" },
    { name: "Sorbic Acid" },
    { name: "Sodium Sulphate Anhydrous" },
    { name: "Sodium Phosphate Dibasic Heptahydrate" },
    { name: "Sodium Phosphate Dibasic Dihydrate" },
    { name: "Sodium Nitrate" },
    { name: "Sodium Bi Carbonate Anhydrous" },
    { name: "Boric Acid" },
    { name: "Calcium Chloride Dihydrate" },
    { name: "Potassium Nitrate" },
    { name: "Potassium Carbonate Anhydrous" },
    { name: "Potassium Acetate" },
    { name: "Mono Potassium Phosphate" },
    { name: "Dried Ferrous Sulfate" },
    { name: "Dibasic Potassium Phoshphate" },
    { name: "Calcium Citrate" },
    { name: "Borax" },
    { name: "Ammonium Acetate" },
    // { name: "Di Potassium Phosphate" },
    { name: "Sodium Phosphate Tribasic Dodecahydrate" },
    { name: "Sodium Phosphate Dibasic Hetahydrate" },
    { name: "Potassium Chloride" },
    { name: "Ammonium Phosphate Monobasic" },
    { name: "Ammonium Phosphate Dibasic" },
    { name: "Potassium Hydrogen Phthalate" },
    { name: "Potassium Sulphate" },
    { name: "Urea" },
    { name: "Potassium Phosphate Dibasic Anhydrous" },
    { name: "Sodium Nitrite" },
    { name: "Sodium Phosphate" },
    { name: "Sodium Oxalate" },
  ];

  console.log("curruntPath", curruntPath);

  const sidebarStyles = {
    overflowX: "auto",
    overflowY: "scroll",
    height: "800px",
    width: "450px",
  };

  return (
    <div>
      <div className="rightsidebar">
        <div className="labora_page_loader" style={{ display: "none" }} />
        <div id="wrapper">
          <SubHeader curruntPath={curruntPath} />

          <div id="main">
            <div id="primary" className="pagemid">
              <div className="inner">
                <main className="content-area">
                  {curruntPath === "Ammonium Ferrous Sulphate Hexahydrate" && (
                    <AmmoniumFerrousSulphateHexahydrate />
                  )}
                  {curruntPath === "Cobalt" && <Cobalt />}
                  {curruntPath === "Copperr" && <Copper />}
                  {curruntPath === "Copper Sulphate Pentahydrate" && (
                    <CopperSulphatePentahydrate />
                  )}
                  {curruntPath === "Ferrous Sulphate Heptahydrate" && (
                    <FerrousSulphateHeptahydrate />
                  )}
                  {curruntPath === "Magnesium Sulphate Heptahydrate" && (
                    <MagnesiumSulphateHeptahydrate />
                  )}
                  {curruntPath === "Nickel Sulphate Hexahydrate" && <Nickle />}
                  {curruntPath === "Tin(II) Chloride Dihydrate" && <Tin />}
                  {curruntPath === "Zinc Sulphate Hepthydrate" && (
                    <ZincSulphateHepthydrate />
                  )}
                  {curruntPath === "Copper Sulphate Pentahydrate LR Grade" && (
                    <Copper />
                  )}
                  {curruntPath === "Cobalt Carbonate" && <Cobalt />}
                  {curruntPath === "Citric Acid Anhydrous" && (
                    <CitricAcidAnhydrous />
                  )}
                  {curruntPath === "Sodium Acetate Trihydrate" && (
                    <SodiumAcetateTrihydrate />
                  )}
                  {curruntPath === "Sodium Phosphate Monobasic Anhydrous" && (
                    <SodiumPhosphateMonobasicAnhydrous />
                  )}
                  {curruntPath === "Saccharin" && <Saccharin />}
                  {curruntPath === "Ferrous Sulfate Heptahydrate" && (
                    <FerrousSulfateHeptahydrate />
                  )}
                  {curruntPath === "Sodium Carbonate Anhydrous" && (
                    <SodiumCarbonateAnhydrous />
                  )}
                  {curruntPath === "Calcium Saccharate" && (
                    <CalciumSaccharate />
                  )}
                  {curruntPath === "Disodium Edetate" && <DisodiumEdetate />}
                  {curruntPath === "Ammonium Phosphate" && (
                    <AmmoniumPhosphate />
                  )}
                  {curruntPath === "Potassium Phosphate Monobasic" && (
                    <PotassiumPhosphateMonobasic />
                  )}
                  {curruntPath === "Sodium Chloride" && <SodiumChloride />}
                  {curruntPath === "Sodium Phosphate Monobasic Monohydrate" && (
                    <SodiumPhosphateMonobasicMonohydrate />
                  )}
                  {curruntPath === "Zinc Sulphate Heptahydrate" && (
                    <ZincSulphateHeptahydrate />
                  )}
                  {curruntPath === "Magnesium Sulphate Heptahydrate 2" && (
                    <MagnesiumSulphateHeptahydrate2 />
                  )}
                  {curruntPath === "Sodium Phosphate Dibasic Anhydrous" && (
                    <SodiumPhosphateDibasicAnhydrous />
                  )}
                  {curruntPath === "Sodium Saccharin" && <SodiumSaccharin />}
                  {curruntPath === "Sodium Citrate Tribasic Dihydrate" && (
                    <SodiumCitrateTribasicDihydrate />
                  )}
                  {curruntPath === "Magnesium Chloride Hexahydrate" && (
                    <MagnesiumChlorideHexahydrate />
                  )}
                  {curruntPath === "Sodium Acetate Anhydrous" && (
                    <SodiumAcetateAnhydrous />
                  )}
                  {curruntPath === "Sodium Nitrate" && <SodiumNitrate />}
                  {curruntPath === "Sodium Phosphate Dibasic Dodecahydrate" && (
                    <SodiumPhosphateDibasicDodecahydrate />
                  )}
                  {curruntPath === "Benzoic Acid" && <BenzoicAcid />}
                  {curruntPath === "Sodium Benzoate" && <SodiumBenzoate />}
                  {curruntPath === "Citric Acid Monohydrate" && (
                    <CitricAcidMonohydrate />
                  )}
                  {curruntPath === "Sorbic Acid" && <SorbicAcid />}
                  {curruntPath === "Sodium Sulphate Anhydrous" && (
                    <SodiumSulphateAnhydrous />
                  )}
                  {curruntPath === "Sodium Phosphate Monobasic Dihydrate 2" && (
                    <SodiumPhosphateMonobasicDihydrate2 />
                  )}{" "}
                  {curruntPath === "Sodium Phosphate Dibasic Dihydrate" && (
                    <SodiumPhosphateDibasicDihydrate />
                  )}
                  {curruntPath === "Sodium Nitrite" && <SodiumNitrite />}
                  {curruntPath === "Sodium Bi Carbonate Anhydrous" && (
                    <SodiumBicarbonateAnhydrous />
                  )}
                  {curruntPath === "Boric Acid" && <BoricAcid />}
                  {curruntPath === "Calcium Chloride Dihydrate" && (
                    <CalciumChlorideDihydrate />
                  )}
                  {curruntPath === "Potassium Nitrate" && <PotassiumNitrate />}
                  {curruntPath === "Potassium Carbonate Anhydrous" && (
                    <PotassiumCarbonateAnhydrous />
                  )}
                  {curruntPath === "Potassium Acetate" && <PotassiumAcetate />}
                  {curruntPath === "Mono Potassium Phosphate" && (
                    <MonoPotassiumPhoshphate />
                  )}
                  {curruntPath === "Dried Ferrous Sulfate" && (
                    <DriedFerrousSulfate />
                  )}
                  {curruntPath === "Dibasic Potassium Phoshphate" && (
                    <DibasicPotassiumPhoshphate />
                  )}
                  {curruntPath === "Calcium Citrate" && <CalciumCitrate />}
                  {curruntPath === "Borax" && <Borax />}
                  {curruntPath === "Ammonium Acetate" && <AmmoniumAcetate />}
                  {curruntPath === "Di Potassium Phosphate" && (
                    <DiPotassiumPhosphate />
                  )}
                  {curruntPath ===
                    "Sodium Phosphate Tribasic Dodecahydrate" && (
                    <SodiumPhosphateTribasicDodecahydrate />
                  )}
                  {curruntPath === "Sodium Phosphate Dibasic Heptahydrate" && (
                    <SodiumPhosphateTribasicDodecahydrate />
                  )}
                  {curruntPath === "Sodium Phosphate Dibasic Hetahydrate" && (
                    <SodiumPhosphateDibasicHetahydrate />
                  )}
                  {curruntPath === "Potassium Chloride" && (
                    <PotassiumChloride />
                  )}
                  {curruntPath === "Potassium Sulphate" && (
                    <PotassiumSulphate />
                  )}
                  {curruntPath === "Ammonium Phosphate Monobasic" && (
                    <AmmoniumPhosphateMonobasic />
                  )}
                  {curruntPath === "Ammonium Phosphate Dibasic" && (
                    <AmmoniumPhosphateDibasic />
                  )}
                  {curruntPath === "Potassium Hydrogen Phthalate" && (
                    <PotassiumHydrogenPhthalate />
                  )}{" "}
                  {curruntPath === "Urea" && <Urea />}{" "}
                  {curruntPath === "Potassium Phosphate Dibasic Anhydrous" && (
                    <PotassiumPhosphateDibasicAnhydrous />
                  )}{" "}
                  {curruntPath === "Sodium Nitrite" && <SodiumNitrite />}{" "}
                  {curruntPath === "Sodium Phosphate" && <SodiumPhosphate />}{" "}
                  {curruntPath === "Sodium Oxalate" && <SodiumOxalate />}
                </main>
                <Sidebar
                  styeside={sidebarStyles} // Pass the class name as a string
                  setCurruntPath={setCurruntPath}
                  menuItems={menuItems}
                  initName={"Zinc Sulphate Hepthydrate"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Labgradechemical;
