import React from "react";

const MagnesiumSulphateHeptahydrate = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        MagnesiumSulphateHeptahydrate
      </h2>
      <p>
        Magnesium sulfate is made of natural magnetite and sulfuric acid. It is
        white or transparent odorless crystal, bitter. It is easy to dissolve in
        water but a .little in alcohol. In dry air, it is easy to turn to
        powder. It loses crystal water and turns to magnesium sulfate anhydrous
        while heating. It can be used for agriculture, industry, food, feed,
        medicine etc. Application of Magnesium Sulfate Heptahydrate
      </p>
      <p>
        Magnesium sulfate is made of natural magnetite and sulfuric acid. It is
        white or transparent odorless crystal, bitter. It is easy to dissolve in
        water but a .little in alcohol. In dry air, it is easy to turn to
        powder. It loses crystal water and turns to magnesium sulfate anhydrous
        while heating. It can be used for agriculture, industry, food, feed,
        medicine etc. Application of Magnesium Sulfate Heptahydrate.
      </p>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – LR Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% solution</td>
                      <td>5.0 to 8.5</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.001% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – EP Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% solution</td>
                      <td>5.0 to 8.5</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – AR Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.5% Min</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>5.0-8.2 at 25°C</td>
                    </tr>
                    <tr>
                      <td>Acidity</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Alkalinity</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Phosphate (PO4)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Nitrate (NO3)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Ammonia (NH4)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.02% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – ACS Grade</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.0 – 102.0%</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>5.0-8.2 at 25°C</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Nitrate (NO3)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Ammonia (NH4)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Strontium (Sr)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.02% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – IP (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 to 100.5%</td>
                    </tr>
                    <tr>
                      <td>Acidity & Alkalinity</td>
                      <td>0.2 mL 0.01M HCl/NaOH</td>
                    </tr>
                    <tr>
                      <td>Arsenic</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.03% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.02% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – BP</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 to 100.5%</td>
                    </tr>
                    <tr>
                      <td>Acidity & Alkalinity</td>
                      <td>0.2 mL 0.01M HCl/NaOH</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.03% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.02% Max.</td>
                    </tr>
                    <tr>
                      <td>Loss on Drying</td>
                      <td>48 – 52%</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – USP</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 to 100.5%</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0002% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.014% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.02% Max.</td>
                    </tr>
                    <tr>
                      <td>Selenium (Se)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Loss on Ignition</td>
                      <td>40 – 52%</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – FCC (Confirming)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay on dry basis</td>
                      <td>99.5% Min.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0003% Max.</td>
                    </tr>
                    <tr>
                      <td>Heavy Metal (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.014% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.02% Max.</td>
                    </tr>
                    <tr>
                      <td>Selenium (Se)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Loss on Ignition</td>
                      <td>40 – 52%</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h2>MAGNESIUM SULPHATE HEPTAHYDRATE – IRON FREE</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>Epsom Salt, Bitter Salt</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>MgSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>246.48</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>10034-99-8</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent. Bitter,
                Cooling Taste.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>Very soluble in water, practically insoluble in ethanol.</td>
            </tr>
            <tr>
              <td>Clarity</td>
              <td>10% of Aqua solution is clear and colorless.</td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% solution</td>
                      <td>5.0-8.5 at 25⁰C</td>
                    </tr>
                    <tr>
                      <td>Iron as (Fe)</td>
                      <td>1 PPM Maximum</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MagnesiumSulphateHeptahydrate;
