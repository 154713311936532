import React from "react";

const SodiumPhosphateMonobasicMonohydrate = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: DI SODIUM PHOSPHATE ANHYDROUS</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 7558-79-4</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        : Na<sub>2</sub>HPO<sub>4</sub>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 142.00 g/mo</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="85*" />
                  <col width="85*" />
                  <col width="85*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>SPECIFICATION</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>BP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>USP</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Description</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>White or almost white hygroscopic powder</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>White or almost white hygroscopic powder</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Identification</strong>
                      </p>
                      <ol type="A">
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Reaction</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Reaction</strong>
                          </p>
                        </li>
                      </ol>
                      <p>
                        <br />
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p
                        align="left"
                        lang="en-US"
                        style={{ marginBottom: "0cm" }}
                      >
                        <font face="Calibri, serif">
                          A : Solution S is slightly Alkaline
                        </font>
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B : Passes LOD Test (1.0%)
                      </p>
                      <p
                        align="left"
                        lang="en-US"
                        style={{ marginBottom: "0cm" }}
                      >
                        <font face="Calibri, serif">
                          C: Gives reaction of phosphates.
                        </font>
                      </p>
                      <p>D: Gives reaction of sodium salts</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p
                        align="left"
                        lang="en-US"
                        style={{ marginBottom: "0cm" }}
                      >
                        <font face="Calibri, serif">
                          A: Gives reaction of phosphates.
                        </font>
                      </p>
                      <p>B: Gives reaction of sodium.</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Appearance of Solution </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">
                          Solution is clear and colorless
                        </font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Solubility</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        {" "}
                        Soluble in water, practically in soluble in ethanol
                        (96%)
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Insoluble Substances</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">-</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 0.4%</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Arsenic</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 2 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 16 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Chloride</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 200 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT. 600 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Sulphates</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">NMT 500 ppm</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 2000 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Iron</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">NMT 20 ppm</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Reducing substances</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">
                          The red color is not completely discharge
                        </font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Residual solvents</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">-</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>Meets the requirement</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Mono Sodium Phosphate</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">NMT.2.5%</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Sodium dihydrogen phosphate</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">-</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Heavy Metal</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">-</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 20 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>Loss on Drying (at 1300C)</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">NMT 1.0%</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>NMT 5.0%</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Assay </strong>
                        <strong>
                          <strong>(On dry basis)</strong>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">98.0% - 101.0 %</font>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="33%"
                    >
                      <p>98.0% - 100.5%</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumPhosphateMonobasicMonohydrate;
