import React from "react";

const ContactPage = () => {
  const phone = '9726538395';
  const mobileURL = `whatsapp://send?text=Thank you for your connection with Aarti Chempharma! If there are anything specific you like to know or discuss, please let me know.&phone=${phone}`;
  const webURL = `https://wa.me/${phone}?text=Thank you for your connection with Aarti Chempharma! If there are anything specific you like to know or discuss, please let me know.`;

  const handleWhatsup = () => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.open(mobileURL, '_blank');
    } else {
      window.open(webURL, '_blank');
    }
  };

  return (
    <>
      <div id="main">
        <div id="primary" className="pagemid">
          <div className="inner">
            <main className="content-area">
              <div className="entry-content-wrapper">
                <div className="ct-section1">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7594.447529128005!2d72.88913924689207!3d21.220556843415743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e85c7e5b7c4db%3A0x7b5eafc7dc05450d!2sPunagam%20Surat%2C%20Gujarat%20435010%2C%20India!5e0!3m2!1sen!2sus!4v1694871895284!5m2!1sen!2sus"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Location"
                  ></iframe>
                </div>

                <div className="ct-section2" style={{ padding: "80px 0" }}>
                  <div className="one_third">
                    <div className="aligncenter">
                      <i
                        style={{ fontSize: "44px", color: "#990b6e" }}
                        className="fa fa-envelope-o"
                      ></i>
                      <div
                        className="demo_space"
                        style={{ height: "20px" }}
                      ></div>
                      <h4>Have any questions?</h4>
                      <hr className="ct-hr" />
                      <h5>info@aartichempharma.com</h5>
                    </div>
                  </div>

                  <div className="one_third">
                    <div className="aligncenter" style={{ cursor: 'pointer'}}  onClick={handleWhatsup}>
                      <i
                        style={{ fontSize: "44px", color: "#990b6e" }}
                        className="fa fa-whatsapp"
                      ></i>
                      <div
                        className="demo_space"
                        style={{ height: "20px" }}
                      ></div>
                      <h4>WhatsApp</h4>
                      <hr className="ct-hr" />
                      <h5>(+91)96870 52936</h5>
                    </div>
                  </div>

                  <div className="one_third last">
                    <div className="aligncenter">
                      <i
                        style={{ fontSize: "44px", color: "#990b6e" }}
                        className="fa fa-phone"
                      ></i>
                      <div
                        className="demo_space"
                        style={{ height: "20px" }}
                      ></div>
                      <h4>Contact Us</h4>
                      <hr className="ct-hr" />
                      <h5>(+91)96870 52936</h5>
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>

                <div className="ct-section3">
                  <div className="two_third">
                    <div className="section-inner clearfix">
                      <div className="fancyheading left">
                        <h3 className="fancy-title">
                          <span style={{ color: "#444444" }}>
                            <strong>GET IN TOUCH</strong>
                          </span>
                        </h3>
                      </div>

                      <div id="form" data-ng-controller="formController">
                        <form name="c_form" novalidate>
                          <p>
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              data-ng-class="{ 'has-error': c_form.name.$invalid && !c_form.name.$pristine }"
                              data-ng-model="name"
                              required
                            />
                          </p>
                          <p>
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              data-ng-class="{ 'has-error': c_form.email.$invalid && !c_form.email.$pristine }"
                              data-ng-model="email"
                              required
                            />
                          </p>
                          <p>
                            <input
                              type="text"
                              name="subject"
                              size="40"
                              placeholder="Subject"
                              data-ng-class="{ 'has-error': c_form.subject.$invalid && !c_form.subject.$pristine }"
                              data-ng-model="subject"
                              required
                            />
                          </p>
                          <p>
                            <textarea
                              name="message"
                              cols="40"
                              rows="10"
                              placeholder="Tell us about your Query"
                              data-ng-model="message"
                            ></textarea>
                          </p>
                          <p>
                            <input
                              type="submit"
                              data-ng-click="submitForm()"
                              data-ng-disabled="c_form.$invalid"
                              value="SEND MESSAGE"
                            />
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="one_third last ct-office">
                    <div className="fancyheading left">
                      <h3 className="fancy-title">
                        <span style={{ color: "#444444" }}>
                          <strong>OUR HEAD OFFICE</strong>
                        </span>
                      </h3>
                    </div>
                    <h4 style={{ fontWeight: 900 }}>
                      Arti - Business, Laboratory & Pharmaceutical
                    </h4>
                    <p>Arti is a creative & powerful medical and laboratory</p>
                    <div className="contactinfo-wrap">
                      <p>
                        <i className="fa fa-map-marker fa-fw"></i> Reg.off.B-56
                        Kiran Park Society, Nalanda School-2, Punagam
                        Surat-395010 , India
                      </p>
                      <p>
                        <i className="fa fa-fax fa-fw"></i>{" "}
                        info@aartichempharma.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
