import React from "react";

const AmmoniumFerrousSulphateHexahydrate = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Ammonium Ferrous Sulphate Hexahydrate
      </h2>
      <p>
        We have established ourselves as a renowned manufacturer and supplier of
        Aluminum Sulphate. Available in powder/lump/slab forms, this chemical is
        best suitable as flocculating agent in purification of drinking water.
        Furthermore, the chemical is used in treatment of waste water, dyeing
        and printing cloth, reducing pH of garden soil..
      </p>
      <div>
        <h2>AMMONIUM FERROUS SULPHATE HEXAHYDRATE</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Ferrous Ammonium Sulphate Hexahydrate; Ammonium Iron(II)
                Sulphate Hexahydrate; Mohr’s Salt
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>Fe(NH4)2(SO4)2.6H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>392.14</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7783-85-9</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Pale blue-green Crystal or Crystalline Powder, Odorless,
                Efflorescent, Slowly Oxidized in Air.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.5% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0 to 5.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.002% Max</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.002% Max</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.002% Max</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>AMMONIUM FERROUS SULPHATE HEXAHYDRATE – Grade AR/GR</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Ferrous Ammonium Sulphate Hexahydrate; Ammonium Iron(II)
                Sulphate Hexahydrate; Mohr’s Salt
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>Fe(NH4)2(SO4)2.6H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>392.14</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7783-85-9</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Pale blue-green Crystal or Crystalline Powder, Odorless,
                Efflorescent, Slowly Oxidized in Air.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0 – 101.5 % Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% aq solution</td>
                      <td>3.0 - 5.0</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.003% Max</td>
                    </tr>
                    <tr>
                      <td>Chloride</td>
                      <td>0.001% Max</td>
                    </tr>
                    <tr>
                      <td>Potassium</td>
                      <td>0.002% Max</td>
                    </tr>
                    <tr>
                      <td>Copper</td>
                      <td>0.003% Max</td>
                    </tr>
                    <tr>
                      <td>Phosphate</td>
                      <td>0.003% Max</td>
                    </tr>
                    <tr>
                      <td>Zinc</td>
                      <td>0.003% Max</td>
                    </tr>
                    <tr>
                      <td>Calcium</td>
                      <td>0.005% Max</td>
                    </tr>
                    <tr>
                      <td>Manganese</td>
                      <td>0.01% Max</td>
                    </tr>
                    <tr>
                      <td>Sodium</td>
                      <td>0.02% Max</td>
                    </tr>
                    <tr>
                      <td>Ferric Ion (Fe3+)</td>
                      <td>0.01% Max</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2>AMMONIUM FERROUS SULPHATE HEXAHYDRATE – Grade ACS</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>
                Ferrous Ammonium Sulphate Hexahydrate; Ammonium Iron(II)
                Sulphate Hexahydrate; Mohr’s Salt
              </td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>Fe(NH4)2(SO4)2.6H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>392.14</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7783-85-9</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Pale blue-green Crystal or Crystalline Powder, Odorless,
                Efflorescent, Slowly Oxidized in Air.
              </td>
            </tr>
            <tr>
              <td>Solubility</td>
              <td>
                10% Solution in Dilute Sulphuric Acid is Clear blush Green
                Solution.
              </td>
            </tr>
            <tr>
              <th>Specification</th>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>98.5 – 101.5%</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>3.0 to 5.0</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Phosphate (PO4)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper (Cu)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Zinc (Zn)</td>
                      <td>0.003% Max.</td>
                    </tr>
                    <tr>
                      <td>Ferric Ion (Fe3+)</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>Substance Not Precipitated by Ammonium Hydroxide</td>
                      <td>0.05% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AmmoniumFerrousSulphateHexahydrate;
