import React from 'react'

const SodiumPhosphate = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <div align="justify">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                {" "}
              </font>
            </div>{" "}
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  ' <!-- @page { margin: 2cm } P { margin-bottom: 0.21cm; direction: ltr; color: #000000; widows: 2; orphans: 2 } P.western { font-family: "Times New Roman", serif; font-size: 12pt; so-language: en-US } P.cjk { font-family: "Times New Roman", serif; font-size: 12pt } P.ctl { font-family: "Times New Roman", serif; font-size: 12pt; so-language: ar-SA } --> '
              }}
            />{" "}
            <p
              lang="en-US"
              style={{ marginBottom: "0cm", lineHeight: "150%" }}
              className="western"
            >
              {" "}
              <font face="Arial, sans-serif">
                PRODUCT NAME : <strong>SODIUM PHOSPHATE DIBASIC ANHYDROUS</strong>{" "}
              </font>{" "}
            </p>{" "}
            <p
              lang="en-US"
              style={{ marginBottom: "0cm", lineHeight: "150%" }}
              className="western"
            >
              {" "}
              <font face="Arial, sans-serif">C.A.S. No. : 7558-79-4 </font>{" "}
            </p>{" "}
            <p
              lang="en-US"
              style={{ marginBottom: "0cm", lineHeight: "150%" }}
              className="western"
            >
              {" "}
              <font face="Arial, sans-serif">
                MOLECULAR FORMULA : Na<sub>2</sub>HPO<sub>4</sub>{" "}
              </font>
            </p>{" "}
            <p
              lang="en-US"
              style={{ marginBottom: "0cm", lineHeight: "150%" }}
              className="western"
            >
              {" "}
              <font face="Arial, sans-serif">
                MOLECULAR WEIGHT : 141.96{" "}
              </font>{" "}
            </p>{" "}
            <p lang="en-US" style={{ marginBottom: "0cm" }} className="western">
              <br />{" "}
            </p>{" "}
            <table width={651} cellSpacing={0} cellPadding={7}>
              {" "}
              <colgroup>
                <col width={232} /> <col width={120} /> <col width={120} />{" "}
                <col width={120} />{" "}
              </colgroup>
              <tbody>
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={14}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">
                        <strong>SPECIFICATION</strong>
                      </font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">
                        <strong>LR</strong>
                      </font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">
                        <strong>AR</strong>
                      </font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">
                        <strong>ACS</strong>
                      </font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Water Insoluble Matter</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.01%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.01%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">pH of a 0.1M solution</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">9.1 - 9.3</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">8.7 - 9.3</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Chloride (Cl)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.01%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.002%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.002%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Nitrogen Compounds (N)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.002%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">---</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">
                        Sulphate (SO<sub>4</sub>)
                      </font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.01%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.005%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.005%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Heavy Metals (as Pb)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.001%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.001%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Iron (Fe)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.001%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.002%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Copper (Cu)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.001%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">---</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Potassium (K)</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.01%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">---</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={15}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Assay</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">NLT 99.0%</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">NLT 99.0%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
                <tr>
                  {" "}
                  <td
                    width={232}
                    height={14}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" className="western">
                      <font face="Arial, sans-serif">Loss on Drying</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <br />{" "}
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      borderWidth: "1px medium 1px 1px",
                      borderStyle: "solid none solid solid",
                      padding: "0cm 0cm 0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">---</font>
                    </p>{" "}
                  </td>{" "}
                  <td
                    width={120}
                    style={{
                      border: "1px solid rgb(0, 0, 0)",
                      padding: "0cm 0.19cm"
                    }}
                  >
                    {" "}
                    <p lang="en-US" align="CENTER" className="western">
                      <font face="Arial, sans-serif">0.2%</font>
                    </p>{" "}
                  </td>{" "}
                </tr>{" "}
              </tbody>
            </table>{" "}
            <p lang="en-US" style={{ marginBottom: "0cm" }} className="western">
              <br />{" "}
            </p>
            &nbsp;{" "}
            <font size={2} face="verdana,arial,helvetica,sans-serif">
              <strong>
                <span style={{ fontSize: "14pt", lineHeight: "115%" }}>
                  <br />
                </span>
              </strong>
            </font>
            <div align="justify">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                {" "}
              </font>
            </div>
            <p align="justify" className="MsoNormal">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                <strong>
                  <span style={{ fontSize: "14pt", lineHeight: "115%" }}>
                    Features
                  </span>
                </strong>
              </font>
            </p>
            <div align="justify">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                {" "}
              </font>
            </div>
            <p align="justify" className="MsoNormal">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                <strong>
                  <span style={{ fontSize: "14pt", lineHeight: "115%" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </strong>
              </font>
            </p>
            <div align="justify">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                {" "}
              </font>
            </div>
            <ul>
              {" "}
              <li>
                <font size={2} face="verdana,arial,helvetica,sans-serif">
                  Optimum solubility
                </font>
              </li>{" "}
              <li>
                <font size={2} face="verdana,arial,helvetica,sans-serif">
                  Precise pH value
                </font>
              </li>{" "}
              <li>
                <font size={2} face="verdana,arial,helvetica,sans-serif">
                  Accurate composition
                </font>
              </li>{" "}
              <li>
                <font size={2} face="verdana,arial,helvetica,sans-serif">
                  Long shelf life
                </font>
              </li>{" "}
            </ul>
            <div align="justify">
              <font size={2} face="verdana,arial,helvetica,sans-serif">
                {" "}
              </font>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default SodiumPhosphate