import React from "react";
import { NavLink } from "react-router-dom";

const SubHeader = ({ curruntPath }) => {
  return (
    <div id="subheader" class="sleft">
      <div class="subheader-inner">
        <div class="subdesc">
          <h1 class="page-title">
            {curruntPath === "OurApproach"
              ? "ABOUT US"
              : curruntPath.toUpperCase()}
          </h1>
        </div>
        <div class="breadcrumb-main">
          <div class="breadcrumb-wrap inner">
            <div class="breadcrumbs">
              <span>
                <NavLink to={"/"} class="home">
                  Home
                </NavLink>
              </span>
              <span>
                <i className="fa fa-angle-right" />
              </span>
              <span>
                <span>
                  {curruntPath === "OurApproach" ? "About us" : curruntPath}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
