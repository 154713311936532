import React from "react";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <div class="topbar">
      <div class="inner">
        <div class="topbar-right">
          <aside id="nav_menu-2" class=" widget_nav_menu">
            <div class="menu-topbar-menu-container">
              <ul id="menu-topbar-menu" class="menu">
                <li>
                  <Link to={"/contach-us"}>+ (91) 96870 52936</Link>
                </li>
                <li>|</li>
                <li>
                  <Link to={"/contach-us"}>info@aartichempharma.com</Link>
                </li>
              </ul>
            </div>
          </aside>
        </div>
          <div id="google_translate_element"></div>
      </div>
    </div>
  );
};

export default Topbar;
