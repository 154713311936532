import React from 'react'

const DisodiumEdetate = () => {
  return (
    <div>
        <div className="container">
  <div className="other-info-section" id="productInfo">
    <div className="product-description-section-main" id="productDescription">
      <div className="product-description-section">
        <h2>Product Description</h2>
        <p lang="en" >
          <strong>Product Details :</strong>
        </p>
        <table cellPadding={4} cellSpacing={0} width="100%">
          <colgroup>
            <col width="128*" />
            <col width="128*" />
          </colgroup>
          <tbody>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "1px medium 1px 1px",
                  borderStyle: "solid none solid solid",
                  borderColor:
                    "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0.04in 0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">Product Name</p>
              </td>
              <td
                style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.04in" }}
                width="50%"
              >
                <p lang="en">: DISODIUM EDETATE</p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">C.A.S. No.</p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">: 6381-92-6</p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">Molecular Formula</p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">
                  : C<sub>10</sub>H<sub>14</sub>N<sub>2</sub>Na<sub>2</sub>O
                  <sub>8</sub>2H<sub>2</sub>O
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">Molecular Weight</p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="50%"
              >
                <p lang="en">: 372.24 g/ mol</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ marginBottom: "0in" }}>
          <br />
        </p>
        <table cellPadding={4} cellSpacing={0} width="100%">
          <colgroup>
            <col width="85*" />
            <col width="85*" />
            <col width="85*" />
          </colgroup>
          <tbody>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "1px medium 1px 1px",
                  borderStyle: "solid none solid solid",
                  borderColor:
                    "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0.04in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <strong>
                    <span lang="en">SPECIFICATION</span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "1px medium 1px 1px",
                  borderStyle: "solid none solid solid",
                  borderColor:
                    "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0.04in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <strong>
                    <span lang="en">IP</span>
                  </strong>
                </p>
              </td>
              <td
                style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.04in" }}
                width="33%"
              >
                <p>
                  <strong>
                    <span lang="en">BP</span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Description</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">A white ,crystalline powder, Odourless</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">
                    White or almost white, crystalline powder.
                  </span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Solubility</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">
                    Soluble in water, practically insoluble in ethanol (96 %)
                  </span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p style={{ marginBottom: "0in" }}>
                  <span lang="en">Identification</span>
                </p>
                <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                <ul>
                  <li>
                    <span lang="en">IR</span>
                  </li>
                  <li>
                    <span lang="en">Reaction</span>
                  </li>
                  <li>
                    <span lang="en">Reaction</span>
                  </li>
                  <li>
                    <span lang="en">Reaction</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p lang="en" style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                <ul>
                  <li>
                    <span lang="en">
                      Must comply with the disodium edetate RS
                    </span>
                  </li>
                  <li>
                    <span lang="en">No precipitate is produced.</span>
                  </li>
                  <li>
                    <span lang="en">No precipitate is produced.</span>
                  </li>
                  <li>
                    <span lang="en">It gives reaction of sodium salt.</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p lang="en" style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                <ul>
                  <li>
                    <span lang="en">
                      Must comply with the disodium edetate RS
                    </span>
                  </li>
                  <li>
                    <span lang="en">No precipitate is produced.</span>
                  </li>
                  <li>
                    <span lang="en">No precipitate is produced.</span>
                  </li>
                  <li>
                    <span lang="en">It gives reaction of sodium salt.</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p style={{ marginBottom: "0in" }}>
                  <span lang="en">Appearance of solution</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">
                    5 % w/v solution in carbon dioxide free water is Clear &amp;
                    colorless
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Clear &amp; colorless</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">pH</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">4.0-5.5 ( 5 % w/v solution )</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">4.0-5.5 ( 5 % w/v solution )</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Loss on Drying</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Calcium</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">
                    Impurity A Limit of nitrilotriacetic acid(USP)
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">NMT 0.1 %</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">NMT 0.1 %</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Heavy metals</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">NMT 20 ppm</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">-</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Iron</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">NMT 80 ppm</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">NMT 80 ppm</span>
                </p>
              </td>
            </tr>
            <tr valign="top">
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">Assay</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium medium 1px 1px",
                  borderStyle: "none none solid solid",
                  borderColor:
                    "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">98.5 %-101.0 %</span>
                </p>
              </td>
              <td
                style={{
                  borderWidth: "medium 1px 1px",
                  borderStyle: "none solid solid",
                  borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                  padding: "0in 0.04in 0.04in"
                }}
                width="33%"
              >
                <p>
                  <span lang="en">98.5 %-101.0 %</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default DisodiumEdetate