import React from "react";

const PotassiumChloride = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p align="justify">
                  We, Yogi Chemical Industries, are well-known manufacturer and
                  supplier of quality checked{" "}
                  <strong>Potassium Chloride</strong>. With the aid advanced
                  methodology, the product is processed using balanced
                  composition of chemical compounds by our ingenious
                  professionals at our well-equipped unit. Ideal to treat the
                  problem of hypokalemia, the provided product also helps for
                  maintaining optimum level of potassium in blood. Moreover, the
                  offered <strong>Potassium Chloride </strong>can be bought from
                  us in moisture proof packaging options at marginal prices.
                </p>
                <p align="justify">
                  <strong>Key points:</strong>
                </p>
                <ul>
                  {" "}
                  <li>
                    High effectiveness <br />
                  </li>{" "}
                  <li>
                    Used for making fertilizers <br />
                  </li>{" "}
                  <li>Longer shelf life</li>{" "}
                  <li>
                    Free from impurities
                    <br />{" "}
                  </li>{" "}
                </ul>{" "}
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                  }}
                />{" "}
                <p style={{ pageBreakBefore: "always" }}>
                  <strong>
                    <span lang="en-US">Potassium Chloride </span>
                  </strong>{" "}
                </p>{" "}
                <ul>
                  <li>
                    <strong>C.A.S. No. </strong>: 7447-40-7{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula :</strong> Kcl{" "}
                  </li>
                  <li>
                    <strong>Molecular Weight</strong> : 74.55{" "}
                  </li>
                </ul>
                <center>
                  {" "}
                  <table cellSpacing={0} cellPadding={0} width="100%">
                    {" "}
                    <colgroup>
                      <col width="64*" /> <col width="64*" />{" "}
                      <col width="64*" /> <col width="64*" />{" "}
                    </colgroup>
                    <tbody>
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>SPECIFICATION</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>LR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>AR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            border: "1px solid rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>ACS</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Insoluble Matter</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>pH of a 5% solution at 250C</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>5.5 - 8.5</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>5.0 - 8.0</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>5.4 - 8.6</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Nitrogen Compounds (N)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Iodide (I)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.01%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.002%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Bromide (Br)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.01%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.01%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.01%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Chlorate and Nitrate</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.003%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Sulphate (SO4)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.02%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Heavy Metals (as Pb)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Iron (Fe)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0003%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Phosphate (PO4)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Calcium (Ca)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.002%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Barium (Ba)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>Passes out</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Magnesium (Mg)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Sodium (Na)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.02%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>0.005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Assay</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>NLT 99.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>NLT 99.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>99 - 100.5%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            <strong>Loss on Drying</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>1.0%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                </center>{" "}
                <p>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PotassiumChloride;
