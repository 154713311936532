import React from "react";

const Urea = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      "TD P { margin-bottom: 0cm; }P { margin-bottom: 0.21cm; }",
                  }}
                />{" "}
                <p lang="en-US" style={{ pageBreakBefore: "always" }}>
                  <strong>Urea </strong>{" "}
                </p>{" "}
                <ul>
                  <li>
                    <strong>C.A.S. No. :</strong> 57-13-6{" "}
                  </li>
                  <li>
                    <strong>Molecular Formula </strong>: Ch4n2o{" "}
                  </li>
                  <li>
                    <span lang="en-US">
                      <strong>Molecular Weight </strong>
                    </span>
                    <span lang="en-US">: 60.06 </span>{" "}
                  </li>
                </ul>{" "}
                <center>
                  {" "}
                  <table cellSpacing={0} cellPadding={0} width="100%">
                    {" "}
                    <colgroup>
                      <col width="64*" /> <col width="64*" />{" "}
                      <col width="64*" /> <col width="64*" />{" "}
                    </colgroup>
                    <tbody>
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>SPECIFICATION</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">LR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "1px medium 1px 1px",
                            borderStyle: "solid none solid solid",
                            borderColor:
                              "rgb(0, 0, 0) -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">AR</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            border: "1px solid rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">ACS</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Water Insoluble Matter</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.003%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p>
                            &nbsp;
                            <strong>
                              <span lang="en-US">Insoluble Matter</span>
                            </strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.04%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Chloride (Cl)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.002%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Residue after Ignition</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.01%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Acidify </strong>{" "}
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.05%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Alkalinity</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.05%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Sulphate (SO4)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.05%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Heavy Metals (as Pb)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Iron (Fe)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.001%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Copper (Cu)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.0001%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Biuret (C2H5N3O2)</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.1%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.05%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Sulphate Ash</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.005%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Assay</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">99.5 – 100.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">NLT 99.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">99 – 100.5%</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={29}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Melting Point</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">131 – 134 0c</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">132 – 135 0c</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        {" "}
                        <td
                          width="25%"
                          height={28}
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">
                            <strong>Loss on Drying</strong>
                          </p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">0.5%</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium medium 1px 1px",
                            borderStyle: "none none solid solid",
                            borderColor:
                              "-moz-use-text-color -moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                        <td
                          width="25%"
                          style={{
                            borderWidth: "medium 1px 1px",
                            borderStyle: "none solid solid",
                            borderColor:
                              "-moz-use-text-color rgb(0, 0, 0) rgb(0, 0, 0)",
                            padding: "0cm",
                          }}
                        >
                          {" "}
                          <p lang="en-US">---</p>{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>
                  </table>{" "}
                </center>{" "}
                <p>
                  <strong>Features</strong>
                </p>{" "}
                <ul>
                  <li>Quality approved </li>
                  <li>Preciously processed </li>
                  <li>Soluble in Properties of water </li>
                  <li>Long shelf life </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Urea;
