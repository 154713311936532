import React from "react";
import copper1 from "../../images/copper1.jpg";
import copper2 from "../../images/copper2.jpg";
import copper3 from "../../images/copper3.jpg";

const Copper = () => {
  return (
    <div>
      <h2 style={{ marginTop: "50px", textTransform: "uppercase" }}>
        Copper Sulphate Pentahydrate LR Grade.
      </h2>
      <p>
        Backed by years of industry experience, we are engaged in offering a
        premium quality range of Copper Sulphate Pentahydrate LR Grade.Copper
        sulphate pentahydrate is a fungicide and pesticide. However, some fungi
        are capable of adapting to elevated levels of copper ions. Mixed with
        lime it is called Bordeaux mixture and used to control fungus on grapes,
        melons, and other berries..
      </p>
      <div>
        <h2 style={{ textTransform: "uppercase" }}>
          Copper Sulphate Pentahydrate (LR - Grade)
        </h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Product Name</td>
              <td>Copper (II) sulphate pentahydrate, LR</td>
            </tr>
            <tr>
              <td>Synonym</td>
              <td>Cupric sulphate pentahydrate, LR 4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>CuSO4 .5H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>249.69</td>
            </tr>
            <tr>
              <td>CAS No.</td>
              <td>7758-99-8</td>
            </tr>
            <tr>
              <td>EC No.</td>
              <td>231-847-6</td>
            </tr>
            <tr>
              <td>HS Code</td>
              <td>2833 25 00</td>
            </tr>
            <tr>
              <td>Shelf Life</td>
              <td>4 years</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 style={{ textTransform: "uppercase" }}>COPPER NITRATE</h2>
      <img src={copper1} alt="" style={{ marginbottom: "15px" }} />
      <h2 style={{ textTransform: "uppercase" }}>
        Copper Sulphate Pentahydrate LR Grade.
      </h2>
      <img src={copper2} alt="" style={{ marginbottom: "15px" }} />
      <h2 style={{ textTransform: "uppercase" }}>
        Copper Sulphate Pentahydrate (AR - Grade)
      </h2>
      <img src={copper3} alt="" style={{ marginBottom: "30px" }} />
    </div>
  );
};

export default Copper;
