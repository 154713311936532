import React from 'react'

const SorbicAcid = () => {
  return (
    <div><div className="other-info-section-main">
    <div className="container">
      <div className="other-info-section" id="productInfo">
        <div className="product-description-section-main" id="productDescription">
          <div className="product-description-section">
            <h2>Product Description</h2>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <strong>Product Details :</strong>
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="128*" />
                <col width="128*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Product Name</strong>
                    </p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="50%"
                  >
                    <p>: SORBIC ACID</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>C.A.S. No.</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 110-44-1 </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Formula</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      : C<sub>6</sub>H<sub>8</sub>O<sub>2</sub>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>
                      <strong>Molecular Weight</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="50%"
                  >
                    <p>: 112.1</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
              <br />
            </p>
            <table cellPadding={4} cellSpacing={0} width="100%">
              <colgroup>
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
                <col width="64*" />
              </colgroup>
              <tbody>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>SPECIFICATION</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>IP</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "solid none solid solid",
                      borderWidth: "1px medium 1px 1px",
                      padding: "0.1cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>BP</strong>
                    </p>
                  </td>
                  <td
                    style={{ border: "1px solid rgb(0, 0, 0)", padding: "0.1cm" }}
                    width="25%"
                  >
                    <p>
                      <strong>USP</strong>
                    </p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Description</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>White, or almost white Crystalline powder</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>White or almost white, crystalline powder.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>White or almost white, crystalline powder.</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Solubility</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      Slightly Soluble in water freely soluble in ethanol (96 %).
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p style={{ marginBottom: "0cm" }}>
                      <strong>Appearance of solution</strong>
                    </p>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>(5 % w/v solution) Solution is clear and colorless.</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>(1.25 % w/v solution)Solution is clear and colorless.</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <ul>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Identification</strong>
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>Melting point</strong>
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>UV Spectrophotometer</strong>
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          <strong>IR </strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>BY color</strong>
                        </p>
                      </li>
                    </ul>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <ul>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          Comparison sorbic Acid RS or reference spectrum of
                          sorbic acid
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          absorbance at about 264 nm 0.43 to 0.51
                        </p>
                      </li>
                      <li>
                        <p>The solution is Decolorised</p>
                      </li>
                    </ul>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <br />
                    <ul>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          132<sup>o</sup>C to 136<sup>o</sup>C
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>230-350 nm</p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          Comparison sorbic Acid CRS
                        </p>
                      </li>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          The solution is decolorised.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <br />
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <ul>
                      <li>
                        <p style={{ marginBottom: "0cm" }}>
                          The color is discharged
                        </p>
                      </li>
                      <li>
                        <p>
                          254 <u>+</u> 2 nm
                        </p>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Aldehydes</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.15%</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.15 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Heavy metals</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 10 ppm</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 10 ppm</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Water</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 1.0 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 1.0 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.5 %</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Organic Volatiles</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Meets the requirement</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Residual Solvents</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>-</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>Meets the requirement</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Sulphated Ash</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.2 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.2 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>NMT 0.2 %</p>
                  </td>
                </tr>
                <tr valign="top">
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>
                      <strong>Assay</strong>
                    </p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % - 101.0 %</p>
                  </td>
                  <td
                    style={{
                      borderColor:
                        "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none none solid solid",
                      borderWidth: "medium medium 1px 1px",
                      padding: "0cm 0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % - 101.0 %</p>
                  </td>
                  <td
                    style={{
                      borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                      borderStyle: "none solid solid",
                      borderWidth: "medium 1px 1px",
                      padding: "0cm 0.1cm 0.1cm"
                    }}
                    width="25%"
                  >
                    <p>99.0 % - 101.0 %</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default SorbicAcid