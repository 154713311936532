import React from "react";
import diamond from "../../images/diamond.svg";

const OurApproach = () => {
  return (
    <div className="entry-content-wrapper clearfix">
      <figure style={{ marginBottom: 30 }}>
        <img width={1000} height={609} src="assets/images/photo4.jpg" alt="" />
      </figure>
      <h3>
        <strong>Company Approach</strong>
      </h3>
      <p>
        Company Approach At AARTI CHEM PHARMA & AARTI DYE CHEM JOINT VENTURE ,
        our approach centers on innovation, quality, and a patient-centric
        focus. We are committed to developing cutting-edge pharmaceutical
        solutions that address real-world health challenges while ensuring the
        highest standards of safety and efficacy. By prioritizing sustainability
        and fostering collaboration with healthcare professionals and industry
        partners, we aim to make a meaningful impact on global health, enhancing
        the quality of life for patients everywhere.
      </p>

      <div className="one_half ">
        <div className="atp-services  left">
          <div className="serviceIcn_style1">
            <div className="services-icon">
              <i
                className="services_icon1 fa fa-fw fa-2x fa-briefcase fa-4x"
                style={{ color: "#990b6e" }}
              />
            </div>

            <div className="services-content">
              <h3>Business Services Consultation</h3>
              <p>
                 AARTI CHEM PHARMA & AARTI DYE CHEM Company provides tailored business services
                consultation for the pharmaceutical industry, offering strategic
                guidance on compliance, market entry, and operational efficiency
                to help clients drive growth and navigate challenges.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="one_half last">
        <div className="atp-services  left">
          <div className="serviceIcn_style1">
            <div className="services-icon">
              <i
                className="services_icon1 fa fa-fw fa-2x fa-money fa-4x"
                style={{ color: "#990b6e" }}
              />
            </div>

            <div className="services-content">
              <h3>Mutual Funds Consultation</h3>
              <p>
                 AARTI CHEM PHARMA & AARTI DYE CHEM Company offers expert mutual funds consultation,
                guiding clients in fund selection, risk assessment, and
                portfolio management to empower informed investment decisions
                for long-term growth.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="one_half">
        <div className="atp-services  left">
          <div className="serviceIcn_style1">
            <div className="services-icon">
              <i
                className="services_icon1 fa fa-fw fa-2x fa-shopping-cart fa-4x"
                style={{ color: "#990b6e" }}
              />
            </div>

            <div className="services-content">
              <h3>Consumer Products Consultation</h3>
              <p>
                 AARTI CHEM PHARMA & AARTI DYE CHEM Company offers specialized consultation for consumer
                products, providing insights on product development, branding,
                and market strategy to help businesses enhance their market
                presence and meet consumer needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="one_half last">
        <div className="atp-services  left">
          <div className="serviceIcn_style1">
            <div className="services-icon">
              <i />
              <img src={diamond} alt="diamond" style={{ height: "65px" }} />
            </div>

            <div className="services-content">
              <h3>Financial Services Consultation</h3>
              <p>
                 AARTI CHEM PHARMA & AARTI DYE CHEM Company delivers tailored financial services
                consultation, offering expert guidance on investment planning
                and risk management to help clients achieve their financial
                goals.
              </p>
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
};

export default OurApproach;
