// // src/MainRouter.js
// import React from "react";
// import { Route, Routes } from "react-router-dom";
// import BackToTop from "./common/BackToTop";
// import Footer from "./common/Footer";
// import Navbar from "./common/Navbar";
// import Topbar from "./common/Topbar";
// import Aboutpage from "./components/Aboutpage";
// import ContactPage from "./components/ContactPage";
// import Homepage from "./components/Homepage";
// import Product from "./components/Product";
// import ContractManufacturing from "./components/ContractManufacturing";

// const MainRouter = () => (
//   <div>
//     <Topbar />
//     <Navbar />
//     <Routes>
//       <Route path="/" element={<Homepage />} />
//       <Route path="/about-us" element={<Aboutpage />} />
//       <Route path="/contact-us" element={<ContactPage />} />
//       <Route path="/product" element={<Product />} />
//       <Route
//         path="/ContractManufacturing"
//         element={<ContractManufacturing />}
//       />
//     </Routes>
//     <Footer />
//     <BackToTop />
//   </div>
// );

// export default MainRouter;
import React from "react";
import { Route, Routes } from "react-router-dom";
import BackToTop from "./common/BackToTop";
import Footer from "./common/Footer";
import Navbar from "./common/Navbar";
import Topbar from "./common/Topbar";
import Aboutpage from "./components/Aboutpage";
import ContactPage from "./components/ContactPage";
import Homepage from "./components/Homepage";
import Product from "./components/Product";
import ContractManufacturing from "./components/ContractManufacturing";
import Apis from "./common/product/Apis";
import Drugintermediates from "./common/product/Drugintermediates";
import Finechemicals from "./common/product/Finechemicals";
import Labgradechemical from "./common/product/Labgradechemical";

const MainRouter = () => (
  <div>
    <Topbar />
    <Navbar />
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about-us" element={<Aboutpage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/product" element={<Product />} />
      <Route path="/apis" element={<Apis />} />
      <Route path="/drugintermediates" element={<Drugintermediates />} />
      <Route path="/Finechemicals" element={<Finechemicals />} />
      <Route path="/Labgradechemical" element={<Labgradechemical />} />

      <Route
        path="/ContractManufacturing"
        element={<ContractManufacturing />}
      />
    </Routes>
    <Footer />
    <BackToTop />
  </div>
);

export default MainRouter;
