import React from "react";

const MonoPotassiumPhoshphate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0in" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.04in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.04in",
                        }}
                        width="50%"
                      >
                        <p>: MONOBASIC POTASIUM PHOSPHATE</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 7778-77-0</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          : KH<sub>2</sub>PO<sub>4</sub>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.04in 0.04in",
                        }}
                        width="50%"
                      >
                        <p>: 136.10 g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0in" }}>
                  <br />
                </p>
                <table cellPadding={2} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="85*" />
                    <col width="85*" />
                    <col width="85*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.02in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">SPECIFICATION</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.02in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">BP</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">USP</span>
                          </strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Description</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white powder , crystalline powder ,
                            colorless powder or crystalline masses,
                            efflorescent.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            White or almost white powder , crystalline powder ,
                            colorless powder or crystalline masses,
                            efflorescent.
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Appearance of solution</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            4% w/v Solution is clear and colorless
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Solubility</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            Soluble in water , very soluble in boiling water and
                            freely soluble in glycerol.
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <strong>
                            <span lang="en">Identification</span>
                          </strong>
                        </p>
                        <ul>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                          <li>
                            <strong>
                              <span lang="en">Reaction</span>
                            </strong>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p lang="en" style={{ marginBottom: "0in" }}>
                          <br />
                        </p>
                        <ul>
                          <li>
                            <span lang="en">A. Solution S is faintly acid</span>
                          </li>
                          <li>
                            <span lang="en">B. Reaction of Potassium</span>
                          </li>
                          <li>
                            <span lang="en">C. Reaction for Phosphates</span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p style={{ marginBottom: "0in" }}>
                          <span lang="en">
                            <br />
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span lang="en">A. Reaction of Potassium</span>
                          </li>
                          <li>
                            <span lang="en">B. Reaction for Phosphates</span>
                          </li>
                        </ul>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">pH (4% w/v solution in water)</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">4.2-4.5</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sulphate</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 50 ppm.</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Ammonium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT10 ppm.</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Arsenic</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 2 ppm.</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 3 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sodium</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 1000 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Iron</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Sulphate</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 300 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Chloride</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 200 ppm</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Lead</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 5 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Heavy Metal</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 20 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Fluoride</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">NMT 10 ppm</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Reducing Substances</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">
                            The colour of permanganate is not completely
                            discharged
                          </span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Residual Solvents</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Meets the requirement</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Organic volatile impurities</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Meets the requirement</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Insoluble substances</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">-</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Max. 0.2%</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Loss on Drying at 1050C</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Max. 2.0%</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">Max. 1.0%</span>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <strong>
                            <span lang="en">Assay Na2B4O7,10H2O</span>
                          </strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0in 0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">98.0 % - 100.5</span>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0in 0.02in 0.02in",
                        }}
                        width="33%"
                      >
                        <p>
                          <span lang="en">98.0 % to 100.5 %</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonoPotassiumPhoshphate;
