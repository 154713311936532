import React from "react";

const SodiumSaccharin = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: SODIUM SACCHARIN ANHYDROUS</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 128-44-9</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        : C<sub>7</sub>H<sub>4</sub>NNO<sub>3</sub>S.H
                        <sub>2</sub>O
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 205.2 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="64*" />
                  <col width="64*" />
                  <col width="64*" />
                  <col width="64*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>SPECIFICATION</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>IP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>BP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>USP</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Description</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        A white, Crystalline powder; or colorless crystals;
                        efflorescent in dry air
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        A white, or almost white Crystalline powder; or
                        colorless crystals; efflorescent in dry air
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        A white, or almost white Crystalline powder; or color
                        less crystals; efflorescent in dry air.
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Solubility</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        Freely soluble in water, sparingly soluble in ethanol
                        (96 %)
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <ul>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Identification</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>IR</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>By Color</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>By color</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Melting Point</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Reaction of sodium salt</strong>
                          </p>
                        </li>
                      </ul>
                      <p>
                        <br />
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <ul>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            Complies with the saccharin sodium RS.
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            A violet color develops
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            An intense green fluorescence develops
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>226 C to 230 C</p>
                        </li>
                        <li>
                          <p>It gives reaction of Sodium salt</p>
                        </li>
                      </ul>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <ul>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            Complies with the saccharin sodium RS.
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            A violet color develops
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            An intense green fluorescence develops
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>226 C to 230 C</p>
                        </li>
                        <li>
                          <p>It gives reaction of Sodium.</p>
                        </li>
                      </ul>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <ul>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>226 C to 230 C</p>
                        </li>
                        <li>
                          <p>It gives reaction of Sodium salt</p>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Appearance of solution </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Solution is Clear &amp; Colorless</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Solution is Clear &amp; Colorless</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Acidity or alkalinity</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        NLT 4.5 ml &amp; NMT 5.5 ml of 0.01 M sodium hydroxide
                        is required to change the color of the solution to pink.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>The solution becomes pink or red.</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        A solution is neutral or alkaline to litmus, but no red
                        color is produced with phenolphthalein TS.
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>(Related Substances)</strong>
                      </p>
                      <p>
                        <strong>o- andp-Toluene sulfonamide</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 10 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 10 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 10 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Limit of Benzoate &amp;Salicylate</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>No precipitate or Violet color appears.</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Selenium</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 0.003 %</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Readily carbon sable substances</strong>
                      </p>
                      <p>
                        <br />
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        The solution is not more intensely colored than
                        reference solution BS6.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        The solution is not more intensely colored than a
                        solution prepared by mixing 0.1mL of red primary
                        solution, 0.1mL of blue primary solution and 0.4mL of
                        yellow primary solution with 4.4mL of water
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        The solution has no more color than matching fluid A.
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Arsenic</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 2 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Heavy metals</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 2 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 10 ppm</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Limit of benzoate and salicylate</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        No precipitate or violet color appears in the liquid.
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Organic volatile impurities</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Meets the requirement.</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Residual solvents</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>Meets the requirement.</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Water</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 15.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 15.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>NMT 15.0 %</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>
                        <strong>Assay </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>99.0 %-101.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>99.0 %-101.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="25%"
                    >
                      <p>98.0 %-101.0 %</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumSaccharin;
