const ZincSulphateHeptahydrate = () => {
  return (
    <div className="container">
      <section className="product-overview">
        <h2 style={{ marginTop: "50px"  , textTransform: "uppercase"}}>Product Overview</h2>
        <p>
          <p>
            Leading manufacturer and exporter of Zinc Sulphate Heptahydrate.
          </p>
          We are producing Zinc Sulphate Heptahydrate (EP/LR Grade), Zinc
          Sulphate Heptahydrate (AR/GR Grade), Zinc Sulphate Heptahydrate
          (Tech).
        </p>

      </section>
      <table>
        <thead>
          <tr>
            <th>Property</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Synonyms</td>
            <td>White Vitriol, Zinc Sulfate Heptahydrate Crystals</td>
          </tr>
          <tr>
            <td>Appearance</td>
            <td>White Crystalline Powder</td>
          </tr>
          <tr>
            <td>Formula</td>
            <td>ZnSO4.7H2O</td>
          </tr>
          <tr>
            <td>CAS No.</td>
            <td>7446-20-0</td>
          </tr>
          <tr>
            <td>Content</td>
            <td>21% Zn (Agri Grade) & 21.5% Zn (Iron Free Grade)</td>
          </tr>
          <tr>
            <td>Uses</td>
            <td>Agriculture, Fertilizers, Dyes, etc.</td>
          </tr>
        </tbody>
      </table>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Zinc Sulphate Heptahydrate (LR - Grade)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>White Vitriol; Zinc Vitriol</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>ZnSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>287.56</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7446-02-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent, Astringent,
                Insoluble in Alcohol.
              </td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>4.0 to 6.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.01% Max</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.005% Max</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.002% Max</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.01% Max</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.05% Max</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}>Zinc Sulphate Heptahydrate (AR - Grade)</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Synonyms</td>
              <td>White Vitriol; Zinc Vitriol</td>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>ZnSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>287.56</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7446-02-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                White Crystalline Powder, Odorless, Efflorescent, Astringent,
                Insoluble in Alcohol.
              </td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.5% Min.</td>
                    </tr>
                    <tr>
                      <td>Insoluble Matter</td>
                      <td>0.01% Max.</td>
                    </tr>
                    <tr>
                      <td>pH of 5% Solution</td>
                      <td>4.4-6.0</td>
                    </tr>
                    <tr>
                      <td>Chloride (Cl)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Iron (Fe)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Cadmium (Cd)</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Copper</td>
                      <td>0.0005% Max.</td>
                    </tr>
                    <tr>
                      <td>Manganese (Mn)</td>
                      <td>0.0003% Max.</td>
                    </tr>
                    <tr>
                      <td>Arsenic (As)</td>
                      <td>0.0001% Max.</td>
                    </tr>
                    <tr>
                      <td>Magnesium (Mg)</td>
                      <td>0.005% Max.</td>
                    </tr>
                    <tr>
                      <td>Nitrate (NO3)</td>
                      <td>0.002% Max.</td>
                    </tr>
                    <tr>
                      <td>Lead (Pb)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Calcium (Ca)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Total Nitrogen (N)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Ammonia (NH4)</td>
                      <td>0.001% Max.</td>
                    </tr>
                    <tr>
                      <td>Sodium (Na)</td>
                      <td>0.05% Max.</td>
                    </tr>
                    <tr>
                      <td>Potassium (K)</td>
                      <td>0.01% Max.</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <h2  style={{ textTransform: "uppercase" }}> Product : Zinc Sulphate Heptahydrate – Iron Free</h2>
        <table>
          <tbody>
            <tr>
              <th>Property</th>
              <th>Details</th>
            </tr>
            <tr>
              <td>Molecular Formula</td>
              <td>ZnSO4.7H2O</td>
            </tr>
            <tr>
              <td>Molecular Weight</td>
              <td>287.56</td>
            </tr>
            <tr>
              <td>CAS Number</td>
              <td>7446-02-0</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                Colorless transparent crystal or white crystalline powder,
                odorless, efflorescent.
              </td>
            </tr>
            <tr>
              <td>Specification</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <th>Specification</th>
                      <th>Value</th>
                    </tr>
                    <tr>
                      <td>Assay</td>
                      <td>99.0% Minimum</td>
                    </tr>
                    <tr>
                      <td>Acidity</td>
                      <td>4.0 to 6.0</td>
                    </tr>
                    <tr>
                      <td>Iron as (Fe)</td>
                      <td>
                        100 PPM / 20 PPM / 10 PPM / 5 PPM (as per requirement)
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <section className="uses">
        <h2>Uses</h2>
        <ul>
          <li>Laboratory Reagent</li>
          <li>Analytical Reagent</li>
          <li>Pharmaceuticals</li>
          <li>Rayon & Paper</li>
          <li>Pigments</li>
          <li>Electro Refining of Zinc</li>
          <li>Lithopone</li>
          <li>Agriculture</li>
          <li>Preserving Wood & Silk</li>
          <li>Electroplating</li>
          <li>Water Treatment</li>
          <li>Clarifying Glue</li>
          <li>Mordant in Calico Printing</li>
          <li>Cattle & Poultry Feed Supplements</li>

          <li>Manufacturing of Other Organic & Inorganic based Zinc Salts</li>
        </ul>
      </section>
    </div>
  );
};
export default ZincSulphateHeptahydrate;
