import React from "react";

const CalciumChlorideDihydrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <strong>Product Details :</strong>
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="128*" />
                    <col width="128*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Product Name</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="50%"
                      >
                        <p>Calcium Chloride Dihydrate</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>C.A.S. No.</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>10035-04-8</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Formula</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          CaCl<sub>2</sub>.2H<sub>2</sub>O
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>
                          <strong>Molecular Weight</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="50%"
                      >
                        <p>147.01 g/mol</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                  <br />
                </p>
                <table cellPadding={4} cellSpacing={0} width="100%">
                  <colgroup>
                    <col width="54*" />
                    <col width="74*" />
                    <col width="77*" />
                    <col width="51*" />
                  </colgroup>
                  <tbody>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>
                          <strong>SPECIFICATION</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>
                          <strong>IP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "solid none solid solid",
                          borderWidth: "1px medium 1px 1px",
                          padding: "0.1cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          <strong>BP</strong>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0.1cm",
                        }}
                        width="20%"
                      >
                        <p>
                          <strong>USP</strong>
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Description</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>
                          A White, Crystalline powder or fragments or granules,
                          odorless, hygroscopic.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          White or almost white, crystalline powder,
                          hygroscopic.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>
                          White or almost white, crystalline powder,
                          hygroscopic.
                        </p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Solubility</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          Freely soluble in water and soluble in ethanol (96 %)
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p style={{ marginBottom: "0cm" }}>
                          Appearance of solution{" "}
                        </p>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>
                          Clear and not more intensely colored than reference
                          solution YS6.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          10 % solution is clear and not more intensely colored
                          than reference solution Y6.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p style={{ marginBottom: "0cm" }}>Identification </p>
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reaction Of Calcium
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reaction of Chlorides
                            </p>
                          </li>
                          <li>
                            <p>Assay </p>
                          </li>
                        </ol>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reactions of Calcium salts
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reactions of Chlorides
                            </p>
                          </li>
                        </ol>
                        <p>
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              10% solution gives reaction of chloride
                            </p>
                          </li>
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              It gives reaction of calcium
                            </p>
                          </li>
                          <li>
                            <p>It complies with the limit test of assay</p>
                          </li>
                        </ol>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <ol type="A">
                          <li>
                            <p style={{ marginBottom: "0cm" }}>
                              Reactions of Calcium salts
                            </p>
                          </li>
                          <li>
                            <p>Reactions of Chlorides</p>
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Acidity or Alkalinity</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>
                          To 10 ml of freshly prepared 10 % w/v solution add 2
                          drops off phenolphthalein solution. Titrate with 0.01
                          M hydrochloric acid or 0.01 M sodium hydroxide.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          If the solution is red , than not more 0.2 ml of 0.01
                          M HCL need to discharge the color and if the solution
                          is colorless, not more than 0.2 mlNaOH is required to
                          turn it red.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>pH </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>4.5 - 9.2</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Arsenic </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>NMT 3 ppm.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Barium</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>
                          After not less than 15 min. the solution is not more
                          opalescent than a mixture of 10 ml of solution and 1
                          ml of distilled water.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>
                          After 15 min, Any opalescent in the solution is not
                          more intense than that in a mixture of 1 ml of
                          distilled water and 1.0 gm of sample.
                        </p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Aluminum&amp;Phosphate (IP)</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>No turbidity or precipitate is produced.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>No turbidity or precipitate is formed.</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>NMT 1 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Iron , Aluminum &amp; Phosphate</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>No turbidity or precipitate is formed.</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Heavy metals</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>NMT10 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>NMT10 ppm</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Iron </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>NMT 20 ppm.</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>NMT10 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Magnesium and Alkali salts </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>NMT 1.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>NMT0.5%</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>NMT 1.0 %</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Sulphate </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>NMT 300 ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>NMT 300ppm</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>-</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Organic Volatile impurities</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>-</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>Meets the requirements</p>
                      </td>
                    </tr>
                    <tr valign="top">
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="21%"
                      >
                        <p>Assay </p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="29%"
                      >
                        <p>97.0 % to 103.0 %</p>
                      </td>
                      <td
                        style={{
                          borderColor:
                            "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none none solid solid",
                          borderWidth: "medium medium 1px 1px",
                          padding: "0cm 0cm 0.1cm 0.1cm",
                        }}
                        width="30%"
                      >
                        <p>97.0% - 103.0%</p>
                      </td>
                      <td
                        style={{
                          borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                          borderStyle: "none solid solid",
                          borderWidth: "medium 1px 1px",
                          padding: "0cm 0.1cm 0.1cm",
                        }}
                        width="20%"
                      >
                        <p>99.0% - 107.0%</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalciumChlorideDihydrate;
