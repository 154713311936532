import React, { useState } from "react";
import Sidebar from "../common/Sidebar";
import SubHeader from "../common/SubHeader";
import OurApproach from "../common/About/OurApproach";
import OurVision from "../common/About/OurVision";
import OurPartner from "../common/About/OurPartner";
import OurValue from "../common/About/OurValue";
import OurPrincipal from "../common/About/OurPrincipal";
import Career from "../common/About/Career";

const Aboutpage = () => {
  const [curruntPath, setCurruntPath] = useState("Our Approach");

  const menuItems = [
    { name: "Our Approach" },
    { name: "Our Vision" },
    { name: "Our Principal" },
    { name: "Our Value" },
    { name: "Our Partner" },
    { name: "Career" },
  ];

  return (
    <>
      <div className="rightsidebar">
        <div className="labora_page_loader" style={{ display: "none" }} />
        <div id="wrapper">
          <SubHeader curruntPath={curruntPath} />

          <div id="main">
            <div id="primary" className="pagemid">
              <div className="inner">
                <main className="content-area">
                  {curruntPath === "Our Approach" && <OurApproach />}
                  {curruntPath === "Our Vision" && <OurVision />}
                  {curruntPath === "Our Partner" && <OurPartner />}

                  {curruntPath === "Our Value" && <OurValue />}
                  {curruntPath === "Our Principal" && <OurPrincipal />}
                  {curruntPath === "Career" && <Career />}
                </main>
                <Sidebar setCurruntPath={setCurruntPath} menuItems={menuItems} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
