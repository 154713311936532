import { Link, NavLink } from "react-router-dom";
import logo from "../images/artilogo.png";
import { windowScroll } from "./Footer";
import { useEffect, useState } from "react";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 959);

  // Detect window resize to switch between mobile and desktop view
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 959);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  // Close the mobile menu after clicking a link
  const closeMobileMenu = () => {
    if (isMobileView) {
      setIsMobileMenuOpen(false);
      setIsProductDropdownOpen(false); // Close dropdown as well
    }
  };

  const childIsActive = () => {
    const path = window.location.pathname;
    return (
      path.includes("/apis") ||
      path.includes("/drugintermediates") ||
      path.includes("/finechemicals") ||
      path.includes("/labgradechemical")
    );
  };

  return (
    <header>
      <div id="fixedheader" className="header">
        <div className="header-area">
          <div className="logo">
            <Link to="/" title="Labora - Medical and Laboratory">
              <img
                src={logo}
                alt="Labora - Medical and Laboratory"
                height="80"
              />
            </Link>
          </div>

          <div className="header-rightpart">
            <div className="primarymenu">
              <ul className="sf-menu">
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    HOME
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/about-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    About Us
                  </NavLink>
                </li>
                <li
                  className="menu-item menu-item-has-children"
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="#"
                    style={() => ({
                      color: childIsActive() ? "#990b6e" : "black",
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setIsProductDropdownOpen(!isProductDropdownOpen);
                    }}
                  >
                    PRODUCT
                  </NavLink>
                  {isProductDropdownOpen && (
                    <ul className="sub-menu">
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/apis">APIs</NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/drugintermediates">
                          Drug Intermediates
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/finechemicals">Fine Chemicals</NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/labgradechemical">
                          Lab-grade Chemicals
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/foodchemicals">Food Chemicals</NavLink>
                      </li>
                      <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/Dyesanddyeintermediates">
                          Dyes And Dye Intermediates
                        </NavLink>
                      </li>
                     <li
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink to="/WaterTreatment">
                        Industrial and water treatment chemical
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/ContractManufacturing"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Contract Manufacturing
                  </NavLink>
                </li>
                <li
                  onClick={() => {
                    closeMobileMenu();
                    windowScroll();
                  }}
                >
                  <NavLink
                    to="/contact-us"
                    style={({ isActive }) => ({
                      color: isActive ? "#990b6e" : "black",
                    })}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {/* Mobile Menu Toggle Button */}
          {isMobileView && (
            <div
              id="iva-mobile-nav-icon"
              className="iva-mobile-dropdown"
              onClick={toggleMobileMenu}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && isMobileView && (
          <div
            style={{
              backgroundColor: "#252525", // Light background for contrast
              padding: "10px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            }}
          >
            <ul
              className="iva_mmenu"
              style={{ listStyle: "none", padding: "0", margin: "0" }}
            >
              {["/", "/about-us", "/ContractManufacturing", "/contact-us"].map(
                (path, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      closeMobileMenu();
                      windowScroll();
                    }}
                  >
                    <NavLink
                      to={path}
                      style={{
                        display: "block",
                        padding: "15px 20px", // Increased padding for better touch target
                        textDecoration: "none",
                        color: "#999999", // Primary color for the links
                        transition: "background-color 0.3s", // Smooth transition for hover effect
                      }}
                    >
                      {path === "/"
                        ? "HOME"
                        : path.split("/")[1].replace("-", " ").toUpperCase()}
                    </NavLink>
                  </li>
                )
              )}
              <li
                onClick={(e) => {
                  e.preventDefault();
                  setIsProductDropdownOpen(!isProductDropdownOpen);
                }}
              >
                <NavLink
                  to="#"
                  style={{
                    display: "block",
                    padding: "15px 20px",
                    textDecoration: "none",
                    color: "#999999",
                    position: "relative",
                    transition: "background-color 0.3s",
                  }}
                >
                  PRODUCT
                  <span
                    className="iva-children-indenter"
                    style={{ marginLeft: "5px" }}
                  >
                    <i className="fa fa-angle-down"></i>
                  </span>
                </NavLink>
                {isProductDropdownOpen && (
                  <ul
                    className="sub-menu"
                    style={{
                      listStyle: "none",
                      padding: "0",
                      margin: "0",

                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                      position: "absolute",
                      left: "0",
                      right: "0",
                      zIndex: "10",
                    }}
                  >
                    {[
                      "/apis",
                      "/drugintermediates",
                      "/finechemicals",
                      "/labgradechemical",
                      "/foodchemicals",
                      "/Dyesanddyeintermediates",
                    ].map((path, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          closeMobileMenu();
                          windowScroll();
                        }}
                      >
                        <NavLink
                          to={path}
                          style={{
                            display: "block",
                            padding: "10px 60px",
                            textDecoration: "none",
                            transition: "background-color 0.3s",
                            backgroundColor: "#252525",
                            color: "#999999",
                            margin: "-1px 0",
                          }}
                        >
                          {path.split("/")[1].replace("-", " ").toUpperCase()}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
