import React from "react";
import SubHeader from "../common/SubHeader";

const ContractManufacturing = () => {
  return (
    <>
      <SubHeader curruntPath={"CONTRACT MANUFACTURING"} />
      <div style={styles.container}>
        <p>
          Business mainly focuses on AgroChemicals, Pharmaceutical Chemicals,
          and Fine Chemicals. Our market leadership in the organic and inorganic
          chemical range has been a result of an unwavering focus on innovation.
          Keeping this aspect as the benchmark of our production and
          development, we have been serving the needs of our clients as per
          their own needs and requirements. We manufacture the chemicals after
          gaining a complete insight into the industry of the clients. Our
          professionals are efficient enough to meet their requirements within
          the stipulated time period.
        </p>
        <p>
          Our sustainable development is a guiding principle at AARTI DYE CHEM
          and an unwavering focus on quality in the chemical industry leads to a
          complete understanding of environmental concerns and the need for a
          viable environmental policy. Here are some of our strengths that
          enable us to meet the specific requirements of our clients.
        </p>

        <ul style={styles.list}>
          <li style={styles.listItem}>
            Extensive experience in multi-step organic synthesis
          </li>
          <li style={styles.listItem}>
            Proficiency in fluorination, chlorination, and ammoniation
          </li>
          <li style={styles.listItem}>
            Expertise in hydrogenation and hydroformylation
          </li>
          <li style={styles.listItem}>
            Advanced skills in chiral synthesis and separation
          </li>
          <li style={styles.listItem}>
            Strong learning and optimization abilities on technology packages
          </li>
          <li style={styles.listItem}>
            Efficient processing and engineering transfer capabilities
          </li>
          <li style={styles.listItem}>
            Platforms for R&D, kilo lab, pilot plant, and commercial production
          </li>
        </ul>

        <p>
          We have expert processing and engineering transfer capabilities under
          some platforms like the platform of R&D → Kilo lab → Pilot plant →
          commercial production, platform of continuously and semi-continuously
          technology, platform of fluorination and chlorination synthesis.
        </p>
      </div>
    </>
  );
};

// Styles for the component
const styles = {
  container: {
    maxWidth: "800px", // Maximum width for the container
    margin: "0 auto", // Center the container
    padding: "20px", // Padding around the container
  },
  heading: {
    marginBottom: "20px", // Space below the heading
  },
  list: {
    listStyleType: "circle",
    padding: "20px 50px",
    margin: "20px 0",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    marginBottom: "10px",
    fontSize: "18px",
    color: "#333",
    lineHeight: "1.6",
    position: "relative",
    paddingLeft: "20px",
  },
};

export default ContractManufacturing;
