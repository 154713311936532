import React from "react";

const SodiumCarbonateAnhydrous = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: SODIUM CARBONATE ANHYDROUS</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>: 497-19-8</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        : Na<sub>2</sub>CO<sub>3</sub>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="50%"
                    >
                      <p> : 106.0 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ marginBottom: "0cm", lineHeight: "100%" }}>
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="57*" />
                  <col width="68*" />
                  <col width="72*" />
                  <col width="59*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>SPECIFICATION</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>
                        <strong>IP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "solid none solid solid",
                        borderWidth: "1px medium 1px 1px",
                        padding: "0.1cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        <strong>BP</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.1cm",
                      }}
                      width="23%"
                    >
                      <p>
                        <strong>USP</strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Description</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>
                        White or almost white powder, Slightly granular powder
                        Hygroscopic.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        White or almost white, Slightly granular powder
                        Hygroscopic.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>White or almost white powder, crystalline powder. </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Solubility</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        Freely Soluble in water, practically insoluble in
                        ethanol (96 %).
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        <strong>Identification</strong>
                      </p>
                      <ol type="A">
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>By color</strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Reaction </strong>
                          </p>
                        </li>
                        <li>
                          <p style={{ marginBottom: "0cm" }}>
                            <strong>Reaction</strong>
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>LOD </strong>
                          </p>
                        </li>
                      </ol>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        A : The solution is strongly alkaline{" "}
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B : Reactions of carbonates &amp; sodium salts
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        A : The solution is strongly alkaline{" "}
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        B : Test A gives the reaction of Carbonates
                      </p>
                      <p style={{ marginBottom: "0cm" }}>
                        C : Test A gives reaction of Sodium
                      </p>
                      <p>D : Complies with LOD Test.</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p style={{ marginBottom: "0cm" }}>
                        A : It gives reaction of Carbonate{" "}
                      </p>
                      <p>B : It gives reaction of Sodium</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Water </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>NMT 0.5 %</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Appearance of Solution</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>
                        2.0 % w/v solution is clear and not more intensely
                        colored than reference solution YS6
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>
                        {" "}
                        2.0 % w/v solution is clear and not more intensely
                        colored than reference solution Y5
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Alkali Hydroxides &amp; Bicarbonates</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>The solution remains Clear</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>The solution remains Clear</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Arsenic</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>NMT 5 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Heavy metals</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>NMT 2 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      {" "}
                      -<br />
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">NMT 10 ppm</font>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Calcium</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>
                        2.0 % W/V solution, when boiled for 5 min. solution is
                        clear.
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p> NMT100 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p align="left" lang="en-US">
                        <font face="Calibri, serif">-</font>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Iron</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>NMT 50 ppm.</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>NMT50 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Chlorides</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>NMT 125 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>NMT125 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Sulphates</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>MNT 250 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p> NMT250 ppm</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Organic Volatile Impurities</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>Meets the requirement</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Residual Solvents</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>-</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>Meets the requirement</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Loss on Drying</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>NMT 1.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>NMT 1.0 %</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>-</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="22%"
                    >
                      <p>
                        <strong>Assay</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="26%"
                    >
                      <p>99.5 % to 100.5 %</p>
                    </td>
                    <td
                      style={{
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none none solid solid",
                        borderWidth: "medium medium 1px 1px",
                        padding: "0cm 0cm 0.1cm 0.1cm",
                      }}
                      width="28%"
                    >
                      <p>99.5 % - 100.5 % (dry basis)</p>
                    </td>
                    <td
                      style={{
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        borderStyle: "none solid solid",
                        borderWidth: "medium 1px 1px",
                        padding: "0cm 0.1cm 0.1cm",
                      }}
                      width="23%"
                    >
                      <p>99.5 % - 100.5 %</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumCarbonateAnhydrous;
