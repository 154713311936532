import React from "react";
import SubHeader from "../SubHeader";

export const Dyesanddyeintermediates = () => {
  return (
    <>
      <SubHeader curruntPath={"Dyes and Dye Intermediates"} />
      <div style={{ padding: "90px" }}>
        <h2>Dyes and Dye Intermediates</h2>
        <p>
          At AARTI CHEM PHARMA & AARTI DYE CHEM, we offer a wide range of dyes
          and dye intermediates that are crucial for industries like textiles,
          leather, and paper. Our products are known for their excellent quality
          and performance, contributing to the vibrant and consistent coloration
          of various materials.
        </p>
        <p>
          Dye intermediates are the raw materials or compounds used in the
          production of dyes. These intermediates are essential for creating
          different kinds of dyes, including reactive, vat, acid, and direct
          dyes. Our dye intermediates are widely used by dye manufacturers
          around the world for the production of high-quality colorants.
        </p>

        <h3>Why Choose Our Dyes and Dye Intermediates?</h3>
        <ul>
          <li>
            <strong>High Purity</strong>: Our products meet stringent purity
            standards, ensuring consistent performance in dye production.
          </li>
          <li>
            <strong>Wide Range of Applications</strong>: Our dyes and dye
            intermediates are suitable for various industries, from textiles to
            paper and leather.
          </li>
          <li>
            <strong>Custom Solutions</strong>: We provide customized solutions
            tailored to the specific needs of our clients.
          </li>
        </ul>

        <h3>Our Product Range</h3>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Application</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Reactive Dyes</td>
              <td>Textiles</td>
            </tr>
            <tr>
              <td>Vat Dyes</td>
              <td>Cotton and cellulosic fibers</td>
            </tr>
            <tr>
              <td>Acid Dyes</td>
              <td>Wool, nylon, and silk</td>
            </tr>
            <tr>
              <td>Direct Dyes</td>
              <td>Paper and textiles</td>
            </tr>
            <tr>
              <td>Disperse Dyes</td>
              <td>Polyester and synthetic fibers</td>
            </tr>
            <tr>
              <td>Naphthol Dyes</td>
              <td>Cotton and wool</td>
            </tr>
            <tr>
              <td>Rhodamine B ( Basic Violet 10) </td>
              <td>Dye / liquid / Base</td>
            </tr>
            <tr>
              <td> DEMAP ALDEHYDE</td>
              <td></td>
            </tr>
            <tr>
              <td> DEMAP (Diethyl Meta Amino Phenol) </td>
              <td>Lumps / Powder</td>
            </tr>
            <tr>
              <td>PAABSA ( Para Aminoazobenzene 4 Sulfonic acid )</td>
              <td></td>
            </tr>
            <tr>
              <td>Auramine O </td>
              <td>( Basic Yellow 2)</td>
            </tr>
            <tr>
              <td> Metanil Yellow </td>
              <td>( Acid Yellow 36 )</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
