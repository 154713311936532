import React from "react";

const MagnesiumSulphateHeptahydrate2 = () => {
  return (
    <div>
      <div className="container">
        <div className="other-info-section" id="productInfo">
          <div
            className="product-description-section-main"
            id="productDescription"
          >
            <div className="product-description-section">
              <h2>Product Description</h2>
              <p lang="en">
                <strong>Product Details :</strong>
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="128*" />
                  <col width="128*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Product Name</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.04in",
                      }}
                      width="50%"
                    >
                      <p>:MAGNESIUM SULPHATE HEPTAHYDRATE</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>C.A.S. No.</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>:10034-99-8</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Formula</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        : MgSO<sub>4</sub>.7H<sub>2</sub>O
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>
                        <strong>Molecular Weight</strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="50%"
                    >
                      <p>: 246.50 g/mol</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p lang="en">
                <br />
              </p>
              <table cellPadding={4} cellSpacing={0} width="100%">
                <colgroup>
                  <col width="85*" />
                  <col width="85*" />
                  <col width="85*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">SPECIFICATION</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "1px medium 1px 1px",
                        borderStyle: "solid none solid solid",
                        borderColor:
                          "rgb(0, 0, 0) currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0.04in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">IP</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        border: "1px solid rgb(0, 0, 0)",
                        padding: "0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">BP</span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Description</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          Colorless crystals or white, Crystalline powder.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          White or almost white, crystalline powder or brilliant
                          , colorless crystals.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Solubility</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          Freely Soluble in water , very soluble in boiling
                          water and practically insoluble in ethanol&nbsp;
                        </span>
                        (96 % )
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <strong>
                          <span lang="en">Identification</span>
                        </strong>
                      </p>
                      <p style={{ marginBottom: "0in" }}>&nbsp;</p>
                      <ul>
                        <li>
                          <strong>
                            <span lang="en">Reaction</span>
                          </strong>
                        </li>
                        <li>
                          <strong>
                            <span lang="en">Reaction</span>
                          </strong>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">A: Reactions of Sulphates</span>
                        </li>
                        <li>
                          <span lang="en">B: Reactions of Magnesium salts</span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <span lang="en">
                          <br />
                        </span>
                      </p>
                      <ul>
                        <li>
                          <span lang="en">
                            A: It gives reaction of Sulphates.
                          </span>
                        </li>
                        <li>
                          <span lang="en">
                            B :It gives reaction ofmagnesium
                          </span>
                        </li>
                      </ul>
                      <p>&nbsp;</p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">pH of a 5% solution at 250C</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Appearance of solution</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          Dissolve 5 g in sufficient carbon dioxide free water
                          to produced 50 ml (Solution A) is clear and colorless.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          Dissolve 5 g in sufficient carbon dioxide free water
                          to produced 50 ml (Solution S) is clear and colorless.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p style={{ marginBottom: "0in" }}>
                        <strong>
                          <span lang="en">Acidity or alkalinity</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          NMT 0.2 ml of either 0.01 M hydrochloric acid or 0.01
                          M sodium hydroxide is required to change the color of
                          solution.
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">
                          NMT.0.2 ml of 0.01 M hydrochloric or 0.01 M sodium
                          hydroxide is required to change the color of
                          indicator.
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Arsenic</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 2 ppm.</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 2 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Heavy metals</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 10 ppm.</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Selenium</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p lang="en">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Iron</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 200 ppm.</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT 20 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Chloride</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT. 300 ppm</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">NMT. 300 ppm</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Organic Volatile impurities</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p lang="en">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Residual Solvents</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p lang="en">
                        <br />
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Loss on drying</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">48.0 to 52.0 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">48.0 % - 52.0 %</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Loss on Ignition</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">-</span>
                      </p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <strong>
                          <span lang="en">Assay (on dried basis)</span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium medium 1px 1px",
                        borderStyle: "none none solid solid",
                        borderColor:
                          "currentcolor currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">99.0 % to 100.5 %</span>
                      </p>
                    </td>
                    <td
                      style={{
                        borderWidth: "medium 1px 1px",
                        borderStyle: "none solid solid",
                        borderColor: "currentcolor rgb(0, 0, 0) rgb(0, 0, 0)",
                        padding: "0in 0.04in 0.04in",
                      }}
                      width="33%"
                    >
                      <p>
                        <span lang="en">99.0 % - 100.5 %</span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MagnesiumSulphateHeptahydrate2;
