import React from "react";

const SodiumPhosphateTribasicDodecahydrate = () => {
  return (
    <div>
      <div className="other-info-section-main">
        <div className="container">
          <div className="other-info-section" id="productInfo">
            <div
              className="product-description-section-main"
              id="productDescription"
            >
              <div className="product-description-section">
                <h2>Product Description</h2>
                <p
                  className="western"
                  lang="en-US"
                  style={{
                    marginBottom: "0cm",
                    lineHeight: "150%",
                    breakBefore: "page",
                  }}
                >
                  <font face="Arial, sans-serif">
                    PRODUCT NAME :{" "}
                    <strong>SODIUM PHOSPHATE TRIBASIC DODECAHYDRATE</strong>{" "}
                  </font>
                </p>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm", lineHeight: "150%" }}
                >
                  <font face="Arial, sans-serif">C.A.S. No. : 10101-89-0 </font>
                </p>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm", lineHeight: "150%" }}
                >
                  <font face="Arial, sans-serif">
                    MOLECULAR FORMULA : Na<sub>3</sub>PO<sub>4</sub>.12H
                    <sub>2</sub>O{" "}
                  </font>
                </p>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm", lineHeight: "150%" }}
                >
                  <font face="Arial, sans-serif">
                    MOLECULAR WEIGHT : 380.12{" "}
                  </font>
                </p>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm" }}
                >
                  <br />
                </p>
                <table cellPadding={7} cellSpacing={0} width={651}>
                  <colgroup>
                    <col width={232} />
                    <col width={120} />
                    <col width={120} />
                    <col width={120} />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td
                        height={14}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            <strong>SPECIFICATION</strong>
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            <strong>LR</strong>
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            <strong>AR</strong>
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            <strong>ACS</strong>
                          </font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            Water Insoluble Matter
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.005%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.01%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">Chloride (Cl)</font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            Sulphate (SO<sub>4</sub>)
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.005%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.01%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            Nitrogen Compounds (N){" "}
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">---</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            Heavy Metals (as Pb)
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.0005%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">Iron (Fe)</font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.001%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">Copper (Cu)</font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.0005%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">---</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">Potassium (K)</font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">0.005%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">---</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={15}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">
                            Excess alkali (as NaOH)
                          </font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">2.0%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">2.5%</font>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        height={14}
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={232}
                      >
                        <p className="western" lang="en-US">
                          <font face="Arial, sans-serif">Assay</font>
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <br />
                        </p>
                      </td>
                      <td
                        style={{
                          borderWidth: "1px medium 1px 1px",
                          borderStyle: "solid none solid solid",
                          padding: "0cm 0cm 0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">NLT 98.0%</font>
                        </p>
                      </td>
                      <td
                        style={{
                          border: "1px solid rgb(0, 0, 0)",
                          padding: "0cm 0.19cm",
                        }}
                        width={120}
                      >
                        <p align="CENTER" className="western" lang="en-US">
                          <font face="Arial, sans-serif">98.0 - 102.0%</font>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm" }}
                >
                  <br />
                </p>
                <p
                  className="western"
                  lang="en-US"
                  style={{ marginBottom: "0cm" }}
                >
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SodiumPhosphateTribasicDodecahydrate;
